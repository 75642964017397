import React from 'react'
import styled from 'styled-components'

const Chevron = (props: any) => {
  return (
    <svg
      id='Group_381'
      data-name='Group 381'
      xmlns='http://www.w3.org/2000/svg'
      width='5.933'
      height='11.016'
      viewBox='0 0 5.933 11.016'
      {...props}
    >
      <path
        id='Path_2537'
        data-name='Path 2537'
        d='M198.017,112.1l-5.085-5.085a.424.424,0,1,0-.6.6l4.784,4.786-4.785,4.785a.424.424,0,0,0,.6.6l5.085-5.085A.424.424,0,0,0,198.017,112.1Z'
        transform='translate(-192.208 -106.896)'
        fill='currentColor'
      />
    </svg>
  )
}

export const BackChevron = styled(Chevron)`
  transform: rotate(180deg);
`

export const ForwardChevron = Chevron
