import { useState, useEffect } from 'react'

export enum MotionSensorState {
  absent,
  disabled,
  enabled,
}

export const useMotionSensorCheck = () => {
  const canBeEnabled = checkIfOsAndBrowserAllowEnabling()
  const [sensorState, setState] = useState<MotionSensorState>(MotionSensorState.enabled)

  const checkSensor = (callback?: (result: MotionSensorState) => void) => {
    window.cameraView.lackRequiredSensors((isAbsent: boolean) => {
      let result = MotionSensorState.absent
      if (!isAbsent) {
        result = MotionSensorState.enabled
      } else if (canBeEnabled) {
        result = MotionSensorState.disabled
      }
      setState(result)
      if (typeof callback === 'function') callback(result)
    })
  }

  useEffect(() => {
    try {
      checkSensor()
    } catch {
      let timeout = setTimeout(checkSensor, 100)
      return () => clearTimeout(timeout)
    }
  })

  return { sensorState, checkSensor }
}

const checkIfOsAndBrowserAllowEnabling = () => {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
    var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
    var browser = navigator.userAgent.toLowerCase().match(/safari/)
    if (!v || !browser) {
      return false
    }
    const majorVersion = parseInt(v[1], 10)
    const minorVersion = parseInt(v[2], 10)
    return majorVersion === 12 && minorVersion >= 2
  }
}
