import React from 'react'

export const Cart = (props: any) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='21.24' height='20.03' viewBox='0 0 21.24 20.03' {...props}>
    <g id='cart' transform='translate(0.004 -0.379)'>
      <path
        id='Path_2327'
        data-name='Path 2327'
        d='M98.482,322.332a2.53,2.53,0,1,0,2.53,2.53A2.53,2.53,0,0,0,98.482,322.332Zm0,4.129a1.6,1.6,0,1,1,1.6-1.6A1.6,1.6,0,0,1,98.482,326.461Zm0,0'
        transform='translate(-91.495 -306.982)'
        fill='currentColor'
      />
      <path
        id='Path_2328'
        data-name='Path 2328'
        d='M20.775,4.734H4.356L4.021,2.86A2.8,2.8,0,0,0,1.756.6L.543.386A.465.465,0,1,0,.38,1.3L1.6,1.516A1.86,1.86,0,0,1,3.1,3.022l1.646,9.286a2.32,2.32,0,0,0,2.288,1.921H16.8a2.319,2.319,0,0,0,2.227-1.651l2.186-7.245a.486.486,0,0,0-.07-.414.471.471,0,0,0-.372-.186ZM18.143,12.3a1.385,1.385,0,0,1-1.335.99H7.043a1.388,1.388,0,0,1-1.372-1.153L4.523,5.659H20.152Zm0,0'
        transform='translate(0)'
        fill='currentColor'
      />
      <path
        id='Path_2329'
        data-name='Path 2329'
        d='M308.482,322.332a2.53,2.53,0,1,0,2.53,2.53A2.53,2.53,0,0,0,308.482,322.332Zm0,4.129a1.6,1.6,0,1,1,1.6-1.6A1.6,1.6,0,0,1,308.482,326.461Zm0,0'
        transform='translate(-291.73 -306.982)'
        fill='currentColor'
      />
    </g>
  </svg>
)
