import React from 'react'
import styled from 'styled-components'

export const Exit = (props: any) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16.497' height='15.961' viewBox='0 0 16.497 15.961' {...props}>
    <g id='logout' transform='translate(16.497 16.293) rotate(180)'>
      <path
        id='Path_2324'
        data-name='Path 2324'
        d='M160.271,220.088h-10.4a.544.544,0,0,1,0-1.088h10.4a.544.544,0,0,1,0,1.088Zm0,0'
        transform='translate(-144.311 -211.232)'
        fill='currentColor'
      />
      <path
        id='Path_2325'
        data-name='Path 2325'
        d='M152.747,140.545a.531.531,0,0,1-.38-.16l-2.869-2.9a.549.549,0,0,1,0-.77l2.869-2.9a.534.534,0,0,1,.761,0,.549.549,0,0,1,0,.77l-2.489,2.518,2.489,2.517a.549.549,0,0,1,0,.77A.536.536,0,0,1,152.747,140.545Zm0,0'
        transform='translate(-144.319 -128.786)'
        fill='currentColor'
      />
      <path
        id='Path_2326'
        data-name='Path 2326'
        d='M7.89,16.293A7.981,7.981,0,0,1,7.89.332a7.851,7.851,0,0,1,7.343,5.061.547.547,0,0,1-.3.706.537.537,0,0,1-.7-.308A6.781,6.781,0,0,0,7.89,1.42a6.893,6.893,0,0,0,0,13.784,6.781,6.781,0,0,0,6.342-4.37.537.537,0,0,1,.7-.308.547.547,0,0,1,.3.706,7.852,7.852,0,0,1-7.343,5.06Zm0,0'
        transform='translate(0 0)'
        fill='currentColor'
      />
    </g>
  </svg>
)

export const Previous = styled(Exit)`
  transform: rotate(180deg);
`
