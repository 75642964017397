import { saveDataOnAitServer } from 'app/services/api'
import { UpdatableShopFields, ShopState } from 'app/domain/shop/contracts'
import {
  UserState,
  JacketPreferences,
  TrousersPreferences,
  ShirtPreferences,
  UpdatableFitPreferencesFields,
  /*UpdatableUserFields,*/
  UpdatableSurveyChoicesFields,
  SurveyChoices,
} from 'app/domain/user/contracts'

interface UCDependencies {
  updateWidgetButton: (text: string, aitId: string) => void
  updateShop: (fields: UpdatableShopFields) => void
  sendMessageToB2B: (aitId: string, mesaj: string) => void
  //updateUser: (fields: UpdatableUserFields) => void
  updateFitPreferences: (fields: UpdatableFitPreferencesFields) => void
  updateSurveyChoices: (fields: UpdatableSurveyChoicesFields) => void
}

interface UCData {
  shop: ShopState
  user: UserState
  fitPreferences: {
    jacket: JacketPreferences
    trousers: TrousersPreferences
    shirt: ShirtPreferences
  }
  surveyChoices: SurveyChoices
}

export const saveDataUC = (dependencies: UCDependencies) => async ({
  shop,
  user,
  fitPreferences,
}: //surveyChoices,
UCData) => {
  const {
    updateWidgetButton,
    sendMessageToB2B,
    updateShop,
    /*updateUser,*/ updateFitPreferences /*, updateSurveyChoices*/,
  } = dependencies
  const response = await saveDataOnAitServer(shop, user, fitPreferences)
  if (response.ok) {
    const data = await response.json()

    //if (shop.shopId?.startsWith('SO'))
    updateWidgetButton(data.button_text, data.ait_id)
    if (shop.fromB2B === '1') sendMessageToB2B(data.ait_id, 'b2b-data-saved')
    updateShop({ aitId: data.ait_id })
    //updateUser({ thigh: user?.thigh, calf: user?.calf })
    updateFitPreferences({
      jacket: {
        fit: data?.fit_preferences?.jacket_suit_fit,
        jacketLength: data?.fit_preferences?.jacket_suit_length,
        sleeveLength: data?.fit_preferences?.jacket_suit_sleeve_length,
      },
      trousers: {
        fit: data?.fit_preferences?.trouser_jeans_fit,
        length: data?.fit_preferences?.trouser_jeans_length,
      },
      shirt: {
        fit: data?.fit_preferences?.shirt_fit,
        shirtLength: data?.fit_preferences?.shirt_length,
        watchSide: data?.fit_preferences?.shirt_watch,
      },
      isSaved: true,
    })
    // updateSurveyChoices({
    //   chest: null,
    //   abdomen: null,
    //   hips: null,
    // })
  } else {
    console.error(`[AIT][MTM] error saving data on AIT server: ${response.status}:${response.statusText}`)
    return false
  }
}
