import React from 'react'

import { images } from 'app/assets/preferences'
import { ShirtPreferences } from 'app/domain/user/contracts'
import { Option, OptionsRow } from './OptionsRow'
import { i18nKeys } from 'app/assets/18nKeys'

interface Props {
  preferences: ShirtPreferences
  onChange: (shirt: ShirtPreferences) => void
}

const {
  shirt: { fit },
} = images

export const ShirtPanel: React.FC<Props> = ({ preferences, onChange }: Props) => {
  const fitOptions: Option[] = ['slim', 'adjusted', 'comfort']

  return (
    <div>
      <OptionsRow
        titleKey={i18nKeys.fitPreferences.chooseShirtFit}
        height={90}
        images={fit}
        category='fit'
        options={fitOptions}
        onChange={onChange}
        preferences={preferences}
      />
    </div>
  )
}
