import { AppReducer, AppState, AppAction } from 'app/domain/contracts'

export const userReducer: AppReducer = (state: AppState, action: AppAction) => {
  if (action.entity !== 'user') {
    return state
  }

  switch (action.name) {
    case 'updateUser': {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
          photos: {
            base64: {
              side: action.payload?.photos?.base64?.side || state.user.photos.base64.side,
              front: action.payload?.photos?.base64?.front || state.user.photos.base64.front,
            },
            angles: {
              side: action.payload?.photos?.angles?.side || state.user.photos.angles?.side,
              front: action.payload?.photos?.angles?.front || state.user.photos.angles?.front,
            },
          },
        },
      }
    }
    case 'resetFrontPhoto': {
      return {
        ...state,
        user: {
          ...state.user,
          photos: {
            base64: {
              side: state.user.photos.base64.side,
              front: '',
            },
            angles: {
              side: state.user.photos.angles?.side,
              front: undefined,
            },
          },
        },
      }
    }
    case 'resetSidePhoto': {
      return {
        ...state,
        user: {
          ...state.user,
          photos: {
            base64: {
              side: '',
              front: state.user.photos.base64.front,
            },
            angles: {
              front: state.user.photos.angles?.front,
              side: undefined,
            },
          },
        },
      }
    }
    case 'updateFitPreferences': {
      return {
        ...state,
        user: {
          ...state.user,
          fitPreferences: {
            ...state.user.fitPreferences,
            isSaved: false,
            ...action.payload,
          },
        },
      }
    }
    case 'updateSizeRec': {
      return {
        ...state,
        user: {
          ...state.user,
          sizeRec: {
            ...state.user.sizeRec,
            isSaved: false,
            ...action.payload,
          },
        },
      }
    }
    case 'setDefaultPreferences': {
      return {
        ...state,
        user: {
          ...state.user,
          fitPreferences: {
            product_type: 'jacket',
            jacket: {
              fit: 'adjusted',
              jacketLength: 'regular',
              sleeveLength: 'regular',
            },
            shirt: {
              fit: 'adjusted',
              shirtLength: 'tucked',
              watchSide: 'none',
            },
            trousers: {
              fit: 'semislim',
              length: 'slightbreak',
            },
            isSaved: false,
          },
        },
      }
    }
    case 'updateSurveyChoices': {
      return {
        ...state,
        user: {
          ...state.user,
          surveyChoices: {
            ...state.user.surveyChoices,
            ...action.payload,
          },
        },
      }
    }
    case 'setDefaultSurveyChoices': {
      return {
        ...state,
        user: {
          ...state.user,
          surveyChoices: {
            chest: 'regular',
            abdomen: 'regular',
            hips: 'regular',
          },
        },
      }
    }
    default: {
      return state
    }
  }
}
