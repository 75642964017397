import querystring from 'querystring'
import { AppState } from 'app/domain/contracts'

export const makeContinueOnMobileUrl = (appState: AppState) => {
  const protocol = window.location.protocol
  const hostname = window.location.hostname
  const port = window.location.port
  const queryStringParams = {
    un: appState.user.name || null,
    us: appState.user.surname || null,
    uh: appState.user.height || null,
    uw: appState.user.weight || null,
    ua: appState.user.age || null,
    ut:
      appState.shop.jeansOption === '1' ||
      appState.shop.moreMeasurements === '1' ||
      appState.shop.moreMeasurements === '2'
        ? appState.user.thigh || null
        : null,
    uc:
      appState.shop.jeansOption === '1' ||
      appState.shop.moreMeasurements === '1' ||
      appState.shop.moreMeasurements === '2'
        ? appState.user.calf || null
        : null,
    unk:
      appState.shop.moreMeasurements === '1' || appState.shop.moreMeasurements === '2'
        ? appState.user.neck || null
        : null,
    uch:
      appState.shop.moreMeasurements === '1' || appState.shop.moreMeasurements === '2'
        ? appState.user.chest || null
        : null,
    uwa:
      appState.shop.moreMeasurements === '1' || appState.shop.moreMeasurements === '2'
        ? appState.user.waist || null
        : null,
    ub:
      appState.shop.moreMeasurements === '1' || appState.shop.moreMeasurements === '2'
        ? appState.user.basin || null
        : null,
    ums: appState.user.preferences.measurementSystem || null,
    aid: appState.shop.aitId || null,
    slang: appState.shop.language || null,
    sid: appState.shop.shopId || null,
    suid: appState.shop.shopUserId || null,
    spt: appState.shop.productType || null,
    topic: appState.pubSubTopicName || null,
    fid: appState.shop.fitId || null,
    jns: appState.shop.jeansOption || null,
    mm: appState.shop.moreMeasurements || null,
    spl: appState.shop.shopProductLink || null,
    fromB2B: appState.shop.fromB2B || null,
  }

  // if (appState.shop.shopId?.startsWith('SO')) {
  //   //Size-rec
  //   return `${protocol}//${
  //     hostname + (port === null || port === undefined || port === '' ? '' : ':' + port)
  //   }/choose-path?${querystring.stringify(queryStringParams)}`
  // } //mtm
  // else {
  return `${protocol}//${
    hostname + (port === null || port === undefined || port === '' ? '' : ':' + port)
  }/mobile-tutorial?${querystring.stringify(queryStringParams)}`
  //}

  // return `${protocol}//${
  //   hostname + (port === null || port === undefined || port === '' ? '' : ':' + port)
  // }/mobile-tutorial?${querystring.stringify(queryStringParams)}`
}
// export const makeContinueOnDesktopUrl = (
//   pubSubTopicName: string,
//   aitId: string,
//   language: any,
//   jeansOption: any,
//   thigh: string,
//   calf: string
// ) => {
//   const protocol = window.location.protocol
//   const hostname = window.location.hostname
//   const port = window.location.port
//   const queryStringParams = {
//     topic: pubSubTopicName || null,
//     aid: aitId || null,
//     slang: language || null,
//     jns: jeansOption || null,
//     ut: thigh || null,
//     uc: calf || null,
//   }

//   return `${protocol}//${
//     hostname + (port === null || port === undefined || port === '' ? '' : ':' + port)
//   }/continue-on-desktop?${querystring.stringify(queryStringParams)}`
// }
