import { isMobile, isTablet } from 'react-device-detect'

export const closeWidget = () => {
  window.parent && window.parent.postMessage({ event: 'ait-mtm-close-app-iframe' }, '*')

  if (isMobile || isTablet) {
    return setTimeout(() => window.close(), 400)
  }

  //window.parent && window.parent.postMessage({ event: 'ait-mtm-close-app-iframe' }, '*')
}
