import React, { useContext, useEffect, useState } from 'react'
import QRCode from 'qrcode.react'
import styled from 'styled-components'
import { Text, Button, Image } from 'rebass/styled-components'
import { RouteComponentProps, navigate } from '@reach/router'
import copy from 'copy-to-clipboard'

import { t, makeContinueOnMobileUrl } from '@shared-utils'
//import { Info } from 'app/assets/icons'
import { images } from 'app/assets/prepare'
import { i18nKeys } from 'app/assets/18nKeys'
import { ApplicationContext } from 'app/Context'
import * as placeholders from 'app/assets/placeholders'
import { variableWidthStyle } from '../style'
import { ComputerPhoto } from './ComputerPhoto'
import { theme } from 'app/assets/theme'
import { isTablet, isMobile } from 'react-device-detect'
import publicIp from 'public-ip'

export const Desktop: React.FC<RouteComponentProps> = () => {
  const {
    state,
    actions: { updateUser, resetFrontPhoto, resetSidePhoto },
  } = useContext(ApplicationContext)

  const [myIp, setMyIp] = useState('')

  const { front, side } = state.user.photos.base64
  const url = makeContinueOnMobileUrl(state)
  useEffect(() => {
    publicIp
      .v4()
      .then(response => setMyIp(response))
      .catch(error => console.error(error))
  }, [state, updateUser, resetFrontPhoto, resetSidePhoto])

  return (
    <Container>
      <TitleText>{t(i18nKeys.prep.title)}</TitleText>
      <SubtitleText>{t(i18nKeys.prep.subtitle)}</SubtitleText>
      <PhotoOptionsContainer>
        <QRCodeOptionContainer>
          <QRInstructionText>{t(i18nKeys.prep.qrcodeInstruction)}</QRInstructionText>
          <QRCode value={url} />
          <CopyLinkContainer myIp={myIp}>
            <CopyLinkText
              onClick={() => {
                copy(url)
              }}
            >
              {t(i18nKeys.prep.copyLink)}
            </CopyLinkText>
            <CopyLinkImage src={images.copyLink} onClick={() => copy(url)} />
          </CopyLinkContainer>
        </QRCodeOptionContainer>
        <ComputerOptionContainer myIp={myIp}>
          <ComputerInstructionText>{t(i18nKeys.prep.computerInstruction)}</ComputerInstructionText>
          <ComputerPhotosContainer>
            <ComputerPhoto
              direction='front'
              onPhotoSelected={base64Data => {
                if (base64Data) {
                  updateUser({ photos: { base64: { front: base64Data } } })
                } else {
                  resetFrontPhoto()
                }
              }}
              fileNamePrefix='ait-mtm-front'
              imageBase64={front || placeholders.images.silhouetteFront}
            />
            <ComputerPhoto
              direction='side'
              onPhotoSelected={base64Data => {
                if (base64Data) {
                  updateUser({ photos: { base64: { side: base64Data } } })
                } else {
                  resetSidePhoto()
                }
              }}
              fileNamePrefix='ait-mtm-side'
              imageBase64={side || placeholders.images.silhouetteSide}
            />
          </ComputerPhotosContainer>
        </ComputerOptionContainer>
        {/* <InfoButton variant='rounded' onClick={() => navigate('/info')}>
          <Info />
        </InfoButton> */}
      </PhotoOptionsContainer>
      <NextButton myIp={myIp} disabled={!(front && side)} onClick={() => navigate('/analyzing')}>
        {t(i18nKeys.next)}
      </NextButton>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  ${variableWidthStyle};
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: none;
  }
  ${isTablet || isMobile ? ' display: none;' : ''}
`

const TitleText = styled(Text)`
  font-weight: 800;
  line-height: 24px;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[5]}px;
`

const SubtitleText = styled(Text)`
  font-weight: 600;
  line-height: 19px;
  margin-top: 15px;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[2]}px;
`
// margin-left: 20px;
//   margin-right: 20px;
const PhotoOptionsContainer = styled.div`
  display: flex;
  width: 80%;
  margin-top: 15%;
  flex-direction: row;
  justify-content: center;
`
//space-between

const QRCodeOptionContainer = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
`
//flex
const ComputerOptionContainer = styled.div<{ myIp: string }>`
  display: ${({ myIp }) =>
    window.location.href.indexOf('localhost') >= 0 || myIp === '86.122.4.120' ? 'flex' : 'none'};
  text-align: center;
  flex-direction: column;
  align-items: center;
`

const ComputerPhotosContainer = styled.div`
  display: flex;
  margin-top: 15px;
`
/*width:  80%;
const InfoButton = styled(Button)`
  margin-top: 45px;
`
*/
//flex
const CopyLinkContainer = styled.div<{ myIp: string }>`
  display: ${({ myIp }) =>
    window.location.href.indexOf('localhost') >= 0 || myIp === '86.122.4.120' ? 'flex' : 'none'};
  width: 60px;
  font-size: 10px;
  line-height: 14px;
  color: #525252;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
  text-decoration: underline;
`

const CopyLinkImage = styled(Image)`
  width: 20%;
  :hover {
    cursor: pointer;
  }
`

const CopyLinkText = styled(Text)`
  :hover {
    cursor: pointer;
  }
`

const NextButton = styled(Button)<{ myIp: string }>`
  ${({ myIp }) => (window.location.href.indexOf('localhost') >= 0 || myIp === '86.122.4.120' ? '' : 'display: none;')}
  margin-top: 100px;
`

const QRInstructionText = styled(Text)`
  font-weight: 600;
  line-height: 17px;
  width: 172px;
  margin-bottom: 40px;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[1]}px;
`

const ComputerInstructionText = styled(Text)`
  font-weight: 600;
  line-height: 17px;
  width: 118px;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[1]}px;
`
