import React from 'react'
import styled from 'styled-components'

import { Instruction } from './Instruction'
import { isTablet } from 'react-device-detect'
import { theme } from 'app/assets/theme'

export const TextInstructions: React.FC = () => {
  return (
    <Container>
      <Instruction type={'phoneLevel'} />
      <Instruction type={'cleanBackground'} />
      <Instruction type={'fittingClothes'} />
      <Instruction type={'showNeck'} />
      <Instruction type={'poseUnchanged'} />
    </Container>
  )
}

const Container = styled.div`
  margin-top: 12px;
  min-width: 270px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    margin-top: 0px;
  }
  ${isTablet ? ' margin-top: 0px;' : ''}
`
