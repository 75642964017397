import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Button, Text } from 'rebass/styled-components'

import { t, toBase64ImageSrc } from '@shared-utils'
import { Download } from 'app/assets/icons'
import { i18nKeys } from 'app/assets/18nKeys'
import { Direction } from 'app/domain/contracts'
import { mediaContainerStyle } from './style'

interface ImagePreviewProps {
  image: string
  direction: Direction
  onCancel: () => void
  onConfirm: () => void
}

export const ImagePreview: React.FC<ImagePreviewProps> = ({
  image,
  direction,
  onCancel,
  onConfirm,
}: ImagePreviewProps) => {
  useEffect(() => {
    var element = document.getElementById('Top')
    if (element != null) {
      var parent = element.parentElement
      if (parent != null) {
        parent.style.height = '99%'
      }
    }
  }, [])
  return (
    <Container id='Top'>
      <MediaContainer>
        <Image src={image ? toBase64ImageSrc(image) : undefined} alt={t(i18nKeys.camera.noPreview)} />
        <DownloadLink href={image ? toBase64ImageSrc(image) : undefined} download={`ait-mtm-${direction}.jpg`}>
          <DownloadIcon />
        </DownloadLink>
      </MediaContainer>
      <Text mb={5} variant='label'>
        {t(i18nKeys.camera.doYouLikeThePicture)}
      </Text>
      <Actions>
        <Button mx={2} variant='lightGray' onClick={onCancel}>
          {t(i18nKeys.camera.no)}
        </Button>
        <Button mx={2} onClick={onConfirm}>
          {t(i18nKeys.camera.yes)}
        </Button>
      </Actions>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const MediaContainer = styled.div`
  ${mediaContainerStyle}
`

const Image = styled.img`
  max-height: 100%;
`

const DownloadLink = styled.a`
  position: absolute;
  bottom: 10px;
  right: 20px;
  color: #ffffff;
`

const DownloadIcon = styled(Download)`
  width: 24px;
`

const Actions = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-evenly;
`
