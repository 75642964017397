import React, { useContext, useEffect, useState } from 'react'
import { navigate } from '@reach/router'
import styled from 'styled-components'
import { Heading, Text, Button } from 'rebass/styled-components'
import { PubSub } from '@aws-amplify/pubsub'
import { isMobile, isTablet } from 'react-device-detect'

import { saveDataUC } from 'app/domain/usecases/saveData'
import { ApplicationContext } from 'app/Context'
import { t, updateWidgetButton, sendMessageToB2B } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { ForwardChevron } from 'app/assets/icons'
import { theme } from 'app/assets/theme'
import { Loading } from 'app/components/Loading'
//import { saveDataOnAitServer } from 'app/services/api'

export const PhotosOK: React.FC<any> = () => {
  const [isLoading, setIsLoading] = useState(false)

  const {
    state: {
      pubSubTopicName,
      shop,
      shop: { aitId, jeansOption, moreMeasurements, shopProductLink, fromB2B },
      user,
      user: { fitPreferences, surveyChoices },
    },
    actions: { updateFitPreferences, updateShop, updateUser, updateSurveyChoices, setDefaultPreferences },
  } = useContext(ApplicationContext)

  useEffect(() => {
    var element = document.getElementById('Top')
    if (element != null) {
      var parent = element.parentElement
      if (parent != null) {
        parent.style.height = '99%'
      }
    }
    if (fromB2B === '2') {
      setDefaultPreferences()
    }
  }, [fromB2B, setDefaultPreferences])

  return (
    <>
      <Container id='Top'>
        <Title>{t(i18nKeys.photosOK.title)}</Title>
        <BigOrangeText variant='profileLabel'>{t(i18nKeys.photosOK.textOK)}</BigOrangeText>
        <InstructionText variant='profileLabel'>{t(i18nKeys.photosOK.text1)}</InstructionText>
        <LineContainer>
          <div></div>
          <HLine></HLine>
          <div></div>
        </LineContainer>
        <InstructionText variant='profileLabel'>{t(i18nKeys.photosOK.text2)}</InstructionText>
        {jeansOption !== '1' && moreMeasurements !== '1' && moreMeasurements !== '2' ? (
          <InstructionText variant='profileLabel'>{t(i18nKeys.photosOK.text3)}</InstructionText>
        ) : null}
        {jeansOption !== '1' ? (
          <>
            <Button
              mt={4}
              onClick={async () => {
                //if () {
                if ((isMobile || isTablet) && (pubSubTopicName || fromB2B === '2')) {
                  if (pubSubTopicName) {
                    PubSub.publish(pubSubTopicName, {
                      action: 'mobile-flow-complete',
                      payload: {
                        aitId: aitId,
                        jeansOption: jeansOption,
                        shopProductLink: shopProductLink,
                      },
                    })
                  }
                  if (fromB2B === '2') {
                    setIsLoading(true)
                    await saveDataUC({
                      updateWidgetButton,
                      updateShop,
                      updateFitPreferences,
                      updateSurveyChoices,
                      sendMessageToB2B,
                    })({
                      shop,
                      user,
                      fitPreferences,
                      surveyChoices,
                    })
                    setIsLoading(false)
                  }
                  navigate('/continue-on-desktop', { replace: true })
                } else {
                  if (moreMeasurements === '1' || moreMeasurements === '2') {
                    updateUser({
                      neck: null,
                      chest: null,
                      waist: null,
                      basin: null,
                      calf: null,
                      thigh: null,
                    })
                    updateShop({ fromPhotosOK: '1' })
                  }
                  if (moreMeasurements === '2') {
                    navigate('/more-measurements', { replace: true })
                  } else {
                    navigate('/measurement-profile', { replace: true })
                  }
                }
                // } else {
                //   navigate('/jeans-tutorial', { replace: true })
                // }
                //}
              }}
            >
              {moreMeasurements !== '2' || fromB2B === '2'
                ? t(i18nKeys.photosOK.button)
                : t(i18nKeys.photosOK.buttonMoreMeas)}
            </Button>
            {moreMeasurements === '1' ? (
              <>
                <InstructionText variant='profileLabel'>{t(i18nKeys.photosOK.textOR)}</InstructionText>
                <Button
                  mt={4}
                  onClick={() => {
                    //navigate('/more-meas-tutorial', { replace: true })
                    navigate('/more-measurements', { replace: true })
                  }}
                >
                  {t(i18nKeys.photosOK.buttonMoreMeas)}
                </Button>
              </>
            ) : null}
          </>
        ) : (
          <MobileControlsDown>
            <RowJustified>
              <div></div>
              <RowEnd>
                <MyButton
                  onClick={() => navigate('/jeans-tutorial', { replace: true })}
                  color={theme.colors.secondary}
                  variant={'flat'}
                  backgroundColor='transparent'
                >
                  {t(i18nKeys.next)}&nbsp;
                  <ForwardChevron />
                </MyButton>
              </RowEnd>
            </RowJustified>
          </MobileControlsDown>
        )}
      </Container>
      {isLoading ? (
        <FullScreenOverlay>
          <LoadingSpinner />
        </FullScreenOverlay>
      ) : null}
    </>
  )
}

const RowJustified = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

// const PhotoContent = styled.div`

// `
//min-height: calc(${window.innerHeight}px - 250px);
const MobileControlsDown = styled.div`
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding-bottom: 30px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    padding-bottom: 15px;
  }
`

const MyButton = styled(Button)`
  outline: none !important;
  outline-offset: none !important;
  padding-left: 35px;
  padding-right: 35px;
`

const RowEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`
const BigOrangeText = styled(Text)`
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: ${theme.fontSizes[8] + 4}px;
  color: ${theme.colors.secondary};
  font-size: ${theme.fontSizes[8]}px;
  @media screen and (max-height: 600px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`
const InstructionText = styled(Text)`
  width: 80%;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20xp;
  text-align: center;
  line-height: ${theme.fontSizes[1] + 4}px;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[1]}px;
  @media screen and (max-height: 600px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`
const HLine = styled.div`
  display: flex;
  border-top: 1px solid ${theme.colors.primary};
  margin-top: 55px;
  margin-bottom: 30px;
  @media screen and (max-height: 600px) {
    margin-top: 50px;
    margin-bottom: 25px;
  }
  width: 90%;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 30%;
  justify-content: space-between;
  align-items: center;
`
const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 30%;
  justify-content: space-between;
`
const Title = styled(Heading)`
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (max-height: 600px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`
const FullScreenOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`

const LoadingSpinner = styled(Loading)`
  margin-top: 0;
`
