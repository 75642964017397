import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { RouteComponentProps, navigate } from '@reach/router'

import { i18nKeys } from 'app/assets/18nKeys'
import { ApplicationContext } from 'app/Context'
import { saveDataUC } from 'app/domain/usecases/saveData'
import { clearDataUC } from 'app/domain/usecases/clearData'
import { FitPreferences } from './components/FitPreferences'
import { MeasurementProfile } from './components/MeasurementProfile'
import { SizeRec } from './components/SizeRec'
import { useUpdateDataFromApi } from 'app/hooks'
import { t, clearCookie, closeWidget, updateWidgetButton, sendMessageToB2B } from '@shared-utils'
import { Loading } from 'app/components/Loading'
//import { isTablet } from 'react-device-detect'
import { theme } from 'app/assets/theme'

export const MeasurementProfileScreen: React.FC<RouteComponentProps> = () => {
  const [isViewingFitPreferences_MO, setIsViewingFitPreferences_MO] = useState(true)
  const [isViewingFitPreferences_SO, setIsViewingFitPreferences_SO] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    state: {
      shop,
      user,
      user: { fitPreferences, surveyChoices },
    },
    actions: { clearState, updateFitPreferences, updateShop, updateSurveyChoices },
  } = useContext(ApplicationContext) //, updateUser

  const isFetching = useUpdateDataFromApi()
  // let shoptype = shop.shopId || 'M'
  // let isSizeRec = false

  // if (shoptype.substring(0, 1) === 'S') {
  //   isSizeRec = true
  // }

  const saveData = async () => {
    setIsLoading(true)
    await saveDataUC({
      updateWidgetButton,
      updateShop,
      /*updateUser,*/ updateFitPreferences,
      updateSurveyChoices,
      sendMessageToB2B,
    })({
      shop,
      user,
      fitPreferences,
      surveyChoices,
    })
    setIsLoading(false)
  }

  useEffect(() => {
    var element = document.getElementById('Top')
    if (element != null) {
      var parent = element.parentElement
      if (parent != null) {
        parent.style.height = '99%'
      }
    }
    // window.parent && window.parent.postMessage({ event: 'ait-mtm-continue-on-desktop' }, '*')
  }, [])

  const clearData = async () => {
    if (window.confirm(t(i18nKeys.measurementProfile.clearDataConfirm))) {
      setIsLoading(true)
      await clearDataUC({ clearState, clearCookie, closeWidget })(shop)
      setIsLoading(false)
      navigate('/start')
    }
  }

  return (
    <>
      <Container id='Top'>
        {!shop.shopId?.startsWith('SO') ? (
          <MeasurementProfile
            shop={shop}
            user={user}
            save={saveData}
            clearData={clearData}
            isShown={!isViewingFitPreferences_MO}
            showFitPreferences={() => setIsViewingFitPreferences_MO(true)}
          />
        ) : null}
        {shop.shopId?.startsWith('SO') ? (
          <SizeRec
            user={user}
            aitId={shop.aitId}
            save={saveData}
            clearData={clearData}
            isShown={!isViewingFitPreferences_SO}
            showFitPreferences={() => setIsViewingFitPreferences_SO(true)}
          />
        ) : null}
        <FitPreferences
          save={saveData}
          isShown={!shop.shopId?.startsWith('SO') ? isViewingFitPreferences_MO : isViewingFitPreferences_SO}
          hideFitPreferences={() =>
            shop.shopId?.startsWith('SO') ? setIsViewingFitPreferences_SO(false) : setIsViewingFitPreferences_MO(false)
          }
        />
      </Container>
      {isFetching || isLoading ? (
        <FullScreenOverlay>
          <LoadingSpinner />
        </FullScreenOverlay>
      ) : null}
    </>
  )
}

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  margin: 0 auto;
  position: relative;
  padding: 60px 50px;
  width: 1200px;
  @media screen and (min-width: ${theme.breakpoints[0]}) {
    padding-bottom: 70px;
  }
  @media screen and (max-width: ${theme.breakpoints[2]}) {
    width: 960px;
  }
  @media screen and (max-width: ${theme.breakpoints[1]}) {
    padding: 30px 0px 0px;
    width: 85%;
  }
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    padding: 20px 10px 0;
    width: 90%;
  }
  @media screen and (max-height: 700px) {
    padding-top: 20px;
  }
`

const FullScreenOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`

const LoadingSpinner = styled(Loading)`
  margin-top: 0;
`
