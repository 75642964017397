import React, { useState, useContext } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'

import { ApplicationContext } from 'app/Context'
import { Direction } from 'app/domain/contracts'
import { PhotoAngles } from 'app/domain/user/contracts'
import { ImagePreview } from './components/ImagePreview'
import { CameraControl } from './components/CameraControl'

export const CameraScreen: React.FC<RouteComponentProps> = () => {
  const {
    state: {
      user: {
        photos: {
          base64: { front: frontImage, side: sideImage },
        },
      },
    },
    actions: { updateUser, resetFrontPhoto, resetSidePhoto },
  } = useContext(ApplicationContext)

  const [direction, setState] = useState<Direction>(!frontImage ? 'front' : 'side')
  const showPreview = (img: string, angles?: PhotoAngles) => {
    updateUser({ photos: { base64: { [direction]: img }, angles: { [direction]: angles } } })
  }

  const cancelPhoto = () => {
    if (direction === 'front') {
      resetFrontPhoto()
    } else {
      resetSidePhoto()
    }
  }
  const confirmFront = () => (!sideImage ? setState('side') : navigate('/analyzing'))
  const confirmSide = () => navigate('/analyzing')

  if (direction === 'front' && frontImage) {
    return <ImagePreview image={frontImage} direction={direction} onCancel={cancelPhoto} onConfirm={confirmFront} />
  }
  if (direction === 'side' && sideImage) {
    return <ImagePreview image={sideImage} direction={direction} onCancel={cancelPhoto} onConfirm={confirmSide} />
  }

  return <CameraControl direction={direction} onCapture={showPreview} />
}
