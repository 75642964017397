import React, { /*useContext, useState,*/ useEffect } from 'react'
//import { /*isMobile, isIOS, isMobileSafari, isSafari,*/ isTablet } from 'react-device-detect'
//import { Swipeable } from 'react-swipeable'
import { Button, Text, Image } from 'rebass/styled-components'

import styled from 'styled-components/macro'
import { RouteComponentProps, navigate } from '@reach/router'
//import { PubSub } from '@aws-amplify/pubsub'
import { t } from '@shared-utils'
//import { getQueryStringValue } from '@shared-utils'
import { theme } from 'app/assets/theme'
import { i18nKeys } from 'app/assets/18nKeys'
import { ForwardChevron } from 'app/assets/icons'
//import { ApplicationContext } from 'app/Context'
import { images } from 'app/assets/jeans_option'

export const JeansTutorial: React.FC<RouteComponentProps> = () => {
  const iKeyCalf = 'measure_calf'
  const iKeyThigh = 'measure_thigh'

  useEffect(() => {
    var element = document.getElementById('Top')
    if (element != null) {
      var parent = element.parentElement
      if (parent != null) {
        parent.style.height = '99%'
      }
    }
  }, [])

  return (
    <>
      <Container id='Top'>
        <ContainerBigColumn>
          <ContainerRow>
            <TitleText>{t(i18nKeys.jeansOption.pageTitle)}</TitleText>
          </ContainerRow>
          <ContainerRow>
            <SubtitleText variant='body'>{t(i18nKeys.jeansOption.tutPageSubtitle)}</SubtitleText>
          </ContainerRow>
          <ContainerRow>
            <ContainerColumn>
              <InstructionsTextTitle variant='body'>{t(i18nKeys.jeansOption.tutTitleThigh)}</InstructionsTextTitle>
            </ContainerColumn>
            <ContainerColumn>
              <InstructionsTextTitle variant='body'>{t(i18nKeys.jeansOption.tutTitleCalf)}</InstructionsTextTitle>
            </ContainerColumn>
          </ContainerRow>
          <ContainerRow>
            <ContainerColumn>
              <InstructionImage src={images[iKeyThigh]} />
            </ContainerColumn>
            <ContainerColumn>
              <InstructionImage src={images[iKeyCalf]} />
            </ContainerColumn>
          </ContainerRow>
          <ContainerRow>
            <ContainerColumn>
              <InstructionsTextSmall variant='body'>{t(i18nKeys.jeansOption.tutInfoThigh)}</InstructionsTextSmall>
            </ContainerColumn>
            <ContainerColumn>
              <InstructionsTextSmall variant='body'>{t(i18nKeys.jeansOption.tutInfoCalf)}</InstructionsTextSmall>
            </ContainerColumn>
          </ContainerRow>
        </ContainerBigColumn>
        <MobileControlsDown>
          <RowJustified>
            <div></div>
            <RowEnd>
              <MyButton
                onClick={() => navigate('/jeans-measurements', { replace: true })}
                color={theme.colors.secondary}
                variant={'flat'}
                backgroundColor='transparent'
              >
                {t(i18nKeys.next)}&nbsp;
                <ForwardChevron />
              </MyButton>
            </RowEnd>
          </RowJustified>
        </MobileControlsDown>
      </Container>
    </>
  )
}

const TitleText = styled(Text)`
  padding-top: 10px;
  width: 80%;
  font-weight: 800;
  line-height: 24px;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[5]}px;
  text-align: center;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    font-weight: 600;
    font-size: ${theme.fontSizes[4]}px;
  }
`
const SubtitleText = styled(Text)`
  padding-top: 5px;
  padding-bottom: 5px;
  width: 80%;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  flex: 1 0 auto;
  text-align: center;
`

const InstructionsTextTitle = styled(Text)`
  width: 90%;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  flex: 1 0 auto;
  text-align: center;
`

const InstructionsTextSmall = styled(Text)`
  width: 90%;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  flex: 1 0 auto;
  text-align: center;
`
const MyButton = styled(Button)`
  outline: none !important;
  outline-offset: none !important;
  padding-left: 35px;
  padding-right: 35px;
`

const RowEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`

const RowJustified = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

// const PhotoContent = styled.div`

// `
//min-height: calc(${window.innerHeight}px - 250px);
const MobileControlsDown = styled.div`
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding-bottom: 30px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    padding-bottom: 15px;
  }
`
const InstructionImage = styled(Image)`
  max-width: 80%;
  width: auto;
  height: auto;
  top: 0;
  bottom: 0;
  margin: auto;
`
//max-height: calc(${window.innerHeight}px - 250px);

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  text-align: center;
  align-items: center;
`

const ContainerRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
`

const ContainerColumn = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  text-align: center;
  align-items: center;
`
const ContainerBigColumn = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding-bottom: 60px;
  flex-direction: column;
  justify-content: space-around;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    padding-bottom: 80px;
  }
`
