import React from 'react'

import { images } from 'app/assets/preferences'
import { JacketPreferences } from 'app/domain/user/contracts'
import { OptionsRow, Option } from './OptionsRow'
import { i18nKeys } from 'app/assets/18nKeys'

interface Props {
  preferences: JacketPreferences
  onChange: (jacket: JacketPreferences) => void
}

const {
  jacket: { fit, length, sleeve },
} = images

export const JacketPanel: React.FC<Props> = ({ preferences, onChange }: Props) => {
  const fitOptions: Option[] = ['slim', 'adjusted', 'comfort']
  const sleeveOptions: Option[] = ['regular', 'long']
  const jacketLengthOptions: Option[] = [/*'long',*/ 'regular', 'short']

  return (
    <div>
      <OptionsRow
        titleKey={i18nKeys.fitPreferences.chooseJacketFit}
        height={window.innerHeight > 520 ? 100 : 72}
        images={fit}
        category='fit'
        options={fitOptions}
        onChange={onChange}
        preferences={preferences}
      />
      <OptionsRow
        titleKey={i18nKeys.fitPreferences.chooseJacketLength}
        height={window.innerHeight > 520 ? 95 : 60}
        images={length}
        category='jacketLength'
        options={jacketLengthOptions}
        onChange={onChange}
        preferences={preferences}
      />
      <OptionsRow
        titleKey={i18nKeys.fitPreferences.chooseSleeveLength}
        height={window.innerHeight > 520 ? 60 : 40}
        images={sleeve}
        category='sleeveLength'
        options={sleeveOptions}
        onChange={onChange}
        preferences={preferences}
      />
    </div>
  )
}
