import silhouetteSide from './silhouetteSide.png'
import silhouetteFront from './silhouetteFront.png'
import silhouetteSideSmall from './silhouetteSide_small.png'
import silhouetteFrontSmall from './silhouetteFront_small.png'

export const images = {
  silhouetteSide,
  silhouetteFront,
  silhouetteSideSmall,
  silhouetteFrontSmall,
}
