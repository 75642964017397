import { css } from 'styled-components'

const otherHeights = 40 + 20 + 130 + 50

export const mediaContainerStyle = css`
  position: relative;
  margin: 40px;
  margin-bottom: 20px;
  width: fit-content;
  max-width: 90vw;
  max-height: 65vh;
  width: ${(window.innerHeight - otherHeights) / 1.38}px;
  height: ${window.innerHeight - otherHeights}px;
  border: 1px solid ${({ theme: { colors } }) => colors.lightGray};
`
