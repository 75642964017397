import React /*, { useContext }*/ from 'react'
import styled from 'styled-components'
import { Heading, Text, Button } from 'rebass/styled-components'

import { t } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { UserState } from 'app/domain/user/contracts'
import { PBar } from './PBar'
import { isTablet } from 'react-device-detect'
import { theme } from 'app/assets/theme'

interface Props {
  user: UserState
  isShown: boolean
  save: () => void
  clearData: () => void
  aitId?: string | null
  showFitPreferences?: () => void
}

export const SizeRec: React.FC<Props> = ({ user, aitId, isShown, save, clearData, showFitPreferences }) => {
  const {
    //name,
    //surname,
    /*height,
    weight,*/
    /*preferences: { measurementSystem },*/
    fitPreferences: { isSaved },
    sizeRec: { /*product_type,*/ trousers, jacket, shirt },
  } = user

  let barsShow = [false, false, false, false]
  let barsLabel = ['', '', '', '']
  let barsProc = [50.0, 50.0, 50.0, 50.0]
  let barsLowLabel = ['', '', '', '']
  let barsHighLabel = ['', '', '', '']
  let size = ''
  let fitpref = 0
  let sleevepref = 0
  let jacketlengthpref = 0

  switch (user.sizeRec.product_type) {
    case 'jacket':
      fitpref = user.fitPreferences.jacket.fit === 'slim' ? -1 : user.fitPreferences.jacket.fit === 'comfort' ? 1 : 0

      sleevepref = user.fitPreferences.jacket.sleeveLength === 'long' ? 1 : 0
      jacketlengthpref = user.fitPreferences.jacket.jacketLength === 'short' ? -1.5 : 0

      size = jacket.size
      barsShow = [
        jacket.waistAccMin !== null &&
          jacket.waistAccMax !== null &&
          Number(jacket.waistAccMin) < Number(jacket.waistAccMax),
        jacket.lengthAccMin !== null &&
          jacket.lengthAccMax !== null &&
          Number(jacket.lengthAccMin) < Number(jacket.lengthAccMax),
        jacket.sleeveLengthAccMin !== null &&
          jacket.sleeveLengthAccMax !== null &&
          Number(jacket.sleeveLengthAccMin) < Number(jacket.sleeveLengthAccMax),
        false,
      ]
      barsProc = [
        getCaretPosition(-1 * (Number(jacket.waist) + fitpref), Number(jacket.waistAccMin), Number(jacket.waistAccMax)),
        getCaretPosition(
          -1 * (Number(jacket.length) + jacketlengthpref),
          Number(jacket.lengthAccMin),
          Number(jacket.lengthAccMax)
        ),
        getCaretPosition(
          -1 * (Number(jacket.sleeveLength) + sleevepref),
          Number(jacket.sleeveLengthAccMin),
          Number(jacket.sleeveLengthAccMax)
        ),
        50.0,
      ]
      barsLabel = [
        t(i18nKeys.fitPreferences.textJacketWaist),
        t(i18nKeys.fitPreferences.textJacketLength),
        t(i18nKeys.fitPreferences.textJacketSleeveLength),
        '',
      ]
      barsLowLabel = [
        t(i18nKeys.fitPreferences.tooTight),
        t(i18nKeys.fitPreferences.tooShort),
        t(i18nKeys.fitPreferences.tooShort),
        '',
      ]
      barsHighLabel = [
        t(i18nKeys.fitPreferences.tooLoose),
        t(i18nKeys.fitPreferences.tooLong),
        t(i18nKeys.fitPreferences.tooLong),
        '',
      ]
      break
    case 'trousers':
    case 'jeans':
      fitpref = user.fitPreferences.trousers.length === 'slightbreak' ? 2 : 0
      size = trousers.size
      barsShow = [
        trousers.waistAccMin !== null &&
          trousers.waistAccMax !== null &&
          Number(trousers.waistAccMin) < Number(trousers.waistAccMax),
        trousers.lengthAccMin !== null &&
          trousers.lengthAccMax !== null &&
          Number(trousers.lengthAccMin) < Number(trousers.lengthAccMax),
        false,
        false,
      ]
      barsProc = [
        getCaretPosition(-1 * Number(trousers.waist), Number(trousers.waistAccMin), Number(trousers.waistAccMax)),
        getCaretPosition(
          -1 * (Number(trousers.length) + fitpref),
          Number(trousers.lengthAccMin),
          Number(trousers.lengthAccMax)
        ),
        50.0,
        50.0,
      ]
      barsLabel = [t(i18nKeys.fitPreferences.textTrousersWaist), t(i18nKeys.fitPreferences.textTrousersInseam), '', '']
      barsLowLabel = [t(i18nKeys.fitPreferences.tooTight), t(i18nKeys.fitPreferences.tooShort), '', '']
      barsHighLabel = [t(i18nKeys.fitPreferences.tooLoose), t(i18nKeys.fitPreferences.tooLong), '', '']
      break
    case 'shirt':
      fitpref = user.fitPreferences.shirt.fit === 'slim' ? -1 : user.fitPreferences.shirt.fit === 'comfort' ? 1 : 0

      size = shirt.size
      barsShow = [
        shirt.waistAccMin !== null &&
          shirt.waistAccMax !== null &&
          Number(shirt.waistAccMin) < Number(shirt.waistAccMax),
        shirt.neckAccMin !== null && shirt.neckAccMax !== null && Number(shirt.neckAccMin) < Number(shirt.neckAccMax),
        shirt.lengthAccMin !== null &&
          shirt.lengthAccMax !== null &&
          Number(shirt.lengthAccMin) < Number(shirt.lengthAccMax),
        shirt.sleeveLengthAccMin !== null &&
          shirt.sleeveLengthAccMax !== null &&
          Number(shirt.sleeveLengthAccMin) < Number(shirt.sleeveLengthAccMax),
      ]
      barsProc = [
        getCaretPosition(-1 * (Number(shirt.waist) + fitpref), Number(shirt.waistAccMin), Number(shirt.waistAccMax)),
        getCaretPosition(-1 * Number(shirt.neck), Number(shirt.neckAccMin), Number(shirt.neckAccMax)),
        getCaretPosition(-1 * Number(shirt.length), Number(shirt.lengthAccMin), Number(shirt.lengthAccMax)),
        getCaretPosition(
          -1 * Number(shirt.sleeveLength),
          Number(shirt.sleeveLengthAccMin),
          Number(shirt.sleeveLengthAccMax)
        ),
      ]
      barsLabel = [
        t(i18nKeys.fitPreferences.textShirtWaist),
        t(i18nKeys.fitPreferences.textShirtNeck),
        t(i18nKeys.fitPreferences.textShirtLength),
        t(i18nKeys.fitPreferences.textJacketSleeveLength),
      ]
      barsLowLabel = [
        t(i18nKeys.fitPreferences.tooTight),
        t(i18nKeys.fitPreferences.tooTight),
        t(i18nKeys.fitPreferences.tooShort),
        t(i18nKeys.fitPreferences.tooShort),
      ]
      barsHighLabel = [
        t(i18nKeys.fitPreferences.tooLoose),
        t(i18nKeys.fitPreferences.tooLoose),
        t(i18nKeys.fitPreferences.tooLong),
        t(i18nKeys.fitPreferences.tooLong),
      ]
      break
  }

  return (
    <Container isShown={isShown}>
      <Title>{t(i18nKeys.measurementProfile.sizetitle)}</Title>
      <SizeBox size={size}>
        {size !== 'too high' && size !== 'too low' && size !== 'oversize' && size !== 'undersize'
          ? size
          : t(i18nKeys.measurementProfile.outofscale)}
      </SizeBox>
      <Row>
        <Text variant='profileLabel'>{t(i18nKeys.measurementProfile.profileId)}</Text>
        <Text variant='profileValue'>{aitId}</Text>
      </Row>
      {/* {name && name !== '' ? 
      ( 
        <>
          <Row>
            <Text variant='profileLabel'>{t(i18nKeys.measurementProfile.name)}</Text>
            <Text variant='profileValue'>{name}</Text>
          </Row>
          <Row>
            <Text variant='profileLabel'>{t(i18nKeys.measurementProfile.surname)}</Text>
            <Text variant='profileValue'>{surname}</Text>
          </Row>
        </>
      ) : null}*/}

      <PBar
        isShown={barsShow[0]}
        labelText={barsLabel[0]}
        labelLeft={barsLowLabel[0]}
        labelRight={barsHighLabel[0]}
        procent={barsProc[0]}
      />
      <PBar
        isShown={barsShow[1]}
        labelText={barsLabel[1]}
        labelLeft={barsLowLabel[1]}
        labelRight={barsHighLabel[1]}
        procent={barsProc[1]}
      />
      <PBar
        isShown={barsShow[2]}
        labelText={barsLabel[2]}
        labelLeft={barsLowLabel[2]}
        labelRight={barsHighLabel[2]}
        procent={barsProc[2]}
      />
      <PBar
        isShown={barsShow[3]}
        labelText={barsLabel[3]}
        labelLeft={barsLowLabel[3]}
        labelRight={barsHighLabel[3]}
        procent={barsProc[3]}
      />

      <DesktopActions>
        <Button id='saveSizeRec' variant='secondary' disabled={isSaved} mb={4} onClick={save}>
          {t(i18nKeys.measurementProfile.saveProfile)}
        </Button>
        <Button variant='secondary' onClick={clearData}>
          {t(i18nKeys.measurementProfile.clearData)}
        </Button>
      </DesktopActions>
      <MobileActions>
        <MobileButton variant='secondary' mr={2} onClick={clearData}>
          {t(i18nKeys.measurementProfile.clearData)}
        </MobileButton>
        <MobileButton variant='secondary' ml={2} onClick={showFitPreferences}>
          {t(i18nKeys.fitPreferences.title)}
        </MobileButton>
      </MobileActions>
    </Container>
  )
}

const Container = styled.div<{ isShown: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 30%;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: ${({ isShown }) => (isShown ? 'flex' : 'none')};
  }
  ${({ isShown }) => (isTablet ? ' display: ' + (isShown ? 'flex' : 'none') + ';' : '')}
`

const Title = styled(Heading)`
  text-align: center;
  margin-bottom: 0;
  @media screen and (max-height: 670px) {
    font-size: 12px;
  }
`

const Row = styled.div`
  display: flex;
  margin-top: 20px;
  @media screen and (max-height: 670px) {
    margin-top: 10px;
  }
`

const DesktopActions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: none;
  }
  ${isTablet ? ' display: none;' : ''}
`

const MobileActions = styled.div`
  display: none;
  justify-content: center;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: flex;
  }
  @media screen and (max-height: 670px) {
    margin-top: 20px;
  }
  ${isTablet ? 'display: flex; margin-top: 50px;' : ''}
`

const MobileButton = styled(Button)`
  font-size: 12px;
`

const SizeBox = styled.div<{ size: string }>`
  padding: 10px;
  font-size: ${({ size }) =>
    size !== 'too high' && size !== 'too low' && size !== 'oversize' && size !== 'undersize' ? '25' : '12'}px;
  font-weight: bold;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: ${({ size }) =>
    size !== 'too high' && size !== 'too low' && size !== 'oversize' && size !== 'undersize' ? '20' : '70'}%;
  text-align: center;
  border: 1px solid ${theme.colors.secondary};
  border-radius: 10px;
`

// function getCaretPosition(myValue: number, minValue: number, maxValue: number) {
//   myValue = myValue > maxValue ? maxValue : myValue < minValue ? minValue : myValue

//   return myValue > 0
//     ? ((-1 * minValue) / (maxValue - minValue)) * 100 + (50 * myValue) / maxValue
//     : 50 - (50 * myValue) / minValue
// }

function getCaretPosition(myValue: number, minValue: number, maxValue: number) {
  myValue = myValue > maxValue ? maxValue : myValue < minValue ? minValue : myValue

  if (maxValue === minValue) return 50.0
  if (maxValue * minValue >= 0) return 100.0 - (100.0 * (maxValue - myValue)) / (maxValue - minValue)

  //2 functii de grad 2, una cand myValue <= 0, una cand myValue > 0
  let p = 20.0
  let c = 50.0
  let xp = 0
  let cap = 0
  let xcap = minValue

  if (myValue > 0) {
    xcap = maxValue
    cap = 100.0
    p = 80.0
  }

  xp = maxValue - ((maxValue - minValue) * (100.0 - p)) / 100.0

  let b = (xcap ** 2 * (p - c) - xp ** 2 * (cap - c)) / (xp * xcap * (xcap - xp))
  let a = (cap - c - b * xcap) / xcap ** 2

  return (a * myValue + b) * myValue + c

  /*
  //O functie de grad 4
  let e = 50
  let m = 1 / minValue
  let M = 1 / maxValue
  let px = 80
  let py = 20

  let x = 1 / (maxValue - (maxValue - minValue) * (100.0 - px) / 100.0)
  let y = 1 / (maxValue - (maxValue - minValue) * (100.0 - py) / 100.0)

  let num1 = M - m
  let num2 = x - m
  let num3 = y - m

  let f11 = (M**2 - m**2) / num1
  let f12 = (M**3 - m**3) / num1
  let f13 = ((100 - e) * M**4 + e * m**4) / num1

  let f21 = (x**2 - m**2) / num2
  let f22 = (x**3 - m**3) / num2
  let f23 = ((px - e) * x**4 + e * m**4) / num2

  let f31 = (y**2 - m**2) / num3
  let f32 = (y**3 - m**3) / num3
  let f33 =  ((py - e) * y**4 + e * m**4) / num3

  let num4 = f21-f11

  let F1 = (f23 - f13) / num4
  let F2 = (f22 - f12) / num4

  let dif_31_11 = f31 - f11  

  let d = (f33 - f13 - F1 * dif_31_11)/(f32 - f12 - F2 * dif_31_11)
  let c = F1 - d * F2
  let b = f13 - c * f11 - d * f12
  let a = (-1 * e) * (m**4) - b * m - c * (m**2) - d * (m**3)

  return ((a * myValue + b) * myValue + c) * myValue + d) * myValue + e
  */
}
