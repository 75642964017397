import frontView from './frontView.png'
import sideView from './sideView.png'
import cleanBackground from './cleanBackground.png'
import fittingClothes from './fittingClothes.png'
import phoneLevel from './phoneLevel.png'
import poseUnchanged from './poseUnchanged.png'
import showNeck from './showNeck.png'
import rotateToPortrait from './Rotate_to_portrait.png'

export const images = {
  frontView,
  sideView,
  cleanBackground,
  fittingClothes,
  phoneLevel,
  poseUnchanged,
  showNeck,
  rotateToPortrait,
}
