import React, { RefObject } from 'react'
// import EXIF from 'exif-js'

interface Props {
  setPhoto: (filename: string, data: string) => void
  photoFileRef?: RefObject<HTMLInputElement>
  style?: React.CSSProperties
  className?: string
  id: string
}

export const FileInput = ({ id, photoFileRef, setPhoto, className, style }: Props) => {
  return (
    <input
      id={id}
      type='file'
      style={style}
      accept='image/*'
      ref={photoFileRef}
      className={className}
      onChange={({ target }) => {
        //console.log(target.files)
        const file = target.files![0]

        if (file) {
          try {
            //EXIF
            // try {
            // const reader1 = new FileReader()
            // reader1.readAsDataURL(file)

            // reader1.onloadend = () => {
            //   if (reader1.result) {
            //     // console.log('reader1.result')
            //     // console.log(reader1.result as string)
            //     // console.log((reader.result as string).split(',')[1])
            //     setPhoto(file.name, (reader.result as string).split(',')[1])
            //   } else {
            //     console.error(`[AIT][MTM] FileReader returned: ${reader1.result} when selecting a photo.`)
            //   }
            // }
            // //data:image/jpeg;base64,
            // const file2: Blob = target.files![0]
            // const fileReader = new FileReader()
            // fileReader.onloadend = () => {
            //   console.log('BYTEARRAY')
            //   console.log(fileReader.result)
            //   var view = new DataView(fileReader.result as ArrayBufferLike)

            //   if (view.getUint16(0, false) != 0xffd8) {
            //     console.log('IMAGE No good')
            //   } else {
            //     //0xFFD8
            //     var length = view.byteLength,
            //       offset = 2
            //     while (offset < length) {
            //       if (view.getUint16(offset + 2, false) <= 8) {
            //         console.log('<=8')
            //         break
            //       }
            //       var marker = view.getUint16(offset, false)
            //       offset += 2
            //       if (marker == 0xffe1) {
            //         if (view.getUint32((offset += 2), false) != 0x45786966) {
            //           console.log('0x45786966')
            //           break
            //         }

            //         var little = view.getUint16((offset += 6), false) == 0x4949
            //         offset += view.getUint32(offset + 4, little)
            //         //var tags = view.getUint16(offset, little);
            //         offset += 2
            //         // for (var i = 0; i < tags; i++)
            //         // {
            //         //     if (view.getUint16(offset + (i * 12), little) == 0x0112)
            //         //     {
            //         //         console.log(view.getUint16(offset + (i * 12) + 8, little));
            //         //     }
            //         // }
            //       } else if ((marker & 0xff00) != 0xff00) {
            //         if (offset - 2 > 0) {
            //           console.log('EXIF')
            //           console.log(hex((fileReader.result as ArrayBuffer).slice(0, offset - 2)))
            //           console.log('IMAGE')
            //           console.log(hex((fileReader.result as ArrayBuffer).slice(offset - 2)))
            //         } else {
            //           console.log('JUST IMAGE')
            //           console.log(hex((fileReader.result as ArrayBuffer).slice(offset - 2)))
            //         }
            //         break
            //       } else {
            //         offset += view.getUint16(offset, false)
            //       }
            //     }

            //     console.log('DONE')
            //   }
            //console.log(fileReader.result)

            // const data = EXIF.readFromBinaryFile(fileReader.result)
            // if (data) {
            //   console.log(data)
            // } else {
            //   console.log('no data')
            // }
            //}
            //fileReader.readAsArrayBuffer(file2)
            //} catch {}

            const reader = new FileReader()
            reader.readAsDataURL(file)

            reader.onloadend = () => {
              if (reader.result) {
                setPhoto(file.name, (reader.result as string).split(',')[1])
              } else {
                console.error(`[AIT][MTM] FileReader returned: ${reader.result} when selecting a photo.`)
              }
            }

            reader.onerror = () => {
              console.error(`[AIT][MTM] Couldn't read photo: ${reader.error?.code}:${reader.error?.message}.`)
            }
          } catch (err) {
            console.error(`[AIT][MTM] Couldn't read photo: ${err}.`)
          }
        }
      }}
    />
  )
}
const byteToHex: string[] = []

for (let n = 0; n <= 0xff; ++n) {
  const hexOctet = n.toString(16).toUpperCase().padStart(2, '0')
  byteToHex.push(hexOctet)
}

// function hex(arrayBuffer: ArrayBuffer) {
//   const buff = new Uint8Array(arrayBuffer)
//   const hexOctets = [] // new Array(buff.length) is even faster (preallocates necessary array size), then use hexOctets[i] instead of .push()

//   for (let i = 0; i < buff.length; ++i) hexOctets.push(byteToHex[buff[i]])

//   return hexOctets.join('')
// }
// function _arrayBufferToBase64(buffer: ArrayBuffer) {
//   var binary = ''
//   var bytes = new Uint8Array(buffer)
//   var len = bytes.byteLength
//   for (var i = 0; i < len; i++) {
//     binary += String.fromCharCode(bytes[i])
//   }
//   return window.btoa(binary)
// }
