import React from 'react'
import styled from 'styled-components'
import { RouteComponentProps, navigate } from '@reach/router'
import { Text, Image, Button } from 'rebass/styled-components'

import { variableWidthStyle } from '../style'

import { t, useMotionSensorCheck, MotionSensorState, useWindowHeight } from '@shared-utils'
//import { Info } from 'app/assets/icons'
import { images } from 'app/assets/prepare'
import { i18nKeys } from 'app/assets/18nKeys'
import { isTablet, isMobile } from 'react-device-detect'
import { theme } from 'app/assets/theme'

export const Mobile: React.FC<RouteComponentProps> = () => {
  const { sensorState, checkSensor } = useMotionSensorCheck()
  const height = useWindowHeight()

  if (sensorState === MotionSensorState.disabled) {
    return (
      <Container inaltime={height - 30}>
        <TitleText>{t(i18nKeys.prep.motion.enableAccess)}</TitleText>
        <InstructionText>{t(i18nKeys.prep.motion.instructionLine1)}</InstructionText>
        <InstructionText>{t(i18nKeys.prep.motion.instructionLine2)}</InstructionText>
        <SafariSettingsImage src={images.enableMotion} />
        <Button mt={5} onClick={() => checkSensor()}>
          {t(i18nKeys.prep.motion.tryAgain)}
        </Button>
      </Container>
    )
  }

  return (
    <Container inaltime={height - 30}>
      {/* <TitleText>{t(i18nKeys.prep.mobileTitle)}</TitleText>
      <InfoContainer>
        <FrontPersonImage src={images.frontPerson}></FrontPersonImage>
        <div>
          <InfoButton variant='rounded' onClick={() => navigate('/info')}>
            <Info />
          </InfoButton>
        </div>
        <SidePersonImage src={images.sidePerson}></SidePersonImage>
      </InfoContainer>
      <InstructionText>{t(i18nKeys.prep.mobileInstructionLine1)}</InstructionText>
      <InstructionText>{t(i18nKeys.prep.mobileInstructionLine2)}</InstructionText>
      <InstructionText>{t(i18nKeys.prep.mobileInstructionLine3)}</InstructionText>
      <InstructionText>{t(i18nKeys.prep.mobileInstructionLine4)}</InstructionText> */}
      <CameraImage src={images.cameraIcon}></CameraImage>
      <CameraText>{t(i18nKeys.prep.mobileCameraText)}</CameraText>
      <Button
        mt={4}
        onClick={() =>
          checkSensor(result => {
            if (result !== MotionSensorState.disabled) {
              if (result === MotionSensorState.absent) {
                alert(t(i18nKeys.camera.motionSensorsError) + ': ' + t(i18nKeys.camera.incompatible))
              }
              navigate('/camera')
            }
          })
        }
      >
        {t(i18nKeys.prep.mobileTakePicture)}
      </Button>
    </Container>
  )
}

const Container = styled.div<{ inaltime: number }>`
  display: ${isTablet || isMobile ? 'flex' : 'none'};
  height: ${({ inaltime }) => inaltime - 100}px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: flex;
  }
  ${variableWidthStyle};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  @media screen and (max-height: 600px) {
    padding-top: 5px;
  }
`

const TitleText = styled(Text)`
  font-weight: 800;
  line-height: 19px;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[2]}px;
`
/*
const InfoContainer = styled.div`
  margin-top: 35px;
  justify-content: center;
  display: flex;
  align-items: flex-start;
  @media screen and (max-height: 600px) {
    margin-top: 5px;
  }
`
*/
const SafariSettingsImage = styled(Image)`
  margin-top: 20px;
  width: 60%;
`

// const FrontPersonImage = styled(Image)`
//   width: 35%;
// `
/*
const SidePersonImage = styled(Image)`
  width: 35%;
  margin-left: 14px;
`
const InfoButton = styled(Button)`
  position: absolute;
  margin-top: 60px;
`
*/
const InstructionText = styled(Text)`
  font-weight: 600;
  margin-top: 20px;
  line-height: 14px;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[0]}px;
  @media screen and (max-height: 600px) {
    margin-top: 15px;
  }
`
const CameraImage = styled(Image)`
  margin-top: 0px;
  width: 10%;
  @media screen and (max-height: 600px) {
    margin-top: 0px;
  }
`

const CameraText = styled(Text)`
  margin-top: 5px;
  font-weight: 600;
  line-height: 17px;
  font-size: ${theme.fontSizes[1]}px;
  color: ${theme.colors.secondary};
`
