import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { Text, Button } from 'rebass/styled-components'
import { navigate } from '@reach/router'

import { t } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { ApplicationContext } from 'app/Context'
import { ComputerPhotos } from './components/ComputerPhotos'
import { isTablet } from 'react-device-detect'
import { theme } from 'app/assets/theme'

export const AnalysisErrorScreen: React.FC<any> = ({ location }) => {
  const {
    state: {
      user: {
        photos: {
          base64: { front, side },
        },
      },
    },
  } = useContext(ApplicationContext)

  const sidePhoto = location?.state?.side as { imageData: string; errorMessage: string }
  const frontPhoto = location?.state?.front as { imageData: string; errorMessage: string }

  if (!sidePhoto && !frontPhoto) {
    console.error(
      '[AIT][MTM] Invalid state encountered in screen [AnalysisErrorScreen]. Missing sidePhoto and frontPhoto errors.'
    )
    navigate('/start')
  }

  let uploadTextDesktop = makeUploadText(sidePhoto?.errorMessage, frontPhoto?.errorMessage)
  useEffect(() => {
    var element = document.getElementById('Top')
    if (element != null) {
      var parent = element.parentElement
      if (parent != null) {
        parent.style.height = '99%'
      }
    }
  }, [])
  return (
    <PageContainer id='Top'>
      <ComponentsContainer>
        <TitleText>{t(i18nKeys.analysiserror.title)}</TitleText>
        <ErrorsContainer>
          <Text display={frontPhoto.errorMessage ? 'block' : 'none'} style={{ marginBottom: '10px' }}>
            {frontPhoto.errorMessage}
          </Text>
          <Text display={sidePhoto.errorMessage ? 'block' : 'none'}>{sidePhoto.errorMessage}</Text>
        </ErrorsContainer>

        <ComputerPhotos sidePhoto={sidePhoto} frontPhoto={frontPhoto}></ComputerPhotos>

        <MobileErrorMessage>
          <MobileText>{t(i18nKeys.analysiserror.mobilePrefixError)}</MobileText>
          <MobileErrorText>
            {!sidePhoto.errorMessage && frontPhoto.errorMessage
              ? t(i18nKeys.analysiserror.mobileFrontPhotoError)
              : sidePhoto.errorMessage && !frontPhoto.errorMessage
              ? t(i18nKeys.analysiserror.mobileSidePhotoError)
              : t(i18nKeys.analysiserror.mobilePhotosError)}
          </MobileErrorText>
          <MobileText>
            {t(
              sidePhoto.errorMessage && frontPhoto.errorMessage
                ? i18nKeys.analysiserror.mobileSuffixErrorMultiple
                : i18nKeys.analysiserror.mobileSuffixError
            )}
          </MobileText>
        </MobileErrorMessage>

        <DesktopUploadPictureText>{uploadTextDesktop}</DesktopUploadPictureText>
        <MobileUploadPictureText>
          {t(
            sidePhoto.errorMessage && frontPhoto.errorMessage
              ? i18nKeys.analysiserror.mobileInstructionMultiple
              : i18nKeys.analysiserror.mobileInstruction
          )}
        </MobileUploadPictureText>
      </ComponentsContainer>
      <DesktopNextButton onClick={() => navigate('/analyzing', { state: {} })} disabled={!(front && side)}>
        {t(i18nKeys.next)}
      </DesktopNextButton>
      <MobileNextButton onClick={() => navigate('/camera')}>
        {!sidePhoto.errorMessage && frontPhoto.errorMessage
          ? t(i18nKeys.analysiserror.mobileRetakeFrontButtonText)
          : sidePhoto.errorMessage && !frontPhoto.errorMessage
          ? t(i18nKeys.analysiserror.mobileRetakeSideButtonText)
          : t(i18nKeys.analysiserror.mobileRetakePhotosButtonText)}
      </MobileNextButton>
    </PageContainer>
  )
}

function makeUploadText(sidePhotoError?: string, frontPhotoError?: string) {
  let text = ''
  if (sidePhotoError && frontPhotoError) {
    text = t(i18nKeys.analysiserror.sideAndFrontPhotoError)
  }

  if (frontPhotoError && !sidePhotoError) {
    text = t(i18nKeys.analysiserror.frontPhotoError)
  }

  if (sidePhotoError && !frontPhotoError) {
    text = t(i18nKeys.analysiserror.sidePhotoError)
  }

  return text
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  text-align: center;
  align-items: center;
`

const ComponentsContainer = styled.div`
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  width: 60%;
  text-align: center;
  align-items: center;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    width: 70%;
    padding-top: 30px;
  }
  ${isTablet ? 'width: 70%; padding-top: 30px;' : ''}
  @media screen and (max-width: 580px) {
    width: 80%;
    padding-top: 20px;
  }
`

const ErrorsContainer = styled.div`
  margin-top: 20px;
`

const TitleText = styled(Text)`
  font-weight: 800;
  line-height: 24px;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[5]}px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    font-weight: 600;
    font-size: ${theme.fontSizes[4]}px;
  }
  ${isTablet ? 'font-weight: 600; font-size: ' + theme.fontSizes[4] + 'px;' : ''}
`

const DesktopUploadPictureText = styled(Text)`
  font-weight: 600;
  margin-top: 40px;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[2]}px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: none;
  }
  ${isTablet ? 'display: none;' : ''}
`

const DesktopNextButton = styled(Button)`
  margin-top: 50px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: none;
  }
  ${isTablet ? 'display: none;' : ''}
`

const MobileNextButton = styled(Button)`
  margin-top: 50px;
  display: none;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: block;
    width: 240px;
  }
  ${isTablet ? 'display: block; width: 240px;' : ''}
`

const MobileErrorMessage = styled.div`
  display: none;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  ${isTablet
    ? 'display: flex; margin-top: 50px; flex-direction: column; justify-content: center; text-align: center;'
    : ''}
`
//@media screen and (max-width: ${theme.breakpoints[0]}) {
const MobileText = styled(Text)`
  margin-bottom: 8px;
  font-size: ${theme.fontSizes[2]}px;
`

const MobileErrorText = styled(Text)`
  margin-bottom: 8px;
  font-weight: 800;
  font-size: ${theme.fontSizes[2]}px;
  color: ${theme.colors.secondary};
`

const MobileUploadPictureText = styled(Text)`
  display: none;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: block;
    margin-top: 50px;
    font-size: ${theme.fontSizes[2]}px;
  }
  ${isTablet ? 'display: block; margin-top: 50px; font-size: ' + theme.fontSizes[2] + 'px;' : ''}
`
