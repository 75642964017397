import { AppAction } from 'app/domain/contracts'
import { UpdatableShopFields } from 'app/domain/shop/contracts'

export const updateShopAction = (dispatch: React.Dispatch<AppAction>) => (fields: UpdatableShopFields) => {
  dispatch({
    entity: 'shop',
    name: 'updateShop',
    payload: fields,
  })
}
