import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Button, Text } from 'rebass/styled-components'

import { t, myIsIOS } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { images } from 'app/assets/placeholders'
import { Direction } from 'app/domain/contracts'
import { PhotoAngles } from 'app/domain/user/contracts'
import { useCamera, requestOrientationPermit } from '../useCamera'
import { mediaContainerStyle } from './style'
import { FileSelector } from './FileSelector'
import { CameraButton } from './CameraButton'
import { InfoLinkButton } from './InfoLinkButton'
import { isIOS, isIOS13 } from 'react-device-detect'
import { theme } from 'app/assets/theme'

interface CameraControlProps {
  direction: Direction
  onCapture: (image: string, angles?: PhotoAngles) => void
}

const overlay = {
  front: images.silhouetteFrontSmall,
  side: images.silhouetteSideSmall,
}

export const CameraControl: React.FC<CameraControlProps> = ({ direction, onCapture }: CameraControlProps) => {
  const { sensorInfo, captureImage } = useCamera(onCapture)

  const selectExistingPhoto = (filename: string, rawPhoto: string) => {
    if (filename.startsWith(`ait-mtm-${direction}`)) {
      return onCapture(rawPhoto)
    }
    alert(t(i18nKeys.prep.invalidPhoto))
  }
  // const isIOS = () => {
  //   return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  // }

  // const requestDeviceOrientationPermissions = async () => {

  //     const permissionState = await window.DeviceOrientationEvent.requestPermission();

  //     if (permissionState === "granted") {
  //       console.log('granted')
  //     } else {
  //         console.log('not granted')
  //     }
  //   }
  // }

  const checkPermissions = () => {
    return Promise.all([navigator.permissions.query({ name: 'xr-spatial-tracking' })]).then(results => {
      return results.every(result => result.state === 'granted')
    })
  }

  useEffect(() => {
    var element = document.getElementById('Top')
    if (element != null) {
      var parent = element.parentElement
      if (parent != null) {
        parent.style.height = '99%'
      }
    }
  }, [])

  return (
    <>
      {!myIsIOS(isIOS, isIOS13) || (myIsIOS(isIOS, isIOS13) && checkPermissions) ? (
        <Container id='Top' className='ait-camera-container ait-container'>
          <MediaContainer id='aitCameraView'>
            <Video id='aitCameraVideo' playsInline></Video>
            <Overlay direction={direction} src={overlay[direction]} />
          </MediaContainer>
          <Text mb={3} variant='label'>
            {t(i18nKeys.camera[direction])}
          </Text>
          <Actions>
            <InfoLinkButton />
            <CameraButton sensorInfo={sensorInfo} onClick={captureImage} />
            <FileSelector setPhoto={selectExistingPhoto} />
          </Actions>
          <CameraTip mt={3}>{t(i18nKeys.camera.pressWhenGreen)}</CameraTip>
        </Container>
      ) : (
        <ContainerPermit id='Top'>
          <BigOrangeText>{t(i18nKeys.prep.motion.instructionLine1)}</BigOrangeText>
          <br />
          <br />
          <Button onClick={requestOrientationPermit}>{t(i18nKeys.prep.motion.enableAccess)}</Button>
        </ContainerPermit>
      )}
    </>
  )
}

const BigOrangeText = styled(Text)`
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 80%;
  line-height: ${theme.fontSizes[8] + 4}px;
  color: ${theme.colors.secondary};
  font-size: ${theme.fontSizes[8]}px;
  @media screen and (max-height: 600px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`
const ContainerPermit = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const MediaContainer = styled.div`
  ${mediaContainerStyle}
  overflow: hidden;
`

const Video = styled.video`
  max-height: 130%;
  -moz-transform: scale(1.6);
  -webkit-transform: scale(1.6);
  -o-transform: scale(1.6);
  -ms-transform: scale(1.6);
  transform: scale(1.6);
`

const Overlay = styled.img<{ direction: string }>`
  opacity: 0.65;
  position: absolute;
  bottom: 10px;
  right: 20px;
  width: 50px;
`
//width 50px;

const Actions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`

const CameraTip = styled(Text)`
  font-size: 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
