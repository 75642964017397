import React, { useRef, useState } from 'react'

import styled from 'styled-components'
import { Image, Text } from 'rebass/styled-components'

import { i18nKeys } from 'app/assets/18nKeys'
import { images } from 'app/assets/analyzing'
import { Direction } from 'app/domain/contracts'
import { t, toBase64ImageSrc } from '@shared-utils'
import { FileInput } from 'app/components/FileInput'
import { theme } from 'app/assets/theme'

interface Props {
  display: boolean
  imageBase64: string
  fileNamePrefix: string
  direction: Direction
  onPhotoSelected: (base64Data: string) => void
}

export const ComputerPhoto: React.FC<Props> = ({
  display,
  direction,
  imageBase64,
  fileNamePrefix,
  onPhotoSelected,
}) => {
  const photoFile = useRef<HTMLInputElement>(null)
  const [validPhoto, setValidPhoto] = useState(false)

  const validationText =
    direction === 'front' ? t(i18nKeys.analysiserror.computerPhotoFront) : t(i18nKeys.analysiserror.computerPhotoSide)

  return display ? (
    <Container>
      {!validPhoto ? <ValidationText>{validationText}</ValidationText> : <div></div>}
      <Photo src={toBase64ImageSrc(imageBase64)} onClick={() => photoFile.current!.click()} />
      {!validPhoto ? <CameraIcon src={images.retakePhotoCamera} onClick={() => photoFile.current!.click()} /> : null}
      <FileInput
        id={`file-${direction}`}
        style={{ display: 'none' }}
        setPhoto={(filename, rawPhoto) => {
          if (filename.startsWith(fileNamePrefix)) {
            setValidPhoto(true)
            onPhotoSelected(rawPhoto)
          } else {
            onPhotoSelected('')
            setValidPhoto(false)
          }
        }}
        photoFileRef={photoFile!}
      />
      {!validPhoto ? <ValidationText>{t(i18nKeys.analysiserror.computerPhotoSuffix)}</ValidationText> : <div></div>}
    </Container>
  ) : null
}

const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ValidationText = styled(Text)`
  font-weight: 600;
  margin: 6px;
  color: ${theme.colors.secondary};
  font-size: ${theme.fontSizes[0]}px;
`

const CameraIcon = styled(Image)`
  position: absolute;
  max-width: 30%;
  left: 50%;
  top: 42%;
  transform: translate(-50%, -50%);
`

const Photo = styled(Image)`
  width: 100px;
  height: 140px;
  border: 1px solid ${theme.colors.secondary};
  :hover {
    cursor: pointer;
  }
`
