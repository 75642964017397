export const i18nKeys = {
  next: 'next',
  back: 'back',
  front: 'front',
  side: 'side',
  skipTutorial: 'skipTutorial',
  rotateDevice: 'rotateDevice',
  notSafariOniOS: 'notSafariOniOS',
  notSafariOniOSNoQR: 'notSafariOniOSNoQR',
  openInSafari: 'openInSafari',
  info: {
    frontViewTitle: 'info.frontViewTitle',
    sideViewTitle: 'info.sideViewTitle',
    phoneLevel: {
      title: 'info.phoneLevel.title',
      desc: 'info.phoneLevel.desc',
    },
    cleanBackground: {
      title: 'info.cleanBackground.title',
      desc: 'info.cleanBackground.desc',
    },
    fittingClothes: {
      title: 'info.fittingClothes.title',
      desc: 'info.fittingClothes.desc',
    },
    showNeck: {
      title: 'info.showNeck.title',
      desc: 'info.showNeck.desc',
    },
    poseUnchanged: {
      title: 'info.poseUnchanged.title',
      desc: 'info.poseUnchanged.desc',
    },
    followInstructions: 'info.followInstructions',
    back: 'info.back',
    takePictures: 'info.takePictures',
  },
  footer: {
    backToStore: 'footer.backToStore',
    previousStep: 'footer.previousStep',
    exitSetup: 'footer.exitSetup',
  },
  start: {
    title: 'start.title',
    subtitle: 'start.subtitle',
    or: 'start.or',
    name: 'start.name',
    nameRequired: 'start.nameRequired',
    surname: 'start.surname',
    surnameRequired: 'start.surnameRequired',
    profileId: 'start.profileId',
    profileIdRequired: 'start.profileIdRequired',
    profileIdInvalid: 'start.profileIdInvalid',
    height: 'start.height',
    heightRequired: 'start.heightRequired',
    weight: 'start.weight',
    age: 'start.age',
    weightRequired: 'start.weightRequired',
    ageRequired: 'start.ageRequired',
    moveSlider: 'start.moveSlider',
    accurateData: 'start.accurateData',
    sliderWeight: 'start.sliderWeight',
    sliderHeight: 'start.sliderHeight',
    sliderAge: 'start.sliderAge',
    sliderCalf: 'start.sliderCalf',
    sliderThigh: 'start.sliderThigh',
    sliderNeck: 'start.sliderNeck',
    sliderChest: 'start.sliderChest',
    sliderWaist: 'start.sliderWaist',
    sliderBasin: 'start.sliderBasin',
    cancel: 'start.cancel',
    confirm: 'start.confirm',
    chooseMeasurementUnit: 'start.chooseMeasurementUnit',
    kgCm: 'start.kgCm',
    lbsFeet: 'start.lbsFeet',
    retakeProfile: 'start.retakeProfile',
    terms: {
      disclaimer: 'start.terms.disclaimer',
      accept: 'start.terms.accept',
      termsAndConditions: 'start.terms.termsAndConditions',
    },
  },
  jeansOption: {
    calf: 'jeansOption.calf',
    calfRequired: 'jeansOption.calfRequired',
    thigh: 'jeansOption.thigh',
    thighRequired: 'jeansOption.thighRequired',
    calculated: 'jeansOption.calculated',
    pageTitle: 'jeansOption.pageTitle',
    tutPageSubtitle: 'jeansOption.tutPageSubtitle',
    tutTitleThigh: 'jeansOption.tutTitleThigh',
    tutTitleCalf: 'jeansOption.tutTitleCalf',
    tutInfoThigh: 'jeansOption.tutInfoThigh',
    tutInfoCalf: 'jeansOption.tutInfoCalf',
    measPageInstructions: 'jeansOption.measPageInstructions',
  },
  moreMeasurements: {
    neck: 'moreMeasurements.neck',
    neckRequired: 'moreMeasurements.neckRequired',
    chest: 'moreMeasurements.chest',
    chestRequired: 'moreMeasurements.chestRequired',
    waist: 'moreMeasurements.waist',
    waistRequired: 'moreMeasurements.waistRequired',
    basin: 'moreMeasurements.basin',
    basinRequired: 'moreMeasurements.basinRequired',
    pageTitle: 'moreMeasurements.pageTitle',
    tutPageSubtitle: 'moreMeasurements.tutPageSubtitle',
    tutTitleThigh: 'moreMeasurements.tutTitleThigh',
    tutTitleCalf: 'moreMeasurements.tutTitleCalf',
    tutTitleNeck: 'moreMeasurements.tutTitleNeck',
    tutTitleChest: 'moreMeasurements.tutTitleChest',
    tutTitleWaist: 'moreMeasurements.tutTitleWaist',
    tutTitleBasin: 'moreMeasurements.tutTitleBasin',
    tutInfoThigh: 'moreMeasurements.tutInfoThigh',
    tutInfoCalf: 'moreMeasurements.tutInfoCalf',
    tutInfoNeck: 'moreMeasurements.tutInfoNeck',
    tutInfoChest: 'moreMeasurements.tutInfoChest',
    tutInfoWaist: 'moreMeasurements.tutInfoWaist',
    tutInfoBasin: 'moreMeasurements.tutInfoBasin',
    measPageInstructions: 'moreMeasurements.measPageInstructions',
  },
  prep: {
    title: 'prep.title',
    subtitle: 'prep.subtitle',
    mobileTitle: 'prep.mobileTitle',
    invalidPhoto: 'prep.invalidPhoto',
    mobileCameraText: 'prep.mobileCameraText',
    mobileTakePicture: 'prep.mobileTakePicture',
    qrcodeInstruction: 'prep.qrcodeInstruction',
    computerInstruction: 'prep.computerInstruction',
    mobileInstructionLine1: 'prep.mobileInstructionLine1',
    mobileInstructionLine2: 'prep.mobileInstructionLine2',
    mobileInstructionLine3: 'prep.mobileInstructionLine3',
    mobileInstructionLine4: 'prep.mobileInstructionLine4',
    copyLink: 'prep.copyLink',
    motion: {
      enableAccess: 'prep.motion.enableAccess',
      instructionLine1: 'prep.motion.instructionLine1',
      instructionLine2: 'prep.motion.instructionLine2',
      tryAgain: 'prep.motion.tryAgain',
      notSupported: 'prep.motion.notSupported',
    },
  },
  camera: {
    front: 'camera.front',
    side: 'camera.side',
    info: 'camera.info',
    gallery: 'camera.gallery',
    pressWhenGreen: 'camera.pressWhenGreen',
    noPreview: 'camera.noPreview',
    doYouLikeThePicture: 'camera.doYouLikeThePicture',
    no: 'camera.no',
    yes: 'camera.yes',
    incompatible: 'camera.incompatible',
    captureError: 'camera.captureError',
    error: 'camera.error',
    motionSensorsError: 'camera.motionSensorsError',
  },
  analyzing: {
    title: 'analyzing.title',
    subtitle: 'analyzing.subtitle',
    quickTips: 'analyzing.quicktips',
    tip1: 'analyzing.tip1',
    tip2: 'analyzing.tip2',
  },
  analysiserror: {
    title: 'analysiserror.title',
    sideAndFrontPhotoError: 'analysiserror.sideAndFrontPhotoError',
    frontPhotoError: 'analysiserror.frontPhotoError',
    sidePhotoError: 'analysiserror.sidePhotoError',
    mobilePhotosError: 'analysiserrorr.mobilePhotosError',
    mobileFrontPhotoError: 'analysiserrorr.mobileFrontPhotoError',
    mobileSidePhotoError: 'analysiserrorr.mobileSidePhotoError',
    mobileRetakeFrontButtonText: 'analysiserror.mobileRetakeFrontButtonText',
    mobileRetakeSideButtonText: 'analysiserror.mobileRetakeSideButtonText',
    mobileRetakePhotosButtonText: 'analysiserror.mobileRetakePhotosButtonText',
    mobileInstruction: 'analysiserror.mobileInstruction',
    mobileInstructionMultiple: 'analysiserror.mobileInstructionMultiple',
    mobilePrefixError: 'analysiserror.mobilePrefixError',
    mobileSuffixErrorMultiple: 'analysiserror.mobileSuffixErrorMultiple',
    mobileSuffixError: 'analysiserror.mobileSuffixError',
    computerPhotoFront: 'analysiserror.computerPhotoFront',
    computerPhotoSide: 'analysiserror.computerPhotoSide',
    computerPhotoSuffix: 'analysiserror.computerPhotoSuffix',
  },
  error: {
    title: 'error.title',
    text1: 'error.text1',
    text2: 'error.text2',
    tryAgain: 'error.tryAgain',
  },
  desktopToMobile: {
    waitingForMobileText: 'desktopToMobile.waitingForMobileText',
    continueOnDesktopText: 'desktopToMobile.continueOnDesktopText',
  },
  fitPreferences: {
    title: 'fitPreferences.title',
    jacket: 'fitPreferences.jacket',
    trousers: 'fitPreferences.trousers',
    shirt: 'fitPreferences.shirt',
    comfort: 'fitPreferences.comfort',
    adjusted: 'fitPreferences.adjusted',
    normal: 'fitPreferences.normal',
    mode: 'fitPreferences.mode',
    regular: 'fitPreferences.regular',
    slim: 'fitPreferences.slim',
    skinny: 'fitPreferences.skinny',
    short: 'fitPreferences.short',
    long: 'fitPreferences.long',
    semislim: 'fitPreferences.semislim',
    tucked: 'fitPreferences.tucked',
    untucked: 'fitPreferences.untucked',
    left: 'fitPreferences.left',
    nobreak: 'fitPreferences.nobreak',
    slightbreak: 'fitPreferences.slightbreak',
    right: 'fitPreferences.right',
    none: 'fitPreferences.none',
    chooseJacketFit: 'fitPreferences.chooseJacketFit',
    chooseShirtFit: 'fitPreferences.chooseShirtFit',
    trousersFit: 'fitPreferences.trousersFit',
    chooseSleeveLength: 'fitPreferences.chooseSleeveLength',
    chooseJacketLength: 'fitPreferences.chooseJacketLength',
    chooseShirtLength: 'fitPreferences.chooseShirtLength',
    chooseWatchSide: 'fitPreferences.chooseWatchSide',
    chooseTrousersLength: 'fitPreferences.chooseTrousersLength',
    textJacketLength: 'fitPreferences.textJacketLength',
    textJacketWaist: 'fitPreferences.textJacketWaist',
    textJacketSleeveLength: 'fitPreferences.textJacketSleeveLength',
    textTrousersWaist: 'fitPreferences.textTrousersWaist',
    textTrousersInseam: 'fitPreferences.textTrousersInseam',
    textShirtWaist: 'fitPreferences.textShirtWaist',
    textShirtNeck: 'fitPreferences.textShirtNeck',
    textShirtLength: 'fitPreferences.textShirtLength',
    tooLoose: 'fitPreferences.tooLoose',
    tooTight: 'fitPreferences.tooTight',
    tooLong: 'fitPreferences.tooLong',
    tooShort: 'fitPreferences.tooShort',
  },
  choosePath: {
    headerText: 'choosePath.headerText',
    photosBtn: 'choosePath.photosBtn',
    photosFriendBtn: 'choosePath.photosFriendBtn',
    photosSelfBtn: 'choosePath.photosSelfBtn',
    accuracyText: 'choosePath.accuracyText',
    surveyBtn: 'choosePath.surveyBtn',
    orText: 'choosePath.orText',
  },
  survey: {
    flat: 'survey.flat',
    regular: 'survey.regular',
    proeminent: 'survey.proeminent',
    narrow: 'survey.narrow',
    wide: 'survey.wide',
    chest: 'survey.chest',
    abdomen: 'survey.abdomen',
    hips: 'survey.hips',
    flatChest: 'survey.flatChest',
    flatAbdomen: 'survey.flatAbdomen',
    continueBtn: 'survey.continueBtn',
    regularHips: 'survey.regularHips',
    recenterView: 'survey.recenterView',
  },
  photosOK: {
    title: 'photosOK.title',
    button: 'photosOK.button',
    textOK: 'photosOK.textOK',
    text1: 'photosOK.text1',
    text2: 'photosOK.text2',
    text3: 'photosOK.text3',
    buttonMoreMeas: 'photosOK.buttonMoreMeas',
    textOR: 'photosOK.textOR',
  },
  measurementProfile: {
    title: 'measurementProfile.title',
    subtitle: 'measurementProfile.subtitle',
    summary: 'measurementProfile.summary',
    profileId: 'measurementProfile.profileId',
    weight: 'measurementProfile.weight',
    age: 'measurementProfile.age',
    years: 'measurementProfile.years',
    height: 'measurementProfile.height',
    name: 'measurementProfile.name',
    surname: 'measurementProfile.surname',
    jacketFit: 'measurementProfile.jacketFit',
    trousersFit: 'measurementProfile.trousersFit',
    trousersLength: 'measurementProfile.trousersLength',
    shirtFit: 'measurementProfile.shirtFit',
    saveProfile: 'measurementProfile.saveProfile',
    clearData: 'measurementProfile.clearData',
    clearDataConfirm: 'measurementProfile.clearDataConfirm',
    sizetitle: 'measurementProfile.sizetitle',
    thigh: 'measurementProfile.thigh',
    calf: 'measurementProfile.calf',
    neck: 'measurementProfile.neck',
    chest: 'measurementProfile.chest',
    waist: 'measurementProfile.waist',
    basin: 'measurementProfile.basin',
    outofscale: 'measurementProfile.outofscale',
  },
  tutorial: {
    correct: 'tutorial.correct',
    incorrect: 'tutorial.incorrect',
    step1: {
      title: 'tutorial.step1.title',
      text1: 'tutorial.step1.text1',
      text2: 'tutorial.step1.text2',
      text3: 'tutorial.step1.text3',
      text4: 'tutorial.step1.text4',
    },
    step2: {
      title: 'tutorial.step2.title',
      text1: 'tutorial.step2.text1',
      text2: 'tutorial.step2.text2',
      text3: 'tutorial.step2.text3',
      text4: 'tutorial.step2.text4',
      text5: 'tutorial.step2.text5',
      text6: 'tutorial.step2.text6',
    },
    step3: {
      title: 'tutorial.step3.title',
      text1: 'tutorial.step3.text1',
      text2: 'tutorial.step3.text2',
    },
    step4: {
      title: 'tutorial.step4.title',
      text1: 'tutorial.step4.text1',
      text2: 'tutorial.step4.text2',
    },
    step5: {
      title: 'tutorial.step5.title',
      text1: 'tutorial.step5.text1',
      text2: 'tutorial.step5.text2',
      text3: 'tutorial.step5.text3',
    },
    step6: {
      title: 'tutorial.step6.title',
      text1: 'tutorial.step6.text1',
      text2: 'tutorial.step6.text2',
      text3: 'tutorial.step5.text3',
    },
    step7: {
      title: 'tutorial.step7.title',
      text1: 'tutorial.step7.text1',
      text2: 'tutorial.step7.text2',
    },
    step8: {
      title: 'tutorial.step8.title',
      text1: 'tutorial.step8.text1',
      text2: 'tutorial.step8.text2',
    },
  },
}
