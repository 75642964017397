import React from 'react'
import styled from 'styled-components'
import { Text } from 'rebass/styled-components'

import { t } from '@shared-utils'
import { Gallery } from 'app/assets/icons'
import { i18nKeys } from 'app/assets/18nKeys'
import { FileInput } from 'app/components/FileInput'

interface FileInputProps {
  setPhoto: (filename: string, data: string) => void
}

export const FileSelector: React.FC<FileInputProps> = ({ setPhoto }: FileInputProps) => {
  return (
    <Container>
      <Gallery />
      <Text variant='iconButtonLabel'>{t(i18nKeys.camera.gallery)}</Text>
      <Input id='file-picker' setPhoto={setPhoto} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 70px;
  align-items: center;
`

const Input = styled(FileInput)`
  opacity: 0;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
`
