import React from 'react'
import { Text, Flex } from 'rebass/styled-components'

import { ImageCheckbox } from './ImageCheckbox'
import { t } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'

export type Option =
  | 'comfort'
  | 'regular'
  | 'slim'
  | 'long'
  | 'short'
  | 'tucked'
  | 'untucked'
  | 'left'
  | 'right'
  | 'none'
  | 'nobreak'
  | 'slightbreak'
  | 'semislim'
  | 'skinny'
  | 'adjusted'
//| 'straight'
interface Props {
  titleKey: string
  category: string
  height: number
  images: {
    [s: string]: string
  }
  options: Option[]
  preferences: any
  onChange: (o: any) => void
}

// const defaults: any = {
//   fit: 'adjustedsemislim',
//   jacketLength: 'regular',
//   sleeveLength: 'regular',
//   shirtLength: 'tucked',
//   watchSide: 'none',
//   length: 'slightbreak',
// }

export const OptionsRow: React.FC<Props> = ({
  titleKey,
  category,
  height,
  images,
  options,
  preferences,
  onChange,
}: Props) => {
  return (
    <>
      <Text variant='label' mb={[3, 4]}>
        {t(titleKey)}
      </Text>
      <Flex justifyContent='space-around' mb={[2, 2]}>
        {options.map(o => (
          <ImageCheckbox
            key={o}
            alt={t(i18nKeys.fitPreferences[o])}
            src={images[o]}
            label={
              (titleKey === i18nKeys.fitPreferences.chooseSleeveLength && o === 'regular') ||
              (titleKey === i18nKeys.fitPreferences.chooseJacketLength && o === 'regular')
                ? t(i18nKeys.fitPreferences['normal'])
                : t(i18nKeys.fitPreferences[o]) +
                  (titleKey === i18nKeys.fitPreferences.chooseJacketLength && o === 'short'
                    ? ' (' + t(i18nKeys.fitPreferences['mode']) + ')'
                    : '')
            }
            height={height}
            isLabelBold={preferences[category] === o}
            //   defaults[category.toString()].toString().indexOf(o.toString()) === 0 ||
            //   defaults[category.toString()].toString().indexOf(o.toString()) === 8
            //     ? true
            //     : false
            // }
            isChecked={preferences[category] === o}
            onClick={() => {
              onChange({ ...preferences, [category]: o })
            }}
          />
        ))}
      </Flex>
    </>
  )
}
