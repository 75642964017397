import React, { useState, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { LocationProvider } from '@reach/router'
import DeviceOrientation, { Orientation } from 'react-screen-orientation'

import './assets/global.css'

import { Footer } from './components'
import { theme } from './assets/theme'
import { AppRouter } from './navigation/Router'
import { t } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { Image } from 'rebass/styled-components'
import { images } from 'app/assets/info'
import { Text } from 'rebass/styled-components'
import { isMobile, isTablet } from 'react-device-detect'
import { ApplicationProvider } from 'app/Context'

export const Root = () => {
  const [height, setHeight] = useState(window.innerHeight)
  const tKey = 'rotateToPortrait'

  useEffect(() => {
    const resize = () => setHeight(window.innerHeight)

    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <ApplicationProvider>
        {isMobile || isTablet ? (
          <DeviceOrientation lockOrientation={'portrait'}>
            <Orientation orientation='portrait' alwaysRender={false}>
              <AppContainer id='appCont'>
                <AppRouter />
                <LocationProvider>
                  <Footer />
                </LocationProvider>
              </AppContainer>
            </Orientation>
            <Orientation orientation='landscape' alwaysRender={false}>
              <Container>
                <BigOrangeText variant='profileLabel'>{t(i18nKeys.rotateDevice)}</BigOrangeText>
                <InstructionImage src={images[tKey]} height={height} />
              </Container>
            </Orientation>
          </DeviceOrientation>
        ) : (
          <AppContainer id='appCont'>
            <AppRouter />
            <LocationProvider>
              <Footer />
            </LocationProvider>
          </AppContainer>
        )}
      </ApplicationProvider>
    </ThemeProvider>
  )
}
//${window.innerHeight === 0 ? '' : 'max-height: ' + window.innerHeight + 'px;'}
const AppContainer = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  position: relative;
  padding-bottom: 70px;
  box-sizing: border-box;
  overflow-y: auto;
`

const BigOrangeText = styled(Text)`
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: ${theme.fontSizes[8] + 4}px;
  color: ${theme.colors.secondary};
  font-size: ${theme.fontSizes[8]}px;
  @media screen and (max-height: 600px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`

const InstructionImage = styled(Image)<{ height: number }>`
  max-height: calc(${window.innerHeight}px - 50px);
  max-width: 100%;
  width: 150px;
  height: auto;
  top: 0;
  margin: 10px;
`

const Container = styled.div`
  min-height: calc(${window.innerHeight}px - 50px);
  overflow: hidden;
  flex-direction: column;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 10px;
`
