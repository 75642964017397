import React from 'react'

export const Shirt = (props: any) => (
  <svg
    id='Outline'
    xmlns='http://www.w3.org/2000/svg'
    width='22.189'
    height='22.188'
    viewBox='0 0 22.189 22.188'
    fill='currentColor'
    {...props}
  >
    <path
      id='Path_2526'
      data-name='Path 2526'
      d='M38.186,37.038l-1.8-16.213a1.111,1.111,0,0,0-.666-.9l-2.192-.94-.016-.006h0L30.705,17.93l-.679-1.7a.37.37,0,0,0-.3-.23l-.042,0H24.506l-.042,0a.37.37,0,0,0-.3.23l-.74,1.849a.37.37,0,0,0,.005.288L24.907,21.7a.37.37,0,0,0,.569.139l1.248-1V34.43l-1,3.011a4.819,4.819,0,0,1-3.626-1.975l-.917-3.976V19.585l1.732-.65-.26-.693-1.972.74L20.643,19h0l-2,1a1.116,1.116,0,0,0-.5.5l-2.031,4.061a1.116,1.116,0,0,0-.117.5v5.938a1.115,1.115,0,0,0,.117.5l3.25,6.5a.37.37,0,0,0,.536.142l2.219-1.479.015-.012a5.557,5.557,0,0,0,3.848,1.553.37.37,0,0,0,.351-.253l.759-2.276.759,2.276a.37.37,0,0,0,.351.253,5.547,5.547,0,0,0,5.547-5.548v-.109l1.117,5.362a.37.37,0,0,0,.464.28l2.589-.74a.37.37,0,0,0,.266-.4Zm-18.345.24-3.063-6.126a.372.372,0,0,1-.039-.165V25.05a.372.372,0,0,1,.039-.165l2.031-4.061a.372.372,0,0,1,.165-.165l1.463-.732v9.146l-.756-2.457-.707.218,1.475,4.8.626,2.713-1.569.941.38.634,1.363-.818.248,1.074Zm10.18-19.067L28.8,20.959,27.6,20l2-2.854Zm-2.927,1.212-.584-.834h1.168Zm1.1-1.574h-2.2l-.777-1.109h3.757Zm-2.808,3.11-1.221-2.748.428-1.069,2,2.854Zm7.623,11.682a4.807,4.807,0,0,1-4.544,4.8l-1-3.012V20.837l1.248,1a.37.37,0,0,0,.569-.139l1.338-3.01,2.392.9Zm2.5,4.712-1.76-8.449V19.889l1.677.719a.37.37,0,0,1,.222.3l1.6,14.362-1.366.341.179.718,1.269-.317.089.8Z'
      transform='translate(-16 -16)'
    />
    <path
      id='Path_2527'
      data-name='Path 2527'
      d='M291.328,192H288.37a.37.37,0,0,0-.37.37v2.589a.37.37,0,0,0,.2.331l1.479.74a.37.37,0,0,0,.331,0l1.479-.74a.37.37,0,0,0,.2-.331V192.37A.37.37,0,0,0,291.328,192Zm-.37,2.73-1.109.555-1.109-.555v-1.99h2.219Z'
      transform='translate(-275.427 -183.864)'
    />
    <path id='Path_2528' data-name='Path 2528' d='M216,160h.74v.74H216Z' transform='translate(-206.755 -153.343)' />
    <path id='Path_2529' data-name='Path 2529' d='M216,240h.74v.74H216Z' transform='translate(-206.755 -229.645)' />
    <path id='Path_2530' data-name='Path 2530' d='M216,320h.74v.74H216Z' transform='translate(-206.755 -305.947)' />
    <path id='Path_2531' data-name='Path 2531' d='M216,400h.74v.74H216Z' transform='translate(-206.755 -382.249)' />
  </svg>
)
