import React, { useState, useContext, useEffect } from 'react'

import styled, { css } from 'styled-components'
import { Heading, Button } from 'rebass/styled-components'

import { t } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { ApplicationContext } from 'app/Context'
import { Jacket, Trousers, Shirt } from 'app/assets/icons'
import { ShirtPanel } from './ShirtPanel'
import { JacketPanel } from './JacketPanel'
import { TrousersPanel } from './TrouserPanel'
import { theme } from 'app/assets/theme'
import { /*isMobile,*/ isTablet } from 'react-device-detect'

interface Props {
  isShown: boolean
  save: () => Promise<void>
  hideFitPreferences: () => void
}

export const FitPreferences: React.FC<Props> = ({ isShown, save, hideFitPreferences }: Props) => {
  const {
    state: {
      shop: { fromB2B },
      user: {
        fitPreferences: { shirt, jacket, trousers, isSaved },
        sizeRec: { product_type },
      },
    },
    actions: { updateFitPreferences, setDefaultPreferences /*, updateShop */ },
  } = useContext(ApplicationContext)
  const [hideFitPref, setHideFitPref] = useState(false)
  useEffect(() => {
    if (!jacket.fit && fromB2B !== null && fromB2B !== '1' && fromB2B !== '2') {
      setDefaultPreferences()
      if (!hideFitPref) {
        setTimeout(() => {
          document.getElementById('btnSaveFitPreferences')?.click()
          setHideFitPref(true)
        }, 100)
      }
    }
  }, [jacket.fit, hideFitPref, fromB2B, setDefaultPreferences])

  const [activeTab, setActiveTab] = useState(-1)
  const changeTab = (direction: number) => () =>
    setActiveTab(state => {
      const r = state + direction
      if (r < 0 || r > 2) {
        return state
      }
      return r
    })

  return (
    <FitPreferencesContainer isShown={isShown}>
      <Title>{t(i18nKeys.fitPreferences.title)}</Title>
      <Tabs>
        <TabLabels>
          <Label
            isActive={
              activeTab === 0 ||
              (activeTab === -1 && (product_type === 'jacket' || product_type === null || product_type === ''))
            }
            onClick={() => setActiveTab(0)}
          >
            <Jacket /> {t(i18nKeys.fitPreferences.jacket)}
          </Label>
          <Label
            isActive={
              activeTab === 1 || (activeTab === -1 && (product_type === 'trousers' || product_type === 'jeans'))
            }
            onClick={() => setActiveTab(1)}
          >
            <Trousers /> {t(i18nKeys.fitPreferences.trousers)}
          </Label>
          <Label
            isActive={activeTab === 2 || (activeTab === -1 && product_type === 'shirt')}
            onClick={() => setActiveTab(2)}
          >
            <Shirt /> {t(i18nKeys.fitPreferences.shirt)}
          </Label>
        </TabLabels>
        <FitPanel fromB2B={fromB2B === '1'}>
          {(activeTab === 0 ||
            (activeTab === -1 && (product_type === 'jacket' || product_type === null || product_type === ''))) && (
            <JacketPanel preferences={jacket} onChange={jacket => updateFitPreferences({ jacket })} />
          )}
          {(activeTab === 1 || (activeTab === -1 && (product_type === 'trousers' || product_type === 'jeans'))) && (
            <TrousersPanel preferences={trousers} onChange={trousers => updateFitPreferences({ trousers })} />
          )}
          {(activeTab === 2 || (activeTab === -1 && product_type === 'shirt')) && (
            <ShirtPanel preferences={shirt} onChange={shirt => updateFitPreferences({ shirt })} />
          )}
        </FitPanel>
        <TabLabelsTablet>
          <Label
            isActive={
              activeTab === 0 ||
              (activeTab === -1 && (product_type === 'jacket' || product_type === null || product_type === ''))
            }
            onClick={() => setActiveTab(0)}
          >
            <Jacket /> {t(i18nKeys.fitPreferences.jacket)}
          </Label>
          <Label
            isActive={
              activeTab === 1 || (activeTab === -1 && (product_type === 'trousers' || product_type === 'jeans'))
            }
            onClick={() => setActiveTab(1)}
          >
            <Trousers /> {t(i18nKeys.fitPreferences.trousers)}
          </Label>
          <Label
            isActive={activeTab === 2 || (activeTab === -1 && product_type === 'shirt')}
            onClick={() => setActiveTab(2)}
          >
            <Shirt /> {t(i18nKeys.fitPreferences.shirt)}
          </Label>
        </TabLabelsTablet>
      </Tabs>
      <MobileActions>
        {/* {(activeTab === 0 ||
            (activeTab === -1 && (product_type === 'jacket' || product_type == null || product_type === ''))) && 
            ( */}
        <Button variant='primary' mr={2} onClick={hideFitPreferences}>
          {t(i18nKeys.measurementProfile.summary).toUpperCase()}{' '}
        </Button>
        {/* )}
        {!(activeTab === 0 ||
            (activeTab === -1 && (product_type === 'jacket' || product_type == null || product_type === ''))) && 
            (<Button variant='primary' mr={2} onClick={hideFitPreferences}>{t(i18nKeys.back)}</Button>)} */}
        <Button
          id='btnSaveFitPreferences'
          variant='secondary'
          disabled={isSaved}
          ml={2}
          onClick={async () => {
            await save()
            if (hideFitPref) {
              hideFitPreferences()
            }
          }}
        >
          {t(i18nKeys.measurementProfile.saveProfile)}
        </Button>
      </MobileActions>
    </FitPreferencesContainer>
  )
}

const FitPreferencesContainer = styled.div<{ isShown: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: ${({ isShown }) => (isShown ? 'flex' : 'none')};
    max-width: 100%;
  }
  ${({ isShown }) => (isTablet ? 'display: ' + (isShown ? 'flex' : 'none') + '; max-width: 100%;' : '')}
`

const Title = styled(Heading)`
  text-align: center;
  margin-bottom: 45px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    margin-top: -10px;
    margin-bottom: 10px;
  }
  @media screen and (max-height: 769px) {
    margin-bottom: 5px;
  }
  ${isTablet ? 'margin-bottom: 30px;' : ''}
`

const Tabs = styled.div`
  display: flex;
  margin-top: 10px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    margin-top: 5px;
    flex-direction: column;
  }
  ${isTablet ? 'flex-direction: column;' : ''}
`

const TabLabels = styled.div`
  display: ${isTablet ? 'none' : 'flex'};
  flex-direction: column;
  margin: 16px;
  min-width: 60px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    flex-direction: row;
    margin: 0px;
    justify-content: space-around;
  }
`
//${isTablet ? 'flex-direction: row; margin: 0px; justify-content: space-around;' : ''}
const TabLabelsTablet = styled.div`
  display: ${isTablet ? 'flex' : 'none'};
  flex-direction: column;
  margin: 16px;
  min-width: 60px;
  flex-direction: row;
  justify-content: space-around;
`

const Label = styled.div<{ isActive: boolean }>(
  ({ isActive }) => css`
    display: flex;
    flex-direction: column;
    color: ${isActive ? theme.colors.secondary : theme.colors.primary};
    font-size: ${isActive ? theme.fontSizes[1] : theme.fontSizes[0]}px;
    font-weight: ${isActive ? '600' : '400'};
    text-decoration: ${isActive ? 'underline' : 'none'};
    align-items: center;
    cursor: pointer;
    margin-bottom: 40px;
    @media screen and (max-width: ${theme.breakpoints[0]}) {
      font-weight: ${isActive ? '800' : '600'};
      margin-bottom: 0px;
      & svg {
        display: none;
      }
    }
    ${isTablet ? 'font-weight: ' + (isActive ? '800' : '600') + '; margin-bottom: 10px;' : ''}
  `
)
// & svg { display: none;}
const FitPanel = styled.div<{ fromB2B: boolean }>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.colors.secondary};
  border-radius: 10px;
  padding: 20px 40px;
  flex: 1 0 auto;
  min-height: ${({ fromB2B }) => (fromB2B ? '450' : '500')}px;
  @media screen and (max-height: 769px) {
    padding: 20px 0px 0px 30px;
  }
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    border: none;
    padding: 5px 0px 0px;
    margin-top: 0px;
    min-height: 300px;
    min-height: calc(100vh - 250px);
  }
`
const MobileActions = styled.div`
  display: none;
  justify-content: center;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: flex;
  }
  ${isTablet ? 'display: flex; margin-top: 50px;' : ''}
`
