/* eslint-disable */
declare global {
  interface Document {
    documentMode?: number
  }
}
export const myIsSafari = (isSafari: boolean, isMobileSafari: boolean) => {
  const is_Edge = navigator.userAgent.indexOf('Edge') > -1
  const is_explorer =
    (typeof document !== 'undefined' && !!document.documentMode && is_Edge === false) ||
    navigator.userAgent.indexOf('MSIE') !== -1 ||
    navigator.appVersion.indexOf('Trident/') > 0
  const is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  return (isSafari || isMobileSafari || is_safari) && is_explorer === false && is_Edge === false
}
