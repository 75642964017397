import React from 'react'

export const Gallery = (props: any) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='47.939' height='25.384' viewBox='0 0 47.939 25.384' {...props}>
    <g id='Group_352' data-name='Group 352' transform='translate(-256.561 -486.99)'>
      <path
        id='Path_61'
        data-name='Path 61'
        d='M-664.648,432.408a4.556,4.556,0,0,1-4.551-4.55,4.556,4.556,0,0,1,4.551-4.551,4.556,4.556,0,0,1,4.551,4.551A4.556,4.556,0,0,1-664.648,432.408Z'
        transform='translate(931.932 63.683)'
        fill='#f80'
      />
      <path
        id='Path_62'
        data-name='Path 62'
        d='M-650.2,442.761l1.943,1.769h-39.791L-676.8,437l7.61,3.575,6.754-5.23s6.584,4.112,8.962,5.23A10.059,10.059,0,0,1-650.2,442.761Z'
        transform='translate(944.611 67.845)'
        fill='#b0b0b0'
      />
      <path
        id='Path_63'
        data-name='Path 63'
        d='M-637.373,444.53h-15.192l-2.611-3.117,6.946-9.225Z'
        transform='translate(941.872 67.845)'
        fill='#b0b0b0'
      />
    </g>
  </svg>
)
