import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Heading, Text, Button } from 'rebass/styled-components'

import { t } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { UserState } from 'app/domain/user/contracts'
import { ShopState } from 'app/domain/shop/contracts'
import { isTablet } from 'react-device-detect'
import { theme } from 'app/assets/theme'

interface Props {
  shop: ShopState
  user: UserState
  isShown: boolean
  save: () => void
  clearData: () => void
  showFitPreferences?: () => void
}

export const MeasurementProfile: React.FC<Props> = ({ shop, user, isShown, save, clearData, showFitPreferences }) => {
  const [wHeight, setWHeight] = useState(window.innerHeight)
  const {
    name,
    surname,
    height,
    weight,
    age,
    thigh,
    calf,
    neck,
    chest,
    waist,
    basin,
    preferences: { measurementSystem },
    fitPreferences: { trousers, jacket, shirt, isSaved },
  } = user
  useEffect(() => {
    // var element = document.getElementById('closeW')
    // if (element != null){
    //   element.style.visibility = isSaved ? 'visible': 'hidden'
    // }
    // console.log(aitId)

    const resize = () => setWHeight(window.innerHeight)

    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  return (
    <Container isShown={isShown} height={wHeight} fromB2B={shop.fromB2B === '1'}>
      <Title fromB2B={shop.fromB2B === '1'}>{t(i18nKeys.measurementProfile.title)}</Title>
      {shop.fromB2B !== '1' ? <Subtitle>{t(i18nKeys.measurementProfile.subtitle)}</Subtitle> : null}
      <Row>
        <Text variant='profileLabel'>{t(i18nKeys.measurementProfile.profileId)}&nbsp;:&nbsp;</Text>
        <Text variant='profileValue'>{shop.aitId}</Text>
      </Row>
      {!shop.shopId?.startsWith('SO') ? (
        <>
          <Row>
            <Text variant='profileLabel'>{t(i18nKeys.measurementProfile.name)}&nbsp;:&nbsp;</Text>
            <Text variant='profileValue'>{name}</Text>
          </Row>
          <Row>
            <Text variant='profileLabel'>{t(i18nKeys.measurementProfile.surname)}&nbsp;:&nbsp;</Text>
            <Text variant='profileValue'>{surname}</Text>
          </Row>
        </>
      ) : null}
      <Row>
        <Text variant='profileLabel'>{t(i18nKeys.measurementProfile.weight)}&nbsp;:&nbsp;</Text>
        <Text variant='profileValue'>
          {weight} {weight && (measurementSystem === 'metric' ? 'kg' : 'lbs')}
        </Text>
      </Row>
      <Row>
        <Text variant='profileLabel'>{t(i18nKeys.measurementProfile.height)}&nbsp;:&nbsp;</Text>
        <Text variant='profileValue'>
          {height} {height && (measurementSystem === 'metric' ? 'cm' : 'ft')}
        </Text>
      </Row>
      <Row>
        <Text variant='profileLabel'>{t(i18nKeys.measurementProfile.age)}&nbsp;:&nbsp;</Text>
        <Text variant='profileValue'>
          {age} {age && t(i18nKeys.measurementProfile.years)}
        </Text>
      </Row>
      {neck && chest && waist && basin ? ( //shop.moreMeasurements === '1' || ()
        <>
          <Row>
            <Text variant='profileLabel'>{t(i18nKeys.measurementProfile.neck)}&nbsp;:&nbsp;</Text>
            <Text variant='profileValue'>
              {neck} {neck && (measurementSystem === 'metric' ? 'cm' : 'ft')}
            </Text>
          </Row>
          <Row>
            <Text variant='profileLabel'>{t(i18nKeys.measurementProfile.chest)}&nbsp;:&nbsp;</Text>
            <Text variant='profileValue'>
              {chest} {chest && (measurementSystem === 'metric' ? 'cm' : 'ft')}
            </Text>
          </Row>
          <Row>
            <Text variant='profileLabel'>{t(i18nKeys.measurementProfile.waist)}&nbsp;:&nbsp;</Text>
            <Text variant='profileValue'>
              {waist} {waist && (measurementSystem === 'metric' ? 'cm' : 'ft')}
            </Text>
          </Row>
          <Row>
            <Text variant='profileLabel'>{t(i18nKeys.measurementProfile.basin)}&nbsp;:&nbsp;</Text>
            <Text variant='profileValue'>
              {basin} {basin && (measurementSystem === 'metric' ? 'cm' : 'ft')}
            </Text>
          </Row>
        </>
      ) : null}
      {thigh && calf ? ( //shop.jeansOption === '1' || ()
        <>
          <Row>
            <Text variant='profileLabel'>{t(i18nKeys.measurementProfile.thigh)}&nbsp;:&nbsp;</Text>
            <Text variant='profileValue'>
              {thigh} {thigh && (measurementSystem === 'metric' ? 'cm' : 'ft')}
            </Text>
          </Row>
          <Row>
            <Text variant='profileLabel'>{t(i18nKeys.measurementProfile.calf)}&nbsp;:&nbsp;</Text>
            <Text variant='profileValue'>
              {calf} {calf && (measurementSystem === 'metric' ? 'cm' : 'ft')}
            </Text>
          </Row>
        </>
      ) : null}
      <Row>
        <Text variant='profileLabel'>{t(i18nKeys.measurementProfile.jacketFit)}&nbsp;:&nbsp;</Text>
        <Text variant='profileValue'>{jacket.fit && t(i18nKeys.fitPreferences[jacket.fit])}</Text>
      </Row>
      <Row>
        <Text variant='profileLabel'>{t(i18nKeys.measurementProfile.trousersFit)}&nbsp;:&nbsp;</Text>
        <Text variant='profileValue'>{trousers.fit && t(i18nKeys.fitPreferences[trousers.fit])}</Text>
      </Row>
      <Row>
        <Text variant='profileLabel'>{t(i18nKeys.measurementProfile.shirtFit)}&nbsp;:&nbsp;</Text>
        <Text variant='profileValue'>{shirt.fit && t(i18nKeys.fitPreferences[shirt.fit])}</Text>
      </Row>
      <DesktopActions fromB2B={shop.fromB2B === '1'}>
        <Button variant='secondary' disabled={isSaved} mb={4} onClick={save}>
          {t(i18nKeys.measurementProfile.saveProfile)}
        </Button>
        {shop.fromB2B !== '1' ? (
          <Button variant='secondary' onClick={clearData}>
            {t(i18nKeys.measurementProfile.clearData)}
          </Button>
        ) : null}
      </DesktopActions>
      <MobileActions>
        <MobileButton variant='secondary' mr={2} onClick={clearData}>
          {t(i18nKeys.measurementProfile.clearData)}
        </MobileButton>
        <MobileButton variant='secondary' ml={2} onClick={showFitPreferences}>
          {t(i18nKeys.fitPreferences.title)}
        </MobileButton>
      </MobileActions>
    </Container>
  )
}

const Container = styled.div<{ isShown: boolean; height: number; fromB2B: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 30%;
  ${({ fromB2B }) => (fromB2B ? 'width: 300px;' : '')}
  max-height: calc(${window.innerHeight}px - 50px);
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: ${({ isShown }) => (isShown ? 'flex' : 'none')};
  }
  ${({ isShown }) => (isTablet ? ' display: ' + (isShown ? 'flex' : 'none') + ';width: 100%;' : '')}
`

const Title = styled(Heading)<{ fromB2B: boolean }>`
  text-align: center;
  @media screen and (max-height: 670px) {
    margin-top: ${({ fromB2B }) => (fromB2B ? '0' : '-10')}px;
    margin-bottom: 0px;
  }
`
const Subtitle = styled(Heading)`
  text-align: center;
  margin-bottom: 0;
  font-size: 10px;
`

const Row = styled.div`
  display: flex;
  margin-top: 20px;
  @media screen and (max-height: 670px) {
    margin-top: 9px;
  }
  justify-content: space-between;
`

const DesktopActions = styled.div<{ fromB2B: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ fromB2B }) => (fromB2B ? '10' : '60')}px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: none;
  }
  ${isTablet ? ' display: none;' : ''}
`

const MobileActions = styled.div`
  display: none;
  margin-top: 10px;
  justify-content: center;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: flex;
  }
  @media screen and (max-height: 670px) {
    margin-top: 5px;
  }
  ${isTablet ? ' display: flex; ' : ''}
`
// const Center = styled.div`
//   left: ${Math.ceil(window.innerWidth / 2)}px;
// `

const MobileButton = styled(Button)`
  font-size: 12px;
`
