export const validations = {
  metric: {
    weight: {
      min: 30,
      max: 170,
    },
    height: {
      min: 120,
      max: 230,
    },
    thigh: {
      min: 0,
      max: 100,
    },
    calf: {
      min: 0,
      max: 100,
    },
    neck: {
      min: 0,
      max: 30,
    },
    chest: {
      min: 0,
      max: 180,
    },
    waist: {
      min: 0,
      max: 180,
    },
    basin: {
      min: 0,
      max: 180,
    },
    age: {
      min: 16,
      max: 100,
    },
  },
  imperial: {
    weight: {
      min: 66,
      max: 375,
    },
    height: {
      min: 55,
      max: 91,
    },
    thigh: {
      min: 0,
      max: 100,
    },
    calf: {
      min: 0,
      max: 100,
    },
    neck: {
      min: 0,
      max: 14,
    },
    chest: {
      min: 0,
      max: 100,
    },
    waist: {
      min: 0,
      max: 100,
    },
    basin: {
      min: 0,
      max: 100,
    },
    age: {
      min: 16,
      max: 100,
    },
  },
}
