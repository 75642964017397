import React from 'react'
import styled, { css } from 'styled-components'
import { theme } from 'app/assets/theme'

interface Props {
  label: string
  isLabelBold: boolean
  isChecked: boolean
  onClick: () => void
}

export const SpecialCheckbox: React.FC<Props> = ({ label, onClick, isLabelBold, isChecked, ...props }) => {
  return (
    <Container onClick={onClick}>
      <Label isMeBold={isLabelBold}>
        <Checkbox isChecked={isChecked} /> {label}
      </Label>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 3px;
`

const Label = styled.div<{ isMeBold: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 16px;
  font-size: 10px;
  @media screen and (max-height: 670px) {
    margin-top: 5px;
  }
  font-weight: ${({ isMeBold }) => (isMeBold ? 'bold' : 'regular')};
  color: ${theme.colors.primary};
`

const Checkbox = styled.div<{ isChecked: boolean }>(
  ({ isChecked }) => css`
    width: 10px;
    height: 10px;
    border: 1px solid ${theme.colors.secondary};
    position: relative;
    margin-right: 8px;
    &:after {
      display: ${isChecked ? 'block' : 'none'};
      content: '';
      top: 2px;
      left: 2px;
      width: 6px;
      height: 6px;
      position: absolute;
      background-color: ${theme.colors.secondary};
    }
  `
)
