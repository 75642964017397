import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Text } from 'rebass/styled-components'
import { RouteComponentProps } from '@reach/router'
//import { PubSub } from '@aws-amplify/pubsub'

import { /*getQueryStringValue,*/ t } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { theme } from 'app/assets/theme'
import { Image } from 'rebass/styled-components'
import { images } from 'app/assets/continue_on_desktop'

export const ContinueOnDesktop: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    var element = document.getElementById('Top')
    if (element != null) {
      var parent = element.parentElement
      if (parent != null) {
        parent.style.height = '99%'
      }
    }
    window.parent && window.parent.postMessage({ event: 'ait-mtm-complete-app-iframe' }, '*')
    // PubSub.publish(getQueryStringValue('topic'), {
    //   action: 'mobile-flow-complete',
    // }).catch(error => console.error(error))
    // PubSub.publish(getQueryStringValue('topic') as string, {
    //   action: 'mobile-flow-complete',
    //   payload: {
    //     aitId: getQueryStringValue('aid') as string,
    //     jeansOption: getQueryStringValue('jns'),
    //     thigh: getQueryStringValue('ut'),
    //     calf: getQueryStringValue('uc'),
    //   },
    // })
  }, [])
  return (
    <PageContainer id='Top'>
      <ComponentsContainer>
        <InstructionImage src={images['contiune_on_desktop']} />
        <TitleText>{t(i18nKeys.desktopToMobile.continueOnDesktopText)}</TitleText>
      </ComponentsContainer>
    </PageContainer>
  )
}
const InstructionImage = styled(Image)`
  max-height: calc(${window.innerHeight}px - 50px);
  max-width: 100%;
  width: auto;
  height: auto;
  top: 0;
  bottom: 0;
  margin: auto;
`
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  text-align: center;
  align-items: center;
`
const ComponentsContainer = styled.div`
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  width: 50%;
  text-align: center;
  align-items: center;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    width: 70%;
    padding-top: 30px;
  }
  @media screen and (max-width: 580px) {
    width: 80%;
    padding-top: 20px;
  }
  height: 60vh;
`

const TitleText = styled(Text)`
  font-weight: 800;
  line-height: 24px;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[5]}px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    font-weight: 600;
    font-size: ${theme.fontSizes[4]}px;
  }
`
