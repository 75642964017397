import { useEffect, useState } from 'react'
import { t } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { PhotoAngles } from 'app/domain/user/contracts'

export interface SensorInfo {
  sensorLR?: number
  sensorFB?: number
  isOk: boolean
}

declare global {
  interface Window {
    cameraView: any
    imageProc: any
    OneMeasureSDKParameters: any
    OneMeasureSDKLite: any
  }
}

export const useCamera = (onCapture: (image: string, angles: PhotoAngles) => void) => {
  const [sensorInfo, setSensorInfo] = useState<SensorInfo>({ isOk: false })

  useEffect(() => {
    setTimeout(() => {
      try {
        window.cameraView.setVideoPosition = setVideoPosition
        window.OneMeasureSDKLite.dealWithEvents = ({ detail }: CustomEvent) => {
          console.warn('[AIT][MTM] OneMeasure SDK dispached an event:', detail?.statusCode, detail?.statusText)
          if (detail?.statusCode === 40008 && detail?.detail) {
            alert(t(i18nKeys.camera.incompatible))
          }
        }
        window.cameraView.registerSensor()
        window.cameraView.onCreate()
      } catch (error) {
        alert(t(i18nKeys.camera.error) + ': ' + t(i18nKeys.camera.incompatible))
        console.error('[AIT][MTM] An error occured trying to start video:', error)
      }

      window.cameraView.setOnSensorListener.onSensorOk = function (...args: any) {
        const angles = window.cameraView.setOnSensorListener.onSensorAngle()
        setSensorInfo({ ...angles, isOk: true })
        //window.cameraView.setOnSensorListener.startVibration(2)
      }

      window.cameraView.setOnSensorListener.onSensorError = function (...args: any) {
        const angles = window.cameraView.setOnSensorListener.onSensorAngle()
        setSensorInfo({ ...angles, isOk: false })
        //window.cameraView.setOnSensorListener.endVibration()
      }

      return () => {
        try {
          window.cameraView.unregisterSensor()
          stopCameras()
          //window.cameraView.onStop()
        } catch {}
      }
    }, 100)
  }, [])

  const captureImage = () => {
    try {
      window.cameraView.captureImage((jpeg: any) => {
        const { sensorFB, sensorLR } = sensorInfo
        clipImage(jpeg, adjusted => onCapture(adjusted.split(',')[1], { FB: sensorFB || 0, LR: sensorLR || 0 }))
      })
      //window.cameraView.onStop()
      stopCameras()
      window.cameraView.unregisterSensor()
    } catch {
      // alert(t(i18nKeys.camera.captureError))
    }
  }

  return { sensorInfo, captureImage }
}

function stopCameras() {
  // var videoElement = window.cameraView
  var videoElement = document.getElementById('aitCameraVideo') as HTMLVideoElement

  if (videoElement && videoElement.srcObject) {
    ;(videoElement.srcObject as MediaStream).getTracks().forEach((track: any) => {
      if (track.readyState === 'live') {
        track.stop()
      }
    })
  }
}

function setVideoPosition() {
  var aitCameraVideo = document.getElementById('aitCameraVideo') as HTMLVideoElement
  var aitVideoContainer = document.getElementById('aitCameraView')
  var vH = aitCameraVideo!.videoHeight
  var vW = aitCameraVideo!.videoWidth
  var cH = aitVideoContainer!.clientHeight
  var cW = aitVideoContainer!.clientWidth
  var videoScale
  if (vW * cH - vH * cW < 0) {
    videoScale = vW / cW
    var upMoveDistance = vH / videoScale - cH
    aitCameraVideo.setAttribute(
      'style',
      `
    width: ${cW}px;
    margin-top: ${-upMoveDistance}px;
    `
    )
  } else if (vW * cH - vH * cW > 0) {
    videoScale = vH / cH
    var leftMoceDistance = (cW - vW / videoScale) / 2
    aitCameraVideo.setAttribute(
      'style',
      `
    height: ${cH}px;
    margin-left: ${leftMoceDistance}px;
    `
    )
  }
}

export function requestOrientationPermit() {
  if (
    typeof (DeviceOrientationEvent as any) !== 'undefined' &&
    typeof (DeviceOrientationEvent as any).requestPermission === 'function'
  ) {
    ;(DeviceOrientationEvent as any).requestPermission()
  } else {
    alert(t(i18nKeys.prep.motion.notSupported))
  }
}

function clipImage(jpeg: string, callback: (s: string) => void) {
  var scale: number
  var clipVideoX: number, clipVideoY: number, clipVideoW: number, clipVideoH: number
  var aitCameraVideo = document.getElementById('aitCameraVideo') as HTMLVideoElement
  var aitVideoContainer = document.getElementById('aitCameraView')
  var vH = aitCameraVideo.videoHeight * 2
  var vW = aitCameraVideo.videoWidth * 2

  var cH = aitVideoContainer!.clientHeight
  var cW = aitVideoContainer!.clientWidth

  if (vW * cH - vH * cW < 0) {
    scale = vW / cW
    var upDist = vH / scale - cH
    clipVideoX = 0
    clipVideoY = Math.floor(upDist * scale)
    clipVideoW = Math.floor(cW * scale)
    clipVideoH = Math.floor(cH * scale)
  } else if (vW * cH - vH * cW > 0) {
    scale = vH / cH
    var leftDist = (cW - vW / scale) / 2
    clipVideoX = Math.floor(-leftDist * scale)
    clipVideoY = 0
    clipVideoW = Math.floor(cW * scale)
    clipVideoH = Math.floor(cH * scale)
  } else {
    clipVideoX = 0
    clipVideoY = 0
    clipVideoW = Math.floor(vW)
    clipVideoH = Math.floor(vH)
  }

  var img = new Image()
  img.onload = function () {
    var imgCanvas = document.createElement('canvas')
    imgCanvas.width = cW * 4
    imgCanvas.height = cH * 4
    imgCanvas
      .getContext('2d')
      ?.drawImage(img, clipVideoX, clipVideoY, clipVideoW, clipVideoH, 0, 0, imgCanvas.width, imgCanvas.height)

    callback(imgCanvas.toDataURL('image/jpeg'))
  }
  img.src = jpeg
}
