import React from 'react'
import styled from 'styled-components'

import { Circles, Inspect } from 'app/assets/icons'
import { theme } from 'app/assets/theme'

interface Props {}

export const Loading: React.FC<Props> = (props: Props) => {
  return (
    <Container {...props}>
      <LoadingImage />
      <InspectIcon />
    </Container>
  )
}

const Container = styled.div`
  width: 110px;
  margin-top: 90px;
  position: relative;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    margin-top: 60px;
    font-size: ${theme.fontSizes[4]}px;
  }
`

const LoadingImage = styled(Circles)`
  animation: rotation 2s infinite linear;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    8.3% {
      transform: rotate(30deg);
    }
    16.6% {
      transform: rotate(60deg);
    }
    25% {
      transform: rotate(90deg);
    }
    33.3% {
      transform: rotate(120deg);
    }
    41.6% {
      transform: rotate(150deg);
    }
    50% {
      transform: rotate(180deg);
    }
    58.3% {
      transform: rotate(210deg);
    }
    66.6% {
      transform: rotate(240deg);
    }
    75% {
      transform: rotate(270deg);
    }
    83.3% {
      transform: rotate(300deg);
    }
    91.6% {
      transform: rotate(330deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const InspectIcon = styled(Inspect)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
