import React, { useEffect, useContext } from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from '@reach/router'
import { Text } from 'rebass/styled-components'

import { t } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { Loading } from 'app/components/Loading'
import { useMeasurementApiCall } from 'app/hooks/useMeasurementApiCall'
import { theme } from 'app/assets/theme'
import { isTablet } from 'react-device-detect'
import { ApplicationContext } from 'app/Context'

export const AnalyzingScreen: React.FC<RouteComponentProps> = () => {
  const {
    state: {
      shop: { shopId },
    },
  } = useContext(ApplicationContext)

  useMeasurementApiCall()

  useEffect(() => {
    var element = document.getElementById('Top')
    if (element != null) {
      var parent = element.parentElement
      if (parent != null) {
        parent.style.height = '99%'
      }
    }
  }, [])
  return (
    <PageContainer id='Top'>
      <ComponentsContainer>
        <TitleText>{t(i18nKeys.analyzing.title)}</TitleText>
        <Loading />

        {!shopId?.startsWith('SO') ? <SubtitleText>{t(i18nKeys.analyzing.subtitle)}</SubtitleText> : null}

        <TipsTitleText>{t(i18nKeys.analyzing.quickTips)}</TipsTitleText>

        <TipsList>
          <Tip>{t(i18nKeys.analyzing.tip1)}</Tip>
          <Tip>{t(i18nKeys.analyzing.tip2)}</Tip>
        </TipsList>
      </ComponentsContainer>
    </PageContainer>
  )
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  text-align: center;
  align-items: center;
`

const ComponentsContainer = styled.div`
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  width: 50%;
  text-align: center;
  align-items: center;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    width: 70%;
    padding-top: 30px;
  }
  ${isTablet ? ' width: 70%; padding-top: 30px;' : ''}
  @media screen and (max-width: 580px) {
    width: 80%;
    padding-top: 20px;
  }
`

const TitleText = styled(Text)`
  font-weight: 800;
  line-height: 24px;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[5]}px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    font-weight: 600;
    font-size: ${theme.fontSizes[4]}px;
  }
  ${isTablet ? ' font-weight: 600; font-size: ' + theme.fontSizes[4] + 'px;' : ''}
`

const SubtitleText = styled(Text)`
  font-weight: 400;
  margin-top: 70px;
  line-height: 19px;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[2]}px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    font-weight: 400;
    margin-top: 50px;
    font-size: ${theme.fontSizes[1]}px;
  }
  ${isTablet ? ' font-weight: 400; margin-top: 50px; font-size: ' + theme.fontSizes[1] + 'px;' : ''}
`

const TipsTitleText = styled(Text)`
  font-weight: 600;
  line-height: 22px;
  margin-top: 100px;
  text-transform: uppercase;
  color: ${theme.colors.secondary};
  font-size: ${theme.fontSizes[4]}px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    font-weight: 600;
    line-height: 22px;
    margin-top: 50px;
    text-transform: uppercase;
    font-size: ${theme.fontSizes[3]}px;
  }
  ${isTablet
    ? ' font-weight: 600; line-height: 22px; margin-top: 50px; text-transform: uppercase; font-size: ' +
      theme.fontSizes[3] +
      'px;'
    : ''}
`

const TipsList = styled.ol`
  list-style: none;
  margin-top: 35px;
  text-align: left;
  counter-reset: tip-counter;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    margin-top: 30px;
  }
  ${isTablet ? ' margin-top: 30px;' : ''}
`

const Tip = styled.li`
  counter-increment: tip-counter;
  font-weight: 400;
  margin-bottom: 30px;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[1]}px;
  ::before {
    content: counter(tip-counter) '. ';
    color: red;
  }
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    margin-bottom: 20px;
    font-size: ${theme.fontSizes[1]}px;
  }
  ${isTablet ? ' margin-bottom: 20px; font-size: ' + theme.fontSizes[1] + 'px;' : ''}
`
