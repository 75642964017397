/* eslint-disable */
export const myIsIOS = (isIOS: boolean, isIOS13: boolean) => {
  return (
    isIOS ||
    isIOS13 ||
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}
