import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import { Image } from 'rebass/styled-components'
import { images } from 'app/assets/mobile_tutorial'
import { ApplicationContext } from 'app/Context'

interface Props {
  image_index: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10'
}

export const PhotoTutorial: React.FC<Props> = ({ image_index }: Props) => {
  const {
    state: {
      shop: { language },
    },
  } = useContext(ApplicationContext)

  const iKey =
    image_index === '1'
      ? language === 'fr'
        ? 'tutorial_1_fr'
        : language === 'ro'
        ? 'tutorial_1_ro'
        : 'tutorial_1'
      : image_index === '2'
      ? language === 'fr'
        ? 'tutorial_2_fr'
        : language === 'ro'
        ? 'tutorial_2_ro'
        : 'tutorial_2'
      : image_index === '3'
      ? language === 'fr'
        ? 'tutorial_3_fr'
        : language === 'ro'
        ? 'tutorial_3_ro'
        : 'tutorial_3'
      : image_index === '4'
      ? language === 'fr'
        ? 'tutorial_4_fr'
        : language === 'ro'
        ? 'tutorial_4_ro'
        : 'tutorial_4'
      : image_index === '5'
      ? language === 'fr'
        ? 'tutorial_5_fr'
        : language === 'ro'
        ? 'tutorial_5_ro'
        : 'tutorial_5'
      : image_index === '6'
      ? language === 'fr'
        ? 'tutorial_6_fr'
        : language === 'ro'
        ? 'tutorial_6_ro'
        : 'tutorial_6'
      : image_index === '7'
      ? language === 'fr'
        ? 'tutorial_7_fr'
        : language === 'ro'
        ? 'tutorial_7_ro'
        : 'tutorial_7'
      : image_index === '8'
      ? language === 'fr'
        ? 'tutorial_8_fr'
        : language === 'ro'
        ? 'tutorial_7_ro'
        : 'tutorial_7'
      : image_index === '9'
      ? language === 'fr'
        ? 'tutorial_9_fr'
        : language === 'ro'
        ? 'tutorial_7_ro'
        : 'tutorial_7'
      : language === 'fr'
      ? 'tutorial_10_fr'
      : language === 'ro'
      ? 'tutorial_7_ro'
      : 'tutorial_7'

  return (
    <Container>
      <InstructionImage src={images[iKey]} />
    </Container>
  )
}

// const PhotoContent = styled.div`
//   min-height: calc(${window.innerHeight}px - 50px);
// `

const InstructionImage = styled(Image)`
  max-height: calc(${window.innerHeight}px - 50px);
  max-width: 100%;
  width: auto;
  height: auto;
  top: 0;
  bottom: 0;
  margin: auto;
`

const Container = styled.div`
  min-height: calc(${window.innerHeight}px - 50px);
  overflow: hidden;
  flex-direction: column;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 10px;
`
