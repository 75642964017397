import { AppAction } from 'app/domain/contracts'

export const updatePubSubTopicNameAction = (dispatch: React.Dispatch<AppAction>) => (topicName: string) => {
  dispatch({
    entity: 'app',
    name: 'updatePubSubTopicName',
    payload: topicName,
  })
}

export const clearStateActionName = (dispatch: React.Dispatch<AppAction>) => () => {
  dispatch({
    entity: 'app',
    name: 'clearStateAction',
  })
}
