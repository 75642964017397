import React, { useState, useEffect } from 'react'
//import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { RouteComponentProps, navigate } from '@reach/router'
import { Button, Text } from 'rebass/styled-components'

import { t } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { BackChevron, ForwardChevron } from 'app/assets/icons'
import { TextInstructions } from './components/TextInstructions'
import { PhotoInstruction } from './components/PhotoInstruction'
import { PhotoTutorial } from '../mobile_tutorial/components/PhotoTutorial'
import { theme } from 'app/assets/theme'
import { isTablet } from 'react-device-detect'

export const InfoScreen: React.FC<RouteComponentProps> = () => {
  const [mobileScreenIndex, setMobileScreenIndex] = useState(0)
  const changeSlide = (direction: number) => () =>
    setMobileScreenIndex(state => {
      const r = state + direction
      if (r < 0 || r > 7) {
        return state
      }
      return r
    })
  useEffect(() => {
    var element = document.getElementById('Top')
    if (element != null) {
      var parent = element.parentElement
      if (parent != null) {
        parent.style.height = '99%'
      }
    }
  }, [])
  return (
    <>
      <Desktop>
        <InstructionsContainer>
          <TextInstructions />
          <PhotoInstruction direction={'front'} />
          <PhotoInstruction direction={'side'} />
        </InstructionsContainer>
        <Controls>
          <Button onClick={() => navigate(-1)}>{t(i18nKeys.info.back)}</Button>
          <FollowInstructionsText>{t(i18nKeys.info.followInstructions)}</FollowInstructionsText>
        </Controls>
      </Desktop>

      <Mobile>
        {/* <MobileControlsUp></MobileControlsUp> */}

        {mobileScreenIndex === 0 && <PhotoTutorial image_index={'1'} />}
        {mobileScreenIndex === 1 && <PhotoTutorial image_index={'2'} />}
        {mobileScreenIndex === 2 && <PhotoTutorial image_index={'3'} />}
        {mobileScreenIndex === 3 && <PhotoTutorial image_index={'4'} />}
        {mobileScreenIndex === 4 && <PhotoTutorial image_index={'5'} />}
        {mobileScreenIndex === 5 && <PhotoTutorial image_index={'6'} />}
        {mobileScreenIndex === 6 && <PhotoTutorial image_index={'7'} />}
        {mobileScreenIndex === 7 && <PhotoTutorial image_index={'8'} />}
        <MobileControlsDown>
          <RowJustified>
            {mobileScreenIndex === 0 ? (
              <MyButton
                onClick={() => navigate(-1)}
                color={theme.colors.secondary}
                variant={'flat'}
                backgroundColor='transparent'
              >
                <BackChevron />
                &nbsp;{t(i18nKeys.info.takePictures)}
                {/* <RowJustified>
                  <BackChevron />
                  <TextBack locationX={-140}>{t(i18nKeys.info.takePictures)}</TextBack>
                </RowJustified> */}
              </MyButton>
            ) : (
              <MyButton
                onClick={changeSlide(-1)}
                color={theme.colors.secondary}
                variant={'flat'}
                backgroundColor='transparent'
              >
                <BackChevron />
                &nbsp;{t(i18nKeys.back)}
                {/* <RowJustified>
                    <BackChevron />
                    <TextBack>{t(i18nKeys.back)}</TextBack>
                  </RowJustified> */}
              </MyButton>
            )}
            {mobileScreenIndex === 7 ? (
              <RowEnd>
                <MyButton
                  onClick={() => navigate(-1)}
                  color={theme.colors.secondary}
                  variant={'flat'}
                  backgroundColor='transparent'
                >
                  {t(i18nKeys.info.takePictures)}&nbsp;
                  <ForwardChevron />
                  {/* <RowJustified>
                    <TextNext locationX={-140}>{t(i18nKeys.info.takePictures)}</TextNext>
                    <ForwardChevron />
                  </RowJustified> */}
                </MyButton>
              </RowEnd>
            ) : (
              <RowEnd>
                <MyButton
                  onClick={changeSlide(1)}
                  color={theme.colors.secondary}
                  variant={'flat'}
                  backgroundColor='transparent'
                >
                  {t(i18nKeys.next)}&nbsp;
                  <ForwardChevron />
                  {/* <RowJustified>
                    <TextNext locationX={-58}>{t(i18nKeys.next)}</TextNext>
                    <ForwardChevron />
                  </RowJustified> */}
                </MyButton>
              </RowEnd>
            )}
          </RowJustified>
        </MobileControlsDown>
      </Mobile>
    </>
  )
}

// const PageContainer = styled.div``

const MyButton = styled(Button)`
  outline: none !important;
  outline-offset: none !important;
`

const FollowInstructionsText = styled(Text)`
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  flex: 1 0 auto;
  text-align: center;
  margin-left: 100px;
`

const Desktop = styled.div`
  padding: 60px 16px 0 60px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: none;
  }
  ${isTablet ? ' display: none;' : ''}
`
const RowEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 35px;
`
const RowJustified = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const Mobile = styled.div`
  padding: 0px 0px 0;
  display: none;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: block;
  }
  ${isTablet ? ' display: block;' : ''}
  @media screen and (max-height: 600px) {
    padding-top: 0px;
  }
`

/*
const TextNext = styled.div`
  margin-top: -2px;
  margin-left: -58px;
  display: flex;
  color: ${({ theme: { colors } }) => colors.secondary};
`
const TextBack = styled.div`
  margin-top: -2px;
  margin-right: -58px;
  display: flex;
  color: ${({ theme: { colors } }) => colors.secondary};
`
const MobileControlsUp = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 20px;
  display: flex;
  padding: 0 16px;
`
*/
const MobileControlsDown = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 50px;
  display: flex;
  padding: 0 16px;
`

const InstructionsContainer = styled.div`
  display: flex;
  max-width: 100%;
`

const Controls = styled.div`
  display: flex;
  margin-top: 30px;
`
