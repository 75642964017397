import React from 'react'

export const Trousers = (props: any) => (
  <svg
    id='trousers'
    xmlns='http://www.w3.org/2000/svg'
    width='15.909'
    height='23.668'
    viewBox='0 0 15.909 23.668'
    fill='currentColor'
    {...props}
  >
    <path
      id='Path_2532'
      data-name='Path 2532'
      d='M15.9,5.866,15,2.038V.352A.352.352,0,0,0,14.647,0H5.3a.351.351,0,1,0,0,.7h.412V1.724H2.866V.7H3.894a.351.351,0,0,0,0-.7H1.263A.352.352,0,0,0,.911.352V2.034L.01,5.866A.345.345,0,0,0,0,5.978l1.528,17.37a.351.351,0,0,0,.35.321H6.431a.352.352,0,0,0,.35-.316L7.954,11.83,8.471,16.9a.351.351,0,1,0,.7-.071L8.3,8.326a.351.351,0,0,0-.7,0L6.114,22.965H2.2L.725,6.181A6.872,6.872,0,0,0,2.965,4.693,4.007,4.007,0,0,0,4.194,2.427H8.41V5.595H7.149a.351.351,0,1,0,0,.7H8.761a.352.352,0,0,0,.352-.352V2.427h2.6a4.006,4.006,0,0,0,1.23,2.266,6.87,6.87,0,0,0,2.239,1.488L13.708,22.965H9.8l-.483-4.744a.351.351,0,1,0-.7.071l.516,5.06a.352.352,0,0,0,.35.316H14.03a.351.351,0,0,0,.35-.321l1.528-17.37a.35.35,0,0,0-.008-.111ZM14.3,1.724h-.549V.7H14.3Zm-4.186,0V.7h2.934V1.724ZM1.614.7h.548V1.724H1.614ZM.852,5.353l.689-2.926h1.94A4.716,4.716,0,0,1,.852,5.353ZM6.415,1.724V.7H9.407V1.724Zm6.013.7h1.94l.689,2.926a4.716,4.716,0,0,1-2.629-2.926Zm0,0'
      transform='translate(0 0)'
    />
    <path
      id='Path_2533'
      data-name='Path 2533'
      d='M78.734,117h0a.352.352,0,0,0-.349.354l.127,16.308a.352.352,0,0,0,.352.349h0a.352.352,0,0,0,.349-.354l-.127-16.307A.352.352,0,0,0,78.734,117Zm0,0'
      transform='translate(-74.759 -111.592)'
    />
    <path
      id='Path_2534'
      data-name='Path 2534'
      d='M248.156,134.01a.352.352,0,0,0,.352-.349l.127-16.308a.352.352,0,0,0-.349-.354h0a.351.351,0,0,0-.351.349l-.128,16.307a.352.352,0,0,0,.349.354Zm0,0'
      transform='translate(-236.349 -111.592)'
    />
    <path
      id='Path_2535'
      data-name='Path 2535'
      d='M183.432,19.34a.343.343,0,0,0,.066-.02.352.352,0,0,0,.06-.032.344.344,0,0,0,.053-.044.351.351,0,0,0,0-.5.3.3,0,0,0-.053-.044.329.329,0,0,0-.06-.032.344.344,0,0,0-.066-.02.339.339,0,0,0-.137,0,.36.36,0,0,0-.066.02.324.324,0,0,0-.06.032.313.313,0,0,0-.053.044.351.351,0,0,0-.076.114.354.354,0,0,0-.02.066.349.349,0,0,0-.007.068.353.353,0,0,0,.1.249.362.362,0,0,0,.053.044.346.346,0,0,0,.06.032.358.358,0,0,0,.066.02.336.336,0,0,0,.068.007A.344.344,0,0,0,183.432,19.34Zm0,0'
      transform='translate(-174.551 -17.783)'
    />
  </svg>
)
