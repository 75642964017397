import { I18n } from '@aws-amplify/core'

interface InterpolationOptions {
  [key: string]: string
}

export const t = (key: string, options?: InterpolationOptions, defaultValue?: string): string => {
  const val = I18n.get(key, defaultValue)
  if (!options) {
    return val
  }

  return Object.keys(options).reduce<string>((prev, key) => prev.replace(`{{${key}}}`, options[key]), val)
}
