import { AppReducer, AppState, AppAction } from 'app/domain/contracts'

export const shopReducer: AppReducer = (state: AppState, action: AppAction) => {
  if (action.entity !== 'shop') {
    return state
  }

  switch (action.name) {
    case 'updateShop': {
      return {
        ...state,
        shop: {
          ...state.shop,
          ...action?.payload,
        },
      }
    }
    default: {
      return state
    }
  }
}
