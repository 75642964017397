import { useEffect, useContext, useState } from 'react'

import { ApplicationContext } from 'app/Context'
import { getQueryStringValue } from '@shared-utils'
import { getDataAndUpdateStore } from 'app/services/api'

interface Params {
  aitId?: string | null
  shopId?: string | null
  language?: string | null
  shopUserId?: string | null
  shopProductLink?: string | null
  productType?: string | null
  fitId?: string | null
  source?: string | null
  jeansOption?: string | null
  moreMeasurements?: string | null
  fromB2B?: string | null
  fromPhotosOK?: string | null
}

export const useUpdateDataFromApi = () => {
  const {
    state: { shop, user },
    actions,
    actions: { updateShop },
  } = useContext(ApplicationContext)
  const [isLoading, setIsLoading] = useState(false)

  const updateDataFromApiIfNeeded = async () => {
    let params: Params = shop
    if (user.name && user.surname && user.height && user.weight && user.age) {
      return
    }

    if (!params?.shopId) {
      params = {
        aitId: getQueryStringValue('ait_id') || getQueryStringValue('aid'),
        shopId: getQueryStringValue('shop_id') || getQueryStringValue('sid'),
        language: getQueryStringValue('language') || getQueryStringValue('slang'),
        shopUserId: getQueryStringValue('shop_user_id') || getQueryStringValue('suid'),
        shopProductLink: getQueryStringValue('shop_product_link') || getQueryStringValue('spl'),
        productType: getQueryStringValue('product_type') || getQueryStringValue('spt'),
        fitId: getQueryStringValue('fit_id') || getQueryStringValue('fid'),
        jeansOption: getQueryStringValue('jns') || getQueryStringValue('jeans_option'),
        moreMeasurements: getQueryStringValue('mm') || getQueryStringValue('more_measurements'),
        fromB2B: getQueryStringValue('fromB2B'),
      }
      updateShop(params)
    }

    if (!params.shopId) {
      console.error('[AIT][MTM] Error, invalid state. No shopId found.')
      return
    }

    if (!params.aitId && !params.shopUserId) {
      return
    }

    setIsLoading(true)
    params.source = 'default'
    //console.log('updateDataFromApiIfNeeded',params)
    await getDataAndUpdateStore(params /*, shop?.jeansOption*/, actions)
    setIsLoading(false)
  }

  useEffect(() => {
    updateDataFromApiIfNeeded()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isLoading
}
