import React, { useContext } from 'react'
import styled from 'styled-components'

import { toBase64ImageSrc } from '@shared-utils'
import { ApplicationContext } from 'app/Context'
import { ComputerPhoto } from './ComputerPhoto'
// import { isMobile } from 'react-device-detect'
import { theme } from 'app/assets/theme'

interface Props {
  sidePhoto: {
    imageData: string
    errorMessage: string
  }
  frontPhoto: {
    imageData: string
    errorMessage: string
  }
}

export const ComputerPhotos: React.FC<Props> = ({ sidePhoto, frontPhoto }) => {
  const {
    state: {
      user: {
        photos: {
          base64: { front, side },
        },
      },
    },
    actions: { updateUser, resetFrontPhoto, resetSidePhoto },
  } = useContext(ApplicationContext)

  return (
    <Desktop>
      <div style={determineContainerStyle(sidePhoto.errorMessage, frontPhoto.errorMessage)}>
        <ComputerPhoto
          display={!!frontPhoto.errorMessage}
          direction='front'
          onPhotoSelected={base64Data => {
            if (base64Data) {
              updateUser({ photos: { base64: { front: base64Data } } })
            } else {
              resetFrontPhoto()
            }
          }}
          fileNamePrefix='ait-mtm-front'
          imageBase64={
            front ? toBase64ImageSrc(front) : frontPhoto.imageData ? toBase64ImageSrc(frontPhoto.imageData) : ''
          }
        />
        <ComputerPhoto
          display={!!sidePhoto.errorMessage}
          direction='side'
          onPhotoSelected={base64Data => {
            if (base64Data) {
              updateUser({ photos: { base64: { side: base64Data } } })
            } else {
              resetSidePhoto()
            }
          }}
          fileNamePrefix='ait-mtm-side'
          imageBase64={side ? toBase64ImageSrc(side) : sidePhoto.imageData ? toBase64ImageSrc(sidePhoto.imageData) : ''}
        />
      </div>
    </Desktop>
  )
}

function determineContainerStyle(sidePhotoError: string, frontPhotoError: string) {
  return {
    display: 'flex',
    height: '200px',
    marginTop: '40px',
    justifyContent: sidePhotoError && frontPhotoError ? 'space-between' : 'center',
  }
}

const Desktop = styled.div`
  width: 50%;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: none;
  }
`
