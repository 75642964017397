import React, { useContext } from 'react'
import styled from 'styled-components'
import { Button } from 'rebass/styled-components'
import { navigate, useMatch } from '@reach/router'
import { isMobile, isTablet } from 'react-device-detect'

import { t, closeWidget, getQueryStringValue } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { Cart, Previous } from 'app/assets/icons'
import { theme } from 'app/assets/theme'
import { ApplicationContext } from 'app/Context'
//import { shopReducer } from 'app/domain/shop/reducer'

interface Props {}

export const Footer: React.FC<Props> = (props: Props) => {
  const {
    state: {
      shop: { fromPhotosOK, jeansOption, moreMeasurements, fromB2B },
    },
  } = useContext(ApplicationContext)

  const match = useMatch('/:route')

  if (match?.route === 'analyzing') {
    return null
  }

  const topic = getQueryStringValue('topic')
  let isredirected = false
  if ((topic || getQueryStringValue('fromB2B') === '2') && (isMobile || isTablet)) {
    isredirected = true
  }

  const showBack =
    match &&
    match.route !== 'start' &&
    match.route !== 'prepare' &&
    (match.route !== 'measurement-profile' || (match.route === 'measurement-profile' && fromPhotosOK)) &&
    match.route !== 'continue-on-desktop' &&
    match.route !== 'mobile-tutorial' &&
    match.route !== 'mobile-tutorial-self' &&
    match.route !== 'photos-ok' &&
    match.route !== 'turn-side' &&
    (match.route !== 'info' || (match.route === 'info' && !isMobile && !isTablet))
  const showBackToStore = match && match.route !== 'continue-on-desktop' && !isredirected
  const hideFooter =
    match &&
    (match.route === 'mobile-tutorial' ||
      match.route === 'mobile-tutorial-self' ||
      match.route === 'jeans-tutorial' ||
      match.route === 'more-meas-tutorial' ||
      (match.route === 'photos-ok' && (jeansOption === '1' || moreMeasurements === '1' || moreMeasurements === '2')) ||
      match.route === 'jeans-measurements' ||
      match.route === 'more-measurements' ||
      (match.route === 'measurement-profile' && fromB2B === '1'))

  return (
    <Container hideFooter={hideFooter || false}>
      {showBackToStore ? (
        <Button id='closeW' variant='link' onClick={closeWidget}>
          <CartIcon />
          {t(i18nKeys.footer.backToStore)}
        </Button>
      ) : (
        <div></div>
      )}
      {showBack ? (
        <Button
          variant='link'
          onClick={() => {
            if (fromPhotosOK === '1') {
              navigate('/photos-ok', { replace: true })
            } else if (fromPhotosOK === '2') {
              navigate('/more-measurements', { replace: true })
            } else {
              navigate(-1)
            }
          }}
        >
          {t(i18nKeys.footer.previousStep)}
          <PreviousIcon />
        </Button>
      ) : (
        <div></div>
      )}
    </Container>
  )
}

const CartIcon = styled(Cart)`
  color: ${theme.colors.primary};
  margin-right: 14px;
`

const PreviousIcon = styled(Previous)`
  color: ${theme.colors.primary};
  margin-left: 14px;
`

const Container = styled.div<{ hideFooter: boolean }>`
  ${({ hideFooter }) => (hideFooter ? 'display: none;' : 'display: flex;')};
  background-color: #ffffff;
  justify-content: space-between;
  position: fixed;
  left: 60px;
  right: 60px;
  bottom: 0px;
  padding-bottom: 30px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    left: 20px;
    right: 20px;
    padding-bottom: 4px;
    border-top: 0.5px solid ${theme.colors.lightGray};
    padding-top: 4px;
  }
  @media screen and (max-height: 700px) {
    left: 20px;
    right: 20px;
    padding-bottom: 4px;
    border-top: 0.5px solid ${theme.colors.lightGray};
    padding-top: 4px;
  }
`
