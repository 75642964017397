import React, { useEffect, useContext } from 'react'
import styled from 'styled-components'

import { SurveyPanel } from './components/SurveyPanel'
import { RouteComponentProps, navigate } from '@reach/router'
import { theme } from 'app/assets/theme'
import { ApplicationContext } from 'app/Context'
import { Button } from 'rebass/styled-components'
import { isMobile, isTablet } from 'react-device-detect'
import { t } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'

const keys = i18nKeys.survey

export const Survey: React.FC<RouteComponentProps> = () => {
  const {
    state: {
      user: { surveyChoices },
    },
    actions: { updateSurveyChoices, setDefaultSurveyChoices },
  } = useContext(ApplicationContext)
  useEffect(() => {
    var element = document.getElementById('Top')
    if (element != null) {
      var parent = element.parentElement
      if (parent != null) {
        parent.style.height = '99%'
      }
    }
    element = document.getElementById('appCont')
    if (element != null) {
      element.style.overflowX = 'hidden'
      element.style.overflowY = 'hidden'
    }
    if (!surveyChoices || !surveyChoices.chest) {
      setDefaultSurveyChoices()
    }
  }, [surveyChoices, setDefaultSurveyChoices])
  return (
    <PageContainer id='Top' isMobile={isMobile || isTablet}>
      <SurveyPanel
        choices={surveyChoices}
        onChange={surveyChoices => {
          updateSurveyChoices(surveyChoices)
        }}
      />
      <Button
        id='btn_okToContinue'
        variant='secondary'
        mb={2}
        onClick={() => {
          navigate('/analyzing')
        }}
        style={{ display: 'none' }}
      >
        {t(keys.continueBtn)}
      </Button>
    </PageContainer>
  )
}

const PageContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100vw;
  text-align: center;
  align-items: center;
  padding-bottom: 50px;
  margin-top: 20px;
  @media screen and (min-width: ${theme.breakpoints[0]}) {
    padding-bottom: 70px;
  }
  @media screen and (max-height: 700px) {
    padding-bottom: 30px;
  }
  ${isMobile
    ? '@media screen and (orientation: landscape) { html { transform: rotate(-90deg); transform-origin: left top; width: 100vh; overflow-x: hidden; position: absolute; top: 100%; left: 0; }}'
    : ''}
`
