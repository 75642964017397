import React from 'react'

export const Inspect = (props: any) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='28.138' height='28.138' viewBox='0 0 28.138 28.138' {...props}>
    <g id='iconfinder_search_322497' transform='translate(0 0)'>
      <path
        id='Path_2312'
        data-name='Path 2312'
        d='M-546.278-166.007l-6.456-6.456a12.44,12.44,0,0,1-3.73,3.73l6.457,6.456a2.636,2.636,0,0,0,3.729,0A2.635,2.635,0,0,0-546.278-166.007Z'
        transform='translate(573.643 189.642)'
        fill='#b0b0b0'
      />
      <path
        id='Path_2313'
        data-name='Path 2313'
        d='M-554.9-181.448A10.552,10.552,0,0,0-565.448-192,10.552,10.552,0,0,0-576-181.448,10.552,10.552,0,0,0-565.448-170.9,10.552,10.552,0,0,0-554.9-181.448Zm-10.552,7.914a7.921,7.921,0,0,1-7.914-7.914,7.922,7.922,0,0,1,7.914-7.914,7.922,7.922,0,0,1,7.914,7.914A7.922,7.922,0,0,1-565.448-173.534Z'
        transform='translate(576 192)'
        fill='#b0b0b0'
      />
      <path
        id='Path_2314'
        data-name='Path 2314'
        d='M-571-180.845h1.759a4.4,4.4,0,0,1,4.4-4.4V-187A6.163,6.163,0,0,0-571-180.845Z'
        transform='translate(575.397 191.397)'
        fill='#b0b0b0'
      />
    </g>
  </svg>
)
