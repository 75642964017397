import React from 'react'
import { Link } from '@reach/router'
import styled from 'styled-components'
import { Text } from 'rebass/styled-components'

import { t } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { InfoMobile } from 'app/assets/icons'

interface Props {}

export const InfoLinkButton: React.FC<Props> = (props: Props) => {
  return (
    <LinkButton to='/info'>
      <InfoMobile />
      <Text variant='iconButtonLabel'>{t(i18nKeys.camera.info)}</Text>
    </LinkButton>
  )
}

const LinkButton = styled(Link)`
  display: block;
  text-align: center;
  text-decoration: none;
  width: 70px;
`
