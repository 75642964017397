import qrCode from './qrcode.svg'
import copyLink from './copyLink.png'
import cameraIcon from './cameraIcon.png'
import sidePerson from './sidePerson.png'
import frontPerson from './frontPerson.png'
import enableMotion from './enableMotion.png'
import sidePhotoView from './sidePhotoView.png'
import frontPhotoView from './frontPhotoView.png'

export const images = {
  qrCode,
  copyLink,
  cameraIcon,
  sidePerson,
  frontPerson,
  enableMotion,
  sidePhotoView,
  frontPhotoView,
}
