import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Text, Button } from 'rebass/styled-components'
import { isMobile, isTablet } from 'react-device-detect'
//import { makeContinueOnDesktopUrl } from 'packages/@shared-utils/funcs/makeContinueOnMobileUrl'
import { saveDataOnAitServer } from 'app/services/api'
import { RouteComponentProps, navigate } from '@reach/router'
import { t, useWindowHeight } from '@shared-utils'
import { Input } from '@rebass/forms/styled-components'
import { i18nKeys } from 'app/assets/18nKeys'
import { ApplicationContext } from 'app/Context'
import { useUpdateDataFromApi } from 'app/hooks'
import { validations } from 'app/screens/start/constants'
import { BackChevron, ForwardChevron } from 'app/assets/icons'
import { theme } from 'app/assets/theme'
import { Slider } from 'app/screens/start/components/Slider'
//import { ClassificationType } from 'typescript'
import { PubSub } from '@aws-amplify/pubsub'

const keys = i18nKeys.jeansOption

export const JeansMeasurements: React.FC<RouteComponentProps> = () => {
  useUpdateDataFromApi()

  const {
    state: {
      pubSubTopicName,
      shop,
      shop: { aitId, /*language,*/ jeansOption, shopProductLink },
      user,
      user: {
        thigh,
        calf,
        thighThreshold,
        calfThreshold,
        fitPreferences,
        preferences: { measurementSystem },
      },
    },

    actions: { updateUser },
  } = useContext(ApplicationContext)

  const [sliderShown, setSliderShown] = useState<'calf' | 'thigh' | null>(null)
  const [isFirstTime, setIsFirstTime] = useState(true)
  //const handleFocus = (event: any) => event.target.select()
  const isValid = (value: string | null, attribute: 'calf' | 'thigh', threshold: string | null) => {
    if (!value) return false
    if (value === null) return false

    try {
      var val = Number.parseFloat(value)
      return (
        value.toString().length <= 4 &&
        val >=
          (threshold === null || threshold === ''
            ? validations[measurementSystem][attribute].min
            : Number.parseFloat(threshold) - (measurementSystem === 'metric' ? 10 : 4)) &&
        val <=
          (threshold === null || threshold === ''
            ? validations[measurementSystem][attribute].max
            : Number.parseFloat(threshold) + (measurementSystem === 'metric' ? 10 : 4))
      )
    } catch {
      return false
    }
  }

  const height = useWindowHeight()
  useEffect(() => {
    var element = document.getElementById('Top')
    if (element != null) {
      var parent = element.parentElement
      if (parent != null) {
        parent.style.height = '99%'
      }
    }
    // const resize = () => {
    //   console.log('resize event')
    //   console.log(btnVisible)
    //   btnVisible === "false" ? setBtnVisible("true") : setBtnVisible("false")
    // }

    // window.addEventListener('resize', resize)
    // return () => {
    //   window.removeEventListener('resize', resize)
    // }
  }, [])

  return (
    <>
      <Container id='Top' inaltime={height}>
        <ColumnContiner>
          <ColumnContiner>
            <TitleText>{t(keys.pageTitle)}</TitleText>
            {/* <text>{btnVisible ? "visible":"invisible"}</text> */}
            <InstructionsText variant='body'>{t(keys.measPageInstructions)}</InstructionsText>
          </ColumnContiner>
          <ColumnContainerInput>
            <ColumnContiner>
              <InputText variant='body'>
                {t(keys.thigh)} {/* ( */}
                {/* {t(keys.calculated, {
                  attr:
                    thighThreshold !== null && thighThreshold !== '' ? thighThreshold + (measurementSystem === 'metric' ? ' cm' : ' ft') : 'NaN',
                })} */}
                {/* ) */}
              </InputText>
              <ProfileInput
                name='thigh'
                isValid={isValid(thigh ? thigh : '', 'thigh', thighThreshold ? thighThreshold : '') || isFirstTime}
                placeholder={t(
                  isValid(thigh ? thigh : '', 'thigh', thighThreshold ? thighThreshold : '') || isFirstTime
                    ? keys.thigh
                    : keys.thighRequired
                )}
                value={thigh || ''}
                autoComplete='off'
                type='number'
                maxLength={4}
                min={
                  thighThreshold === null || thighThreshold === ''
                    ? validations[measurementSystem]['thigh'].min
                    : Number.parseFloat(thighThreshold ? thighThreshold : '') -
                      (measurementSystem === 'metric' ? 10 : 4)
                }
                max={
                  thighThreshold === null || thighThreshold === ''
                    ? validations[measurementSystem]['thigh'].max
                    : Number.parseFloat(thighThreshold ? thighThreshold : '') +
                      (measurementSystem === 'metric' ? 10 : 4)
                }
                onChange={e => updateUser({ thigh: e.target.value })}
                onFocus={({ target }) => {
                  if (isMobile || isTablet) {
                    target.blur()
                    setSliderShown('thigh')
                  } else {
                    target.select()
                  }
                }}
                isVisible={true}
              />
            </ColumnContiner>
            <ColumnContiner>
              <InputText variant='body'>
                {t(keys.calf)} {/* ( */}
                {/* {t(keys.calculated, {
                  attr:
                    calfThreshold !== null && calfThreshold !== '' ? calfThreshold + (measurementSystem === 'metric' ? ' cm' : ' ft') : 'NaN',
                })} */}
                {/* ) */}
              </InputText>
              <ProfileInput
                name='calf'
                isValid={isValid(calf ? calf : '', 'calf', calfThreshold ? calfThreshold : '') || isFirstTime}
                placeholder={t(
                  isValid(calf ? calf : '', 'calf', calfThreshold ? calfThreshold : '') || isFirstTime
                    ? keys.calf
                    : keys.calfRequired
                )}
                value={calf || ''}
                autoComplete='off'
                type='number'
                maxLength={4}
                min={
                  calfThreshold === null || calfThreshold === ''
                    ? validations[measurementSystem]['calf'].min
                    : Number.parseFloat(calfThreshold ? calfThreshold : '') - (measurementSystem === 'metric' ? 10 : 4)
                }
                max={
                  calfThreshold === null || calfThreshold === ''
                    ? validations[measurementSystem]['calf'].max
                    : Number.parseFloat(calfThreshold ? calfThreshold : '') + (measurementSystem === 'metric' ? 10 : 4)
                }
                onChange={e => updateUser({ calf: e.target.value })}
                onFocus={({ target }) => {
                  if (isMobile || isTablet) {
                    target.blur()
                    setSliderShown('calf')
                  } else {
                    target.select()
                  }
                }}
                isVisible={true}
              />
            </ColumnContiner>
          </ColumnContainerInput>
          {sliderShown ? (
            <Slider
              heightValue={0}
              weightValue={0}
              ageValue={0}
              calfValue={+(calf ? calf : '')}
              thighValue={+(thigh ? thigh : '')}
              neckValue={0}
              chestValue={0}
              waistValue={0}
              basinValue={0}
              calfThreshold={
                calfThreshold === null || calfThreshold === ''
                  ? null
                  : Number.parseFloat(calfThreshold ? calfThreshold : '')
              }
              thighThreshold={
                thighThreshold === null || thighThreshold === ''
                  ? null
                  : Number.parseFloat(thighThreshold ? thighThreshold : '')
              }
              neckThreshold={null}
              chestThreshold={null}
              waistThreshold={null}
              basinThreshold={null}
              attribute={sliderShown}
              measurementSystem={measurementSystem}
              onCancel={() => setSliderShown(null)}
              onConfirm={value => {
                updateUser({ [sliderShown]: value })
                setSliderShown(null)
              }}
            />
          ) : null}
        </ColumnContiner>

        {sliderShown ? null : (
          <MobileControlsDown>
            <RowJustified>
              {/* {btnVisible === "true" &&  */}
              <MyButton
                onClick={() => navigate('/jeans-tutorial')}
                color={theme.colors.secondary}
                variant={'flat'}
                backgroundColor='transparent'
              >
                <BackChevron />
                &nbsp;{t(i18nKeys.back)}
              </MyButton>
              {/* } */}
              <RowEnd>
                {/* {btnVisible === "true" &&  */}
                <MyButton
                  onClick={async () => {
                    setIsFirstTime(false)
                    if (
                      isValid(thigh ? thigh : '', 'thigh', thighThreshold ? thighThreshold : '') &&
                      isValid(calf ? calf : '', 'calf', calfThreshold ? calfThreshold : '')
                    ) {
                      const response = await saveDataOnAitServer(shop, user, fitPreferences)
                      if (!response.ok) {
                        console.error(
                          `[AIT][MTM] error saving data on AIT server: ${response.status}:${response.statusText}`
                        )
                        return
                      }
                      if ((isMobile || isTablet) && pubSubTopicName) {
                        PubSub.publish(pubSubTopicName, {
                          action: 'mobile-flow-complete',
                          payload: {
                            aitId: aitId,
                            jeansOption: jeansOption,
                            shopProductLink: shopProductLink,
                          },
                        })
                        navigate('/continue-on-desktop', { replace: true })
                      } else {
                        navigate('/measurement-profile', { replace: true })
                      }
                    } else {
                      let error = 'Invalid fields:'
                      if (!isValid(thigh ? thigh : '', 'thigh', thighThreshold ? thighThreshold : ''))
                        error += ` thigh=${thigh}`
                      if (!isValid(calf ? calf : '', 'calf', calfThreshold ? calfThreshold : ''))
                        error += ` calf=${calf}`
                      console.error(error)
                    }
                  }}
                  color={theme.colors.secondary}
                  variant={'flat'}
                  backgroundColor='transparent'
                >
                  {t(i18nKeys.next)}&nbsp;
                  <ForwardChevron />
                </MyButton>
                {/* } */}
              </RowEnd>
            </RowJustified>
          </MobileControlsDown>
        )}
      </Container>
    </>
  )
}

const Container = styled.div<{ inaltime: number }>`
  display: flex;
  width: 100%;
  height: ${({ inaltime }) => inaltime}px;
  minheight: ${({ inaltime }) => inaltime}px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0px;
  @media screen and (min-width: ${theme.breakpoints[0]}) {
    padding-bottom: 0px;
  }
`
const ColumnContiner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: start;
`
const ColumnContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: start;
  margin-top: 50px;
`
//TO DO: stergere display: none; cand va fi nevoie
const ProfileInput = styled(Input)<{ isValid: boolean; isVisible: boolean }>`
  ${({ isVisible }) => (isVisible ? '' : 'display: none;')}
  text-align: center;
  height: 35px;
  margin-bottom: 10px;
  width: 80%;
  border-color: ${({ isValid }) => (isValid ? theme.colors.lightGray : theme.colors.secondary)};
  @media screen and (max-height: 670px) {
    margin-bottom: 5px;
  }
`

const MyButton = styled(Button)`
  outline: none !important;
  outline-offset: none !important;
  padding-left: 35px;
  padding-right: 35px;
`
const InputText = styled(Text)`
  padding-bottom: 4px;
  width: 80%;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  flex: 1 0 auto;
  text-align: left;
`

const InstructionsText = styled(Text)`
  padding-top: 10px;
  padding-bottom: 10px;
  width: 80%;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  flex: 1 0 auto;
  text-align: center;
`

const TitleText = styled(Text)`
  padding-top: 20px;
  width: 80%;
  font-weight: 800;
  line-height: 24px;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[5]}px;
  text-align: center;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    font-weight: 600;
    font-size: ${theme.fontSizes[4]}px;
  }
` //
const RowJustified = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
//
const RowEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`
// position: fixed;
const MobileControlsDown = styled.div`
  position: fixed;
  z-index: 2000;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding-bottom: 30px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    padding-bottom: 15px;
  }
`
