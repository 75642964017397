import { useEffect, useContext } from 'react'
import { navigate } from '@reach/router'
//import { PubSub } from '@aws-amplify/pubsub'

import { ApplicationContext } from 'app/Context'
import { sendMeasurement } from 'app/services/api'
import { updateWidgetButton } from '@shared-utils' //isMobile,
//import { isMobile } from 'react-device-detect'
//import { makeContinueOnDesktopUrl } from 'packages/@shared-utils/funcs/makeContinueOnMobileUrl'

export const useMeasurementApiCall = () => {
  const {
    state: {
      //pubSubTopicName,
      shop,
      user,
      user: {
        photos: {
          base64: { front, side },
          angles,
          //cameraProps,
        },
        surveyChoices,
      },
    },
    actions: {
      resetFrontPhoto,
      resetSidePhoto,
      updateFitPreferences,
      updateShop,
      updateUser,
      updateSizeRec,
      updateSurveyChoices,
    },
  } = useContext(ApplicationContext)
  // function timeOutFunc(ait_id: any, button_text: any, fitPreferences: any) {
  //   console.log(
  //     'publish PubSub: ' +
  //       pubSubTopicName +
  //       ', ait_id: ' +
  //       ait_id.toString() +
  //       ', button_text: ' +
  //       button_text.toString() +
  //       ', fitPreferences: ' +
  //       fitPreferences.toString()
  //   )

  //   PubSub.publish(pubSubTopicName, {
  //     action: 'mobile-flow-complete',
  //     payload: { aitId: ait_id, buttonText: button_text, fitPreferences },
  //   }).catch(error => console.error(error))

  //   setTimeout(() => timeOutFunc(ait_id, button_text, fitPreferences), 400)
  // }
  useEffect(() => {
    //console.log('Sending Measurement', shop.jeansOption)
    sendMeasurement(shop, user, { front, side, angles }, surveyChoices)
      .then(data => {
        //console.log('measurement SENT', shop.jeansOption)
        const measurement = data?.measurement

        if (measurement && measurement.measurement_complete && !measurement.err_front && !measurement.err_side) {
          const fitPreferences = {
            jacket: {
              fit: data?.fit_preferences?.jacket_suit_fit,
              jacketLength: data?.fit_preferences?.jacket_suit_length,
              sleeveLength: data?.fit_preferences?.jacket_suit_sleeve_length,
            },
            trousers: {
              fit: data?.fit_preferences?.trouser_jeans_fit,
              length: data?.fit_preferences?.trouser_jeans_length,
            },
            shirt: {
              fit: data?.fit_preferences?.shirt_fit,
              shirtLength: data?.fit_preferences?.shirt_length,
              watchSide: data?.fit_preferences?.shirt_watch,
            },
          }
          const sizeRec = {
            product_type: data?.fit_preferences?.product_type,
            jacket: {
              size: data?.size_rec?.jacket?.size,
              waist: data?.size_rec?.jacket?.waist,
              waistAccMin: data?.size_rec?.jacket?.waist_min,
              waistAccMax: data?.size_rec?.jacket?.waist_max,
              sleeveLength: data?.size_rec?.jacket?.sleeve_length,
              sleeveLengthAccMin: data?.size_rec?.jacket?.sleeve_length_min,
              sleeveLengthAccMax: data?.size_rec?.jacket?.sleeve_length_max,
              length: data?.size_rec?.jacket?.length,
              lengthAccMin: data?.size_rec?.jacket?.length_min,
              lengthAccMax: data?.size_rec?.jacket?.length_max,
            },
            trousers: {
              size: data?.size_rec?.trousers?.size,
              waist: data?.size_rec?.trousers?.waist,
              waistAccMin: data?.size_rec?.trousers?.waist_min,
              waistAccMax: data?.size_rec?.trousers?.waist_max,
              length: data?.size_rec?.trousers?.length,
              lengthAccMin: data?.size_rec?.trousers?.length_min,
              lengthAccMax: data?.size_rec?.trousers?.length_max,
            },
            shirt: {
              size: data?.size_rec?.shirt?.size,
              waist: data?.size_rec?.shirt?.waist,
              waistAccMin: data?.size_rec?.shirt?.waist_min,
              waistAccMax: data?.size_rec?.shirt?.waist_max,
              neck: data?.size_rec?.shirt?.neck,
              neckAccMin: data?.size_rec?.shirt?.neck_min,
              neckAccMax: data?.size_rec?.shirt?.neck_max,
              sleeveLength: data?.size_rec?.shirt?.sleeve_length,
              sleeveLengthAccMin: data?.size_rec?.shirt?.sleeve_length_min,
              sleeveLengthAccMax: data?.size_rec?.shirt?.sleeve_length_max,
              length: data?.size_rec?.shirt?.length,
              lengthAccMin: data?.size_rec?.shirt?.length_min,
              lengthAccMax: data?.size_rec?.shirt?.length_max,
            },
          }
          // const surveyChoices = {
          //   chest: null,
          //   abdomen: null,
          //   hips: null,
          // }

          updateShop({ aitId: data.ait_id })

          if (
            shop.jeansOption === '1' &&
            measurement?.thigh &&
            measurement?.thigh !== '' &&
            measurement?.calf &&
            measurement?.calf !== ''
          ) {
            updateUser({
              // thigh: measurement?.thigh,
              // calf: measurement?.calf,
              thighThreshold: measurement?.thigh,
              calfThreshold: measurement?.calf,
            })
          }

          if (
            (shop.moreMeasurements === '1' || shop.moreMeasurements === '2') &&
            measurement?.neck &&
            measurement?.neck !== '' &&
            measurement?.thigh &&
            measurement?.thigh !== '' &&
            measurement?.calf &&
            measurement?.calf !== '' &&
            measurement?.chest &&
            measurement?.chest !== '' &&
            measurement?.waist &&
            measurement?.waist !== '' &&
            measurement?.basin &&
            measurement?.basin !== ''
          ) {
            updateUser({
              // neck: measurement?.neck,
              // thigh: measurement?.thigh,
              // calf: measurement?.calf,
              // chest: measurement?.chest,
              // waist: measurement?.waist,
              // basin: measurement?.basin,
              neckThreshold: measurement?.neck,
              thighThreshold: measurement?.thigh,
              calfThreshold: measurement?.calf,
              chestThreshold: measurement?.chest,
              waistThreshold: measurement?.waist,
              basinThreshold: measurement?.basin,
            })
          }

          updateFitPreferences(fitPreferences)
          updateSizeRec(sizeRec)
          updateSurveyChoices(surveyChoices)
          updateWidgetButton(data.button_text, data.ait_id)

          // navigate('/photos-ok', {
          //   replace: true,
          //   state: {
          //     pubSubTopicName: pubSubTopicName,
          //     ait_Id: data.ait_id,
          //     buttonText: data.button_text,
          //     fitPreferences: fitPreferences,
          //   },
          // })
          // if (isMobile && pubSubTopicName && shop.jeansOption !== '1') {
          //   window.open(
          //     makeContinueOnDesktopUrl(pubSubTopicName, data.ait_id, shop.language, null, '', ''),
          //     '_self',
          //     ''
          //   )
          // } else {
          //;(!front || front === '') && (!side || side === '')
          shop.shopId?.startsWith('SO')
            ? navigate('/measurement-profile', { replace: true })
            : navigate('/photos-ok', { replace: true })
          //}
        } else {
          if (measurement.err_front) {
            resetFrontPhoto()
          }
          if (measurement.err_side) {
            resetSidePhoto()
          }
          navigate('/analysis-error', {
            replace: true,
            state: {
              front: {
                imageData: front || null,
                errorMessage: measurement.err_front || null,
              },
              side: {
                imageData: side || null,
                errorMessage: measurement.err_side || null,
              },
            },
          })
        }
      })
      .catch(err => {
        navigate('/error', { replace: true })
        console.error(`[AIT][MTM] Error getting measurements: ${err}`)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
