import { isMobile, isTablet, isIOS, isIOS13 } from 'react-device-detect'
export const updateWidgetButton = (text: string, aitId: string | null) => {
  // let data = window.localStorage.getItem('ait-widget-time')
  // let diff = 1000000
  // if (data) {
  //   diff = new Date().getTime() - (JSON.parse(data) as number)
  // }
  // if (diff > 1500) {
  // daca am trimis acu mai mult de 1,5 sec sau este prima data cand trimit mesajul
  window.parent && window.parent.postMessage({ event: 'ait-mtm-update-button-text', text: text, ait_id: aitId }, '*')

  if (isMobile || isTablet) {
    let okStorage = false
    try {
      if ('localStorage' in window && window.localStorage !== null) {
        window.localStorage.setItem('testLocalStorage_', 'testLocalStorage_')
        if (window.localStorage.getItem('testLocalStorage_') !== 'testLocalStorage_') {
          window.localStorage.removeItem('testLocalStorage_')
          //for private browsing, error is thrown before even getting here
          //alert('can read CANNOT write');
        } else {
          window.localStorage.removeItem('testLocalStorage_')
          okStorage = true
        }
      } else {
        //alert('CANNOT use');
      }
    } catch (ex) {
      //alert('CANNOT use reliably');
    }
    if (okStorage && !(isIOS || isIOS13)) {
      //localStorage.removeItem('ait-widget-msg')
      console.log('Update using storage', aitId, text)
      window.localStorage.setItem(
        'ait-widget-msg',
        JSON.stringify({ event: 'ait-mtm-update-button-text', text: text, ait_id: aitId })
      )
    } else {
      console.log('Update using cookie', aitId, text)

      let textVal = text
      if (isIOS || isIOS13) {
        textVal = textVal
          .replace(/ă/g, '<a~>')
          .replace(/Ă/g, '<A~>')
          .replace(/î/g, '<i,,>')
          .replace(/Î/g, '<I,,>')
          .replace(/â/g, '<a,,>')
          .replace(/Â/g, '<A,,>')
          .replace(/ș/g, '<s,>')
          .replace(/Ș/g, '<S,>')
          .replace(/ț/g, '<t,>')
          .replace(/Ț/g, '<T,>')
          .replace(/é/g, '<e">')
          .replace(/à/g, '<a`>')
          .replace(/ä/g, '<a:>')
          .replace(/À/g, '<A`>')
          .replace(/è/g, '<e`>')
          .replace(/ù/g, '<u`>')
          .replace(/â/g, '<a,,>')
          .replace(/ê/g, '<e,,>')
          .replace(/î/g, '<i,,>')
          .replace(/ô/g, '<o,,>')
          .replace(/û/g, '<u,,>')
          .replace(/ç/g, '<c,>')
          .replace(/ë/g, '<e:>')
          .replace(/ï/g, '<i:>')
          .replace(/ü/g, '<u:>')
          .replace(/Â/g, '<A,,>')
          .replace(/Ä/g, '<A:>')
          .replace(/Ç/g, '<C,>')
          .replace(/È/g, '<E`>')
          .replace(/É/g, '<E">')
          .replace(/Ê/g, '<E,,>')
          .replace(/Ë/g, '<E:>')
          .replace(/Î/g, '<I,,>')
          .replace(/Ï/g, '<I:>')
          .replace(/Ô/g, '<O,,>')
          .replace(/Ù/g, '<U`>')
          .replace(/Û/g, '<U,,>')
          .replace(/Ü/g, '<U:>')
      }

      document.cookie = 'ait_btn_text=' + textVal + '; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure'
      // if (isIOS || isIOS13) {
      //   let match = document.cookie.match(new RegExp('(^| )ait_btn_text=([^;]+)'))
      //   let cookieBtnText = match && match[2]
      //   alert(text + ' ' + cookieBtnText + ' {' + match + '} ' + document.cookie)
      // }
    }
  }
  // }
  // window.localStorage.setItem('ait-widget-time', JSON.stringify(new Date().getTime()))
}

export const sendMessageToB2B = (aitId: string | null, mesaj: string | null) => {
  window.parent.postMessage({ event: 'ait-mtm-b2b-message', ait_id: aitId, mesaj: mesaj }, '*')
}
