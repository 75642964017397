import { useEffect, useContext } from 'react'
import { navigate } from '@reach/router'
import { PubSub } from '@aws-amplify/pubsub'

import { ApplicationContext } from 'app/Context'
//import { updateCookie, updateWidgetButton } from '@shared-utils' // isMobile,
import { isMobile, isTablet } from 'react-device-detect'
import { getDataAndUpdateStore } from 'app/services/api'
//import { updateUserAction } from 'app/domain/user/actions'

export const usePubSubSubscriber = () => {
  const {
    state: { pubSubTopicName, shop },
    actions,
    //actions: { updateShop, updateUser, updateFitPreferences },
  } = useContext(ApplicationContext)

  useEffect(() => {
    if (isMobile || isTablet) {
      return
    }

    PubSub.subscribe(pubSubTopicName).subscribe({
      next: async ({ value }) => {
        const isOnPrepareScreen = window.location.pathname.startsWith('/prepare')
        if (isOnPrepareScreen && value.action === 'wait-for-mobile') {
          return navigate('/wait-for-mobile')
        }

        const isOnWaitForMobileScreen = window.location.pathname.startsWith('/wait-for-mobile')
        if (isOnWaitForMobileScreen && value.action === 'mobile-flow-complete') {
          const { aitId, jeansOption, moreMeasurements, shopProductLink, fromB2B } = value.payload
          // console.log('PubSub.subscribe',shop)
          await getDataAndUpdateStore(
            {
              ...shop,
              aitId: aitId,
              source: 'default',
              jeansOption: jeansOption,
              moreMeasurements: moreMeasurements,
              fromB2B: fromB2B,
              shopProductLink: shopProductLink,
            },
            /*jeansOption,*/ actions
          )
          //if (thigh && thigh !== '' && calf && calf !== '') updateUser({ thigh: thigh, calf: calf })

          //if (fromB2B === '2' && !(isMobile || isTablet))
          window.parent && window.parent.postMessage({ event: 'ait-mtm-continue-on-desktop' }, '*')

          return navigate('/measurement-profile') //'/photos-ok'
        }
        console.warn('Message received: ', value)
      },
      error: error => console.error(error),
    })
  }, [pubSubTopicName, shop, actions]) //last : pubSubTopicName, shop, actions, updateShop, updateUser, updateFitPreferences
  //****** old : pubSubTopicName, updateShop, updateFitPreferences
}
