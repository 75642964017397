import jacketComfort from './jacket.comfort.png'
import jacketAdjusted from './jacket.regular.png'
import jacketSlim from './jacket.slim.png'
import jacketLong from './length.long.png'
import jacketRegularLen from './length.regular.png'
import jacketShort from './length.short.png'
import sleeveLong from './sleeve.long.png'
import sleeveRegular from './sleeve.regular.png'
import sleeveShort from './sleeve.short.png'
import trousersSemiSlim from './trousers.semislim.png'
import trousersSkinny from './trousers.skinny.png'
import trousersRegular from './trousers.regular.png'
import trousersBreak from './trousers.break.png'
import trousersNoBreak from './trousers.nobreak.png'
import shirtComfort from './shirt.comfort.png'
import shirtAdjusted from './shirt.regular.png'
import shirtSlim from './shirt.slim.png'
import shirtTucked from './shirt.tucked.png'
import shirtUntucked from './shirt.untucked.png'
import watchNone from './watch.none.png'
import watchRight from './watch.right.png'
import watchLeft from './watch.left.png'

export const images = {
  jacket: {
    fit: {
      comfort: jacketComfort,
      adjusted: jacketAdjusted,
      slim: jacketSlim,
    },
    length: {
      long: jacketLong,
      regular: jacketRegularLen,
      short: jacketShort,
    },
    sleeve: {
      long: sleeveLong,
      regular: sleeveRegular,
      short: sleeveShort,
    },
  },
  trousers: {
    fit: {
      skinny: trousersSkinny,
      semislim: trousersSemiSlim,
      regular: trousersRegular,
    },
    length: {
      nobreak: trousersNoBreak,
      slightbreak: trousersBreak,
    },
  },
  shirt: {
    fit: {
      comfort: shirtComfort,
      adjusted: shirtAdjusted,
      slim: shirtSlim,
    },
    length: {
      tucked: shirtTucked,
      untucked: shirtUntucked,
    },
    watch: {
      none: watchNone,
      right: watchRight,
      left: watchLeft,
    },
  },
}
