import React from 'react'

export const Circles = (props: any) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='110' height='110' viewBox='0 0 110 110' {...props}>
    <g id='loading' transform='translate(0)'>
      <circle
        id='Ellipse_29'
        data-name='Ellipse 29'
        cx='5'
        cy='5'
        r='5'
        transform='translate(50.397 0.397)'
        fill='#383a39'
      />
      <circle
        id='Ellipse_30'
        data-name='Ellipse 30'
        cx='5'
        cy='5'
        r='5'
        transform='translate(50.397 100.397)'
        fill='#eceeee'
      />
      <circle
        id='Ellipse_31'
        data-name='Ellipse 31'
        cx='5.365'
        cy='5.365'
        r='5.365'
        transform='translate(24.993 6.572)'
        fill='#77807f'
      />
      <path
        id='Path_2315'
        data-name='Path 2315'
        d='M346.129,421.2a5.368,5.368,0,1,1-7.333-1.967A5.52,5.52,0,0,1,346.129,421.2Z'
        transform='translate(-261.042 -325.021)'
        fill='#f2f4f4'
      />
      <path
        id='Path_2316'
        data-name='Path 2316'
        d='M37.761,112.692a5.368,5.368,0,1,1-7.333,1.967A5.317,5.317,0,0,1,37.761,112.692Z'
        transform='translate(-23.141 -86.983)'
        fill='#9faaa9'
      />
      <path
        id='Path_2317'
        data-name='Path 2317'
        d='M426.561,336.828a5.368,5.368,0,1,1-7.333,1.967A5.43,5.43,0,0,1,426.561,336.828Z'
        transform='translate(-325.021 -261.042)'
        fill='#f2f7f7'
      />
      <circle
        id='Ellipse_32'
        data-name='Ellipse 32'
        cx='5'
        cy='5'
        r='5'
        transform='translate(0.397 50.397)'
        fill='#b2bbba'
      />
      <circle
        id='Ellipse_33'
        data-name='Ellipse 33'
        cx='5'
        cy='5'
        r='5'
        transform='translate(100.397 50.397)'
        fill='#fff'
      />
      <path
        id='Path_2318'
        data-name='Path 2318'
        d='M32.12,336.828a5.368,5.368,0,1,1-1.967,7.333A5.357,5.357,0,0,1,32.12,336.828Z'
        transform='translate(-22.865 -261.042)'
        fill='#c5cccb'
      />
      <path
        id='Path_2319'
        data-name='Path 2319'
        d='M421.2,112.828a5.368,5.368,0,1,1-1.967,7.333A5.52,5.52,0,0,1,421.2,112.828Z'
        transform='translate(-325.021 -87.12)'
      />
      <path
        id='Path_2320'
        data-name='Path 2320'
        d='M112.828,421.2a5.368,5.368,0,1,1,1.967,7.333A5.52,5.52,0,0,1,112.828,421.2Z'
        transform='translate(-87.12 -325.021)'
        fill='#d9dddd'
      />
      <path
        id='Path_2321'
        data-name='Path 2321'
        d='M336.828,32.4a5.368,5.368,0,1,1,1.967,7.333A5.43,5.43,0,0,1,336.828,32.4Z'
        transform='translate(-261.042 -23.141)'
        fill='#111'
      />
    </g>
  </svg>
)
