import { AppAction } from 'app/domain/contracts'
import {
  UpdatableUserFields,
  UpdatableFitPreferencesFields,
  UpdatableSizeRecFields,
  UpdatableSurveyChoicesFields,
} from 'app/domain/user/contracts'

export const updateUserAction = (dispatch: React.Dispatch<AppAction>) => (fields: UpdatableUserFields) => {
  dispatch({
    entity: 'user',
    name: 'updateUser',
    payload: fields,
  })
}

export const resetSidePhotoAction = (dispatch: React.Dispatch<AppAction>) => () => {
  dispatch({
    entity: 'user',
    name: 'resetSidePhoto',
  })
}

export const resetFrontPhotoAction = (dispatch: React.Dispatch<AppAction>) => () => {
  dispatch({
    entity: 'user',
    name: 'resetFrontPhoto',
  })
}

export const updateFitPreferencesAction = (dispatch: React.Dispatch<AppAction>) => (
  fields: UpdatableFitPreferencesFields
) => {
  dispatch({
    entity: 'user',
    name: 'updateFitPreferences',
    payload: fields,
  })
}

export const updateSizeRecAction = (dispatch: React.Dispatch<AppAction>) => (fields: UpdatableSizeRecFields) => {
  dispatch({
    entity: 'user',
    name: 'updateSizeRec',
    payload: fields,
  })
}

export const setDefaultPreferencesAction = (dispatch: React.Dispatch<AppAction>) => () => {
  dispatch({
    entity: 'user',
    name: 'setDefaultPreferences',
  })
}

export const updateSurveyChoicesAction = (dispatch: React.Dispatch<AppAction>) => (
  fields: UpdatableSurveyChoicesFields
) => {
  dispatch({
    entity: 'user',
    name: 'updateSurveyChoices',
    payload: fields,
  })
}

export const setDefaultSurveyChoicesAction = (dispatch: React.Dispatch<AppAction>) => () => {
  dispatch({
    entity: 'user',
    name: 'setDefaultSurveyChoices',
  })
}
