import React from 'react'
import styled from 'styled-components'
import { Text } from 'rebass/styled-components'
import { RouteComponentProps } from '@reach/router'
import { Label, Checkbox } from '@rebass/forms'

import { t } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { theme } from 'app/assets/theme'

interface Props extends RouteComponentProps {
  accepted: boolean
  onValueChanged: () => void
}
//

export const TermsAndConditions: React.FC<Props> = ({ onValueChanged, accepted }) => {
  return (
    <Container>
      <TermsAndConditionsInfoText variant='body'>{t(i18nKeys.start.terms.disclaimer)}</TermsAndConditionsInfoText>
      <TermsAndConditionsContainer>
        <Checkbox
          height={window.innerHeight > 320 ? '24px' : '20px'}
          width={window.innerHeight > 320 ? '24px' : '20px'}
          checked={accepted}
          name='acceptTC'
          onChange={onValueChanged}
          color={accepted ? theme.colors.primary : theme.colors.secondary}
        />
        <TermsAndConditionsLinkText variant='body' accepted={accepted}>
          {t(i18nKeys.start.terms.accept)}{' '}
          <a
            rel='noopener noreferrer'
            href='https://ait-mtm-legal.s3.eu-central-1.amazonaws.com/standard-terms-and-conditions.pdf'
            target='_blank'
          >
            {t(i18nKeys.start.terms.termsAndConditions)}
          </a>
        </TermsAndConditionsLinkText>
      </TermsAndConditionsContainer>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 10px;
  margin-bottom: 15px;
  @media screen and (max-height: 700px) {
    margin-top: ${Math.ceil(window.innerHeight * 0.014) < 2
      ? '2'
      : Math.ceil(window.innerHeight * 0.014) > 10
      ? '10'
      : Math.ceil(window.innerHeight * 0.014)}px;
    margin-bottom: ${Math.ceil(window.innerHeight * 0.014) < 2
      ? '2'
      : Math.ceil(window.innerHeight * 0.014) > 15
      ? '15'
      : Math.ceil(window.innerHeight * 0.014)}px;
  }
`

const TermsAndConditionsInfoText = styled(Text)`
  margin-bottom: 10px;
  @media screen and (max-height: 700px) {
    font-size: ${Math.ceil(window.innerHeight * 0.021) < 10
      ? '10'
      : Math.ceil(window.innerHeight * 0.021) > 12
      ? '12'
      : Math.ceil(window.innerHeight * 0.021)}px;
    margin-bottom: ${Math.ceil(window.innerHeight * 0.002) < 2
      ? '2'
      : Math.ceil(window.innerHeight * 0.002) > 10
      ? '10'
      : Math.ceil(window.innerHeight * 0.002)}px;
  }
  @media screen and (max-height: 320px) {
    display: none;
  }
`

const TermsAndConditionsContainer = styled(Label)`
  justify-content: center;
`

const TermsAndConditionsLinkText = styled(Text)<{ accepted: boolean }>`
  padding: 3px;
  color: ${({ accepted }) => (accepted ? theme.colors.primary : theme.colors.secondary)};
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    text-align: left;
  }
  @media screen and (max-height: 700px) {
    font-size: ${Math.ceil(window.innerHeight * 0.021) < 10
      ? '10'
      : Math.ceil(window.innerHeight * 0.021) > 12
      ? '12'
      : Math.ceil(window.innerHeight * 0.021)}px;
  }
`
