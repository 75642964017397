import { AppActions } from 'app/domain/contracts'
import { UpdatableShopFields, ShopState } from 'app/domain/shop/contracts'
import {
  UserState,
  ShirtPreferences,
  JacketPreferences,
  TrousersPreferences,
  UpdatableUserFields,
  PhotoAngles,
  SurveyChoices,
} from 'app/domain/user/contracts'
import { updateWidgetButton, updateCookie } from '@shared-utils'
//import { textChangeRangeIsUnchanged } from 'typescript'
//import { findByTestId } from '@testing-library/react'

const apiUrl = 'https://api.ait-tailoring.com/widget'

interface ImageData {
  front: string
  side: string
  angles?: {
    front: PhotoAngles
    side: PhotoAngles
  }
}

export const sendMeasurement = async (
  { shopId, shopUserId, domainName, aitId, language, productType, fitId }: ShopState,
  { name, surname, height, weight, age, preferences: { measurementSystem } }: UserState,
  { front, side, angles }: ImageData,
  { chest, abdomen, hips }: SurveyChoices
) => {
  const requestData = {
    type: 'measurement',
    measurementType: (!front || front === '') && (!side || side === '') ? 'chestionar' : 'foto',
    shop_id: shopId,
    shop_user_id: shopUserId || null,
    ait_id: aitId || null,
    product_type: productType || null,
    fit_id: fitId || null,
    //shop_product_link: shopProductLink || null,, shopProductLink
    domain: domainName || null,
    language: language,
    measureName: name || '',
    measureSurname: surname || '',
    gender: '1',
    height: height,
    weight: weight,
    age: age,
    unit: measurementSystem === 'metric' ? 'cm' : 'inch',
    sensorFB_F: angles?.front?.FB || 0,
    sensorLR_F: angles?.front?.LR || 0,
    foto_front: front,
    sensorFB_S: angles?.side?.FB || 0,
    sensorLR_S: angles?.side?.LR || 0,
    foto_side: side,
    survey_chest: chest,
    survey_abdomen: abdomen,
    survey_hips: hips,
  }
  console.warn('POST on type=MEASUREMENT')
  return fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  })
    .then(response => response.json())
    .then(data => {
      if (data?.ait_id) {
        updateCookie(data.ait_id)
      }
      return data
    })
}

interface FitPreferences {
  jacket: JacketPreferences
  trousers: TrousersPreferences
  shirt: ShirtPreferences
}

export const saveDataOnAitServer = async (
  shop: ShopState,
  { thigh, calf, neck, chest, waist, basin }: UserState,
  { jacket, trousers, shirt }: FitPreferences
) => {
  const { aitId, shopId, domainName, shopUserId, language, productType, fitId, shopProductLink } = shop

  const reqBody = JSON.stringify({
    type: 'fit_preferences',
    ait_id: aitId,
    shop_id: shopId,
    shop_user_id: shopUserId,
    language: language,
    product_type: productType,
    fit_id: fitId,
    shop_product_link: shopProductLink,
    domain: domainName,
    thigh: thigh || '',
    calf: calf || '',
    neck: neck || '',
    chest: chest || '',
    waist: waist || '',
    basin: basin || '',
    jacket_suit_fit: jacket.fit,
    jacket_suit_sleeve_length: jacket.sleeveLength,
    jacket_suit_length: jacket.jacketLength,
    trouser_jeans_fit: trousers.fit,
    trouser_jeans_length: trousers.length,
    shirt_fit: shirt.fit,
    shirt_length: shirt.shirtLength,
    shirt_watch: shirt.watchSide,
  })

  return await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: reqBody,
  })
}

export const clearDataOnAitServer = async ({
  aitId,
  shopId,
  domainName,
  language,
  shopUserId,
}: UpdatableShopFields) => {
  const reqBody = JSON.stringify({
    type: 'delete_ait_id',
    ait_id: aitId,
    shop_id: shopId,
    domain: domainName,
    language: language,
    shop_user_id: shopUserId,
  })

  return await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: reqBody,
  })
}

export const getDataAndUpdateStore = async (
  params: UpdatableShopFields,
  //jeansOption: any,
  { updateShop, updateUser, updateFitPreferences, updateSizeRec, updateSurveyChoices }: AppActions
) => {
  //console.log('getDataAndUpdateStore', params)
  const data = await getData(params)

  if (data?.ait_id === null) {
    return { isInvalidProfileId: true }
  }
  updateCookie(data.ait_id)
  //console.log(data)
  const user: UpdatableUserFields = {
    name: data?.measurement?.profile_name,
    surname: data?.measurement?.profile_Surname,
    height: data?.measurement?.height,
    weight: data?.measurement?.weight,
    age: data?.measurement?.age,
    thigh: data?.measurement?.thigh,
    calf: data?.measurement?.calf,
    neck: data?.measurement?.neck,
    chest: data?.measurement?.chest,
    waist: data?.measurement?.waist,
    basin: data?.measurement?.basin,
    thighThreshold: data?.measurement?.thigh,
    calfThreshold: data?.measurement?.calf,
    neckThreshold: data?.measurement?.neck,
    chestThreshold: data?.measurement?.chest,
    waistThreshold: data?.measurement?.waist,
    basinThreshold: data?.measurement?.basin,
    preferences: { measurementSystem: data?.measurement?.unit === 'inch' ? 'imperial' : 'metric' },
  }

  const fitPreferences = {
    jacket: {
      fit: data?.fit_preferences?.jacket_suit_fit,
      jacketLength: data?.fit_preferences?.jacket_suit_length,
      sleeveLength: data?.fit_preferences?.jacket_suit_sleeve_length,
    },
    trousers: {
      fit: data?.fit_preferences?.trouser_jeans_fit,
      length: data?.fit_preferences?.trouser_jeans_length,
    },
    shirt: {
      fit: data?.fit_preferences?.shirt_fit,
      shirtLength: data?.fit_preferences?.shirt_length,
      watchSide: data?.fit_preferences?.shirt_watch,
    },
  }

  const sizeRec = {
    product_type: data?.fit_preferences?.product_type,
    jacket: {
      size: data?.size_rec?.jacket?.size,
      waist: data?.size_rec?.jacket?.waist,
      waistAccMin: data?.size_rec?.jacket?.waist_min,
      waistAccMax: data?.size_rec?.jacket?.waist_max,
      sleeveLength: data?.size_rec?.jacket?.sleeve_length,
      sleeveLengthAccMin: data?.size_rec?.jacket?.sleeve_length_min,
      sleeveLengthAccMax: data?.size_rec?.jacket?.sleeve_length_max,
      length: data?.size_rec?.jacket?.length,
      lengthAccMin: data?.size_rec?.jacket?.length_min,
      lengthAccMax: data?.size_rec?.jacket?.length_max,
    },
    trousers: {
      size: data?.size_rec?.trousers?.size,
      waist: data?.size_rec?.trousers?.waist,
      waistAccMin: data?.size_rec?.trousers?.waist_min,
      waistAccMax: data?.size_rec?.trousers?.waist_max,
      length: data?.size_rec?.trousers?.length,
      lengthAccMin: data?.size_rec?.trousers?.length_min,
      lengthAccMax: data?.size_rec?.trousers?.length_max,
    },
    shirt: {
      size: data?.size_rec?.shirt?.size,
      waist: data?.size_rec?.shirt?.waist,
      waistAccMin: data?.size_rec?.shirt?.waist_min,
      waistAccMax: data?.size_rec?.shirt?.waist_max,
      neck: data?.size_rec?.shirt?.neck,
      neckAccMin: data?.size_rec?.shirt?.neck_min,
      neckAccMax: data?.size_rec?.shirt?.neck_max,
      sleeveLength: data?.size_rec?.shirt?.sleeve_length,
      sleeveLengthAccMin: data?.size_rec?.shirt?.sleeve_length_min,
      sleeveLengthAccMax: data?.size_rec?.shirt?.sleeve_length_max,
      length: data?.size_rec?.shirt?.length,
      lengthAccMin: data?.size_rec?.shirt?.length_min,
      lengthAccMax: data?.size_rec?.shirt?.length_max,
    },
  }

  const surveyChoices = {
    chest: null,
    abdomen: null,
    hips: null,
  }

  updateShop({ aitId: data?.ait_id /*, jeansOption: jeansOption*/ })
  updateUser(user)
  updateFitPreferences(fitPreferences)
  updateSizeRec(sizeRec)
  updateSurveyChoices(surveyChoices)
  updateWidgetButton(data.button_text, data?.ait_id)
}

const getData = async ({
  shopId,
  domainName,
  shopUserId,
  aitId,
  shopProductLink,
  productType,
  fitId,
  language,
  source,
}: UpdatableShopFields) => {
  if (domainName !== window.location.hostname) {
    domainName = window.location.hostname
  }

  const requestData = {
    type: 'req_id',
    shop_id: shopId,
    domain: domainName,
    shop_user_id: shopUserId || null,
    ait_id: aitId || null,
    product_type: productType || null,
    fit_id: fitId || null,
    shop_product_link: shopProductLink || null,
    language: language,
    source: source,
  }
  //console.log(requestData)
  return fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  }).then(response => response.json())
}
