import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Text, Button, Image } from 'rebass/styled-components'
import { isMobile, isTablet } from 'react-device-detect'
//import { makeContinueOnDesktopUrl } from 'packages/@shared-utils/funcs/makeContinueOnMobileUrl'
import { saveDataOnAitServer } from 'app/services/api'
import { RouteComponentProps, navigate } from '@reach/router'
import { t, useWindowHeight } from '@shared-utils'
import { Input } from '@rebass/forms/styled-components'
import { i18nKeys } from 'app/assets/18nKeys'
import { ApplicationContext } from 'app/Context'
import { useUpdateDataFromApi } from 'app/hooks'
import { validations } from 'app/screens/start/constants'
import { BackChevron, ForwardChevron } from 'app/assets/icons'
import { theme } from 'app/assets/theme'
import { Slider } from 'app/screens/start/components/Slider'
//import { ClassificationType } from 'typescript'
import { PubSub } from '@aws-amplify/pubsub'

import { images } from 'app/assets/mm_option'

const keys = i18nKeys.jeansOption
const keysMM = i18nKeys.moreMeasurements
const iKey = 'mm_body'
export const MoreMeasurements: React.FC<RouteComponentProps> = () => {
  useUpdateDataFromApi()

  const {
    state: {
      pubSubTopicName,
      shop,
      shop: { aitId, /*language,*/ jeansOption, moreMeasurements, shopProductLink, fromB2B },
      user,
      user: {
        thigh,
        calf,
        neck,
        chest,
        waist,
        basin,
        thighThreshold,
        calfThreshold,
        neckThreshold,
        chestThreshold,
        waistThreshold,
        basinThreshold,
        fitPreferences,
        preferences: { measurementSystem },
      },
    },

    actions: { updateUser, updateShop },
  } = useContext(ApplicationContext)

  const [sliderShown, setSliderShown] = useState<'calf' | 'thigh' | 'neck' | 'chest' | 'waist' | 'basin' | null>(null)
  const [isFirstTime, setIsFirstTime] = useState(true)
  //const handleFocus = (event: any) => event.target.select()
  const isValid = (
    value: string | null,
    attribute: 'calf' | 'thigh' | 'neck' | 'chest' | 'waist' | 'basin',
    threshold: string | null
  ) => {
    if (!value) return false
    if (value === null) return false

    try {
      var val = Number.parseFloat(value)
      return (
        value.toString().length <= 5 &&
        val >=
          (threshold === null || threshold === ''
            ? validations[measurementSystem][attribute].min
            : Number.parseFloat(threshold) -
              (measurementSystem === 'metric' ? (attribute === 'calf' || attribute === 'thigh' ? 10 : 30) : 4)) &&
        val <=
          (threshold === null || threshold === ''
            ? validations[measurementSystem][attribute].max
            : Number.parseFloat(threshold) +
              (measurementSystem === 'metric' ? (attribute === 'calf' || attribute === 'thigh' ? 10 : 30) : 4))
      )
    } catch {
      return false
    }
  }

  const height = useWindowHeight()
  useEffect(() => {
    var element = document.getElementById('Top')
    if (element != null) {
      var parent = element.parentElement
      if (parent != null) {
        parent.style.height = '99%'
      }
    }
    // const resize = () => {
    //   console.log('resize event')
    //   console.log(btnVisible)
    //   btnVisible === "false" ? setBtnVisible("true") : setBtnVisible("false")
    // }

    // window.addEventListener('resize', resize)
    // return () => {
    //   window.removeEventListener('resize', resize)
    // }
  }, [])

  return (
    <>
      <Container id='Top' inaltime={height}>
        <ColumnContainer>
          <ColumnContainer>
            <TitleText>{t(keysMM.pageTitle)}</TitleText>
            {/* <text>{btnVisible ? "visible":"invisible"}</text> */}
            {/* <InstructionsText variant='body'>{t(keysMM.measPageInstructions)}</InstructionsText> */}
          </ColumnContainer>

          <RowContainerTop>
            <ColumnContainerInputLeft>
              <ColumnContainer1>
                <InputText variant='body'>
                  {t(keysMM.neck)} {/* ( */}
                  {/* {t(keys.calculated, {
                  attr:
                    thighThreshold !== null && thighThreshold !== '' ? thighThreshold + (measurementSystem === 'metric' ? ' cm' : ' ft') : 'NaN',
                })} */}
                  {/* ) */}
                </InputText>
                <RowContainer>
                  <ProfileInput
                    name='neck'
                    isValid={isValid(neck ? neck : '', 'neck', neckThreshold ? neckThreshold : '') || isFirstTime}
                    placeholder={t(
                      isValid(neck ? neck : '', 'neck', neckThreshold ? neckThreshold : '') || isFirstTime
                        ? keysMM.neck
                        : keysMM.neckRequired
                    )}
                    value={neck || ''}
                    autoComplete='off'
                    type='number'
                    maxLength={4}
                    min={
                      neckThreshold === null || neckThreshold === ''
                        ? validations[measurementSystem]['neck'].min
                        : Number.parseFloat(neckThreshold ? neckThreshold : '') -
                          (measurementSystem === 'metric' ? 30 : 4)
                    }
                    max={
                      neckThreshold === null || neckThreshold === ''
                        ? validations[measurementSystem]['neck'].max
                        : Number.parseFloat(neckThreshold ? neckThreshold : '') +
                          (measurementSystem === 'metric' ? 30 : 4)
                    }
                    onChange={e => updateUser({ neck: e.target.value })}
                    onFocus={({ target }) => {
                      if (isMobile || isTablet) {
                        target.blur()
                        setSliderShown('neck')
                      } else {
                        target.select()
                      }
                    }}
                    isVisible={true}
                  />
                </RowContainer>
              </ColumnContainer1>
              <ColumnContainer2>
                <InputText variant='body'>
                  {t(keysMM.chest)} {/* ( */}
                  {/* {t(keys.calculated, {
                  attr:
                    thighThreshold !== null && thighThreshold !== '' ? thighThreshold + (measurementSystem === 'metric' ? ' cm' : ' ft') : 'NaN',
                })} */}
                  {/* ) */}
                </InputText>
                <RowContainer>
                  <ProfileInput
                    name='chest'
                    isValid={isValid(chest ? chest : '', 'chest', chestThreshold ? chestThreshold : '') || isFirstTime}
                    placeholder={t(
                      isValid(chest ? chest : '', 'chest', chestThreshold ? chestThreshold : '') || isFirstTime
                        ? keysMM.chest
                        : keysMM.chestRequired
                    )}
                    value={chest || ''}
                    autoComplete='off'
                    type='number'
                    maxLength={4}
                    min={
                      chestThreshold === null || chestThreshold === ''
                        ? validations[measurementSystem]['chest'].min
                        : Number.parseFloat(chestThreshold ? chestThreshold : '') -
                          (measurementSystem === 'metric' ? 30 : 4)
                    }
                    max={
                      chestThreshold === null || chestThreshold === ''
                        ? validations[measurementSystem]['chest'].max
                        : Number.parseFloat(chestThreshold ? chestThreshold : '') +
                          (measurementSystem === 'metric' ? 30 : 4)
                    }
                    onChange={e => updateUser({ chest: e.target.value })}
                    onFocus={({ target }) => {
                      if (isMobile || isTablet) {
                        target.blur()
                        setSliderShown('chest')
                      } else {
                        target.select()
                      }
                    }}
                    isVisible={true}
                  />
                </RowContainer>
              </ColumnContainer2>
              <ColumnContainer3>
                <InputText variant='body'>
                  {t(keysMM.waist)} {/* ( */}
                  {/* {t(keys.calculated, {
                  attr:
                    thighThreshold !== null && thighThreshold !== '' ? thighThreshold + (measurementSystem === 'metric' ? ' cm' : ' ft') : 'NaN',
                })} */}
                  {/* ) */}
                </InputText>
                <RowContainer>
                  <ProfileInput
                    name='waist'
                    isValid={isValid(waist ? waist : '', 'waist', waistThreshold ? waistThreshold : '') || isFirstTime}
                    placeholder={t(
                      isValid(waist ? waist : '', 'waist', waistThreshold ? waistThreshold : '') || isFirstTime
                        ? keysMM.waist
                        : keysMM.waistRequired
                    )}
                    value={waist || ''}
                    autoComplete='off'
                    type='number'
                    maxLength={4}
                    min={
                      waistThreshold === null || waistThreshold === ''
                        ? validations[measurementSystem]['waist'].min
                        : Number.parseFloat(waistThreshold ? waistThreshold : '') -
                          (measurementSystem === 'metric' ? 30 : 4)
                    }
                    max={
                      waistThreshold === null || waistThreshold === ''
                        ? validations[measurementSystem]['waist'].max
                        : Number.parseFloat(waistThreshold ? waistThreshold : '') +
                          (measurementSystem === 'metric' ? 30 : 4)
                    }
                    onChange={e => updateUser({ waist: e.target.value })}
                    onFocus={({ target }) => {
                      if (isMobile || isTablet) {
                        target.blur()
                        setSliderShown('waist')
                      } else {
                        target.select()
                      }
                    }}
                    isVisible={true}
                  />
                </RowContainer>
              </ColumnContainer3>
              <ColumnContainer4>
                <InputText variant='body'>
                  {t(keysMM.basin)} {/* ( */}
                  {/* {t(keys.calculated, {
                  attr:
                    thighThreshold !== null && thighThreshold !== '' ? thighThreshold + (measurementSystem === 'metric' ? ' cm' : ' ft') : 'NaN',
                })} */}
                  {/* ) */}
                </InputText>
                <RowContainer>
                  <ProfileInput
                    name='basin'
                    isValid={isValid(basin ? basin : '', 'basin', basinThreshold ? basinThreshold : '') || isFirstTime}
                    placeholder={t(
                      isValid(basin ? basin : '', 'basin', basinThreshold ? basinThreshold : '') || isFirstTime
                        ? keysMM.basin
                        : keysMM.basinRequired
                    )}
                    value={basin || ''}
                    autoComplete='off'
                    type='number'
                    maxLength={4}
                    min={
                      basinThreshold === null || basinThreshold === ''
                        ? validations[measurementSystem]['basin'].min
                        : Number.parseFloat(basinThreshold ? basinThreshold : '') -
                          (measurementSystem === 'metric' ? 30 : 4)
                    }
                    max={
                      basinThreshold === null || basinThreshold === ''
                        ? validations[measurementSystem]['basin'].max
                        : Number.parseFloat(basinThreshold ? basinThreshold : '') +
                          (measurementSystem === 'metric' ? 30 : 4)
                    }
                    onChange={e => updateUser({ basin: e.target.value })}
                    onFocus={({ target }) => {
                      if (isMobile || isTablet) {
                        target.blur()
                        setSliderShown('basin')
                      } else {
                        target.select()
                      }
                    }}
                    isVisible={true}
                  />
                </RowContainer>
              </ColumnContainer4>
              <ColumnContainer5>
                <InputText variant='body'>
                  {t(keys.thigh)} {/* ( */}
                  {/* {t(keys.calculated, {
                  attr:
                    thighThreshold !== null && thighThreshold !== '' ? thighThreshold + (measurementSystem === 'metric' ? ' cm' : ' ft') : 'NaN',
                })} */}
                  {/* ) */}
                </InputText>
                <RowContainer>
                  <ProfileInput
                    name='thigh'
                    isValid={isValid(thigh ? thigh : '', 'thigh', thighThreshold ? thighThreshold : '') || isFirstTime}
                    placeholder={t(
                      isValid(thigh ? thigh : '', 'thigh', thighThreshold ? thighThreshold : '') || isFirstTime
                        ? keys.thigh
                        : keys.thighRequired
                    )}
                    value={thigh || ''}
                    autoComplete='off'
                    type='number'
                    maxLength={4}
                    min={
                      thighThreshold === null || thighThreshold === ''
                        ? validations[measurementSystem]['thigh'].min
                        : Number.parseFloat(thighThreshold ? thighThreshold : '') -
                          (measurementSystem === 'metric' ? 10 : 4)
                    }
                    max={
                      thighThreshold === null || thighThreshold === ''
                        ? validations[measurementSystem]['thigh'].max
                        : Number.parseFloat(thighThreshold ? thighThreshold : '') +
                          (measurementSystem === 'metric' ? 10 : 4)
                    }
                    onChange={e => updateUser({ thigh: e.target.value })}
                    onFocus={({ target }) => {
                      if (isMobile || isTablet) {
                        target.blur()
                        setSliderShown('thigh')
                      } else {
                        target.select()
                      }
                    }}
                    isVisible={true}
                  />
                </RowContainer>
              </ColumnContainer5>
              <ColumnContainer6>
                <InputText variant='body'>
                  {t(keys.calf)} {/* ( */}
                  {/* {t(keys.calculated, {
                  attr:
                    calfThreshold !== null && calfThreshold !== '' ? calfThreshold + (measurementSystem === 'metric' ? ' cm' : ' ft') : 'NaN',
                })} */}
                  {/* ) */}
                </InputText>
                <RowContainer>
                  <ProfileInput
                    name='calf'
                    isValid={isValid(calf ? calf : '', 'calf', calfThreshold ? calfThreshold : '') || isFirstTime}
                    placeholder={t(
                      isValid(calf ? calf : '', 'calf', calfThreshold ? calfThreshold : '') || isFirstTime
                        ? keys.calf
                        : keys.calfRequired
                    )}
                    value={calf || ''}
                    autoComplete='off'
                    type='number'
                    maxLength={4}
                    min={
                      calfThreshold === null || calfThreshold === ''
                        ? validations[measurementSystem]['calf'].min
                        : Number.parseFloat(calfThreshold ? calfThreshold : '') -
                          (measurementSystem === 'metric' ? 10 : 4)
                    }
                    max={
                      calfThreshold === null || calfThreshold === ''
                        ? validations[measurementSystem]['calf'].max
                        : Number.parseFloat(calfThreshold ? calfThreshold : '') +
                          (measurementSystem === 'metric' ? 10 : 4)
                    }
                    onChange={e => updateUser({ calf: e.target.value })}
                    onFocus={({ target }) => {
                      if (isMobile || isTablet) {
                        target.blur()
                        setSliderShown('calf')
                      } else {
                        target.select()
                      }
                    }}
                    isVisible={true}
                  />
                </RowContainer>
              </ColumnContainer6>
            </ColumnContainerInputLeft>
            <ColumnContainerImage>
              <BodyImage src={images[iKey]} />
            </ColumnContainerImage>
            <ColumnContainerInputRight>
              <ColumnContainerR1>
                <HelpButton
                  onClick={() => {
                    alert(t(keysMM.tutTitleNeck) + '\n\n' + t(keysMM.tutInfoNeck))
                  }}
                >
                  ?
                </HelpButton>
              </ColumnContainerR1>
              <ColumnContainerR2>
                <HelpButton
                  onClick={() => {
                    alert(t(keysMM.tutTitleChest) + '\n\n' + t(keysMM.tutInfoChest))
                  }}
                >
                  ?
                </HelpButton>
              </ColumnContainerR2>
              <ColumnContainerR3>
                <HelpButton
                  onClick={() => {
                    alert(t(keysMM.tutTitleWaist) + '\n\n' + t(keysMM.tutInfoWaist))
                  }}
                >
                  ?
                </HelpButton>
              </ColumnContainerR3>
              <ColumnContainerR4>
                <HelpButton
                  onClick={() => {
                    alert(t(keysMM.tutTitleBasin) + '\n\n' + t(keysMM.tutInfoBasin))
                  }}
                >
                  ?
                </HelpButton>
              </ColumnContainerR4>
              <ColumnContainerR5>
                <HelpButton
                  onClick={() => {
                    alert(t(keysMM.tutTitleThigh) + '\n\n' + t(keysMM.tutInfoThigh))
                  }}
                >
                  ?
                </HelpButton>
              </ColumnContainerR5>
              <ColumnContainerR6>
                <HelpButton
                  onClick={() => {
                    alert(t(keysMM.tutTitleCalf) + '\n\n' + t(keysMM.tutInfoCalf))
                  }}
                >
                  ?
                </HelpButton>
              </ColumnContainerR6>
            </ColumnContainerInputRight>
          </RowContainerTop>
          {sliderShown ? (
            <ColumnContainerSlider>
              <Slider
                heightValue={0}
                weightValue={0}
                ageValue={0}
                calfValue={+(calf ? calf : '')}
                thighValue={+(thigh ? thigh : '')}
                neckValue={+(neck ? neck : '')}
                chestValue={+(chest ? chest : '')}
                waistValue={+(waist ? waist : '')}
                basinValue={+(basin ? basin : '')}
                calfThreshold={
                  calfThreshold === null || calfThreshold === ''
                    ? null
                    : Number.parseFloat(calfThreshold ? calfThreshold : '')
                }
                thighThreshold={
                  thighThreshold === null || thighThreshold === ''
                    ? null
                    : Number.parseFloat(thighThreshold ? thighThreshold : '')
                }
                neckThreshold={
                  neckThreshold === null || neckThreshold === ''
                    ? null
                    : Number.parseFloat(neckThreshold ? neckThreshold : '')
                }
                chestThreshold={
                  chestThreshold === null || chestThreshold === ''
                    ? null
                    : Number.parseFloat(chestThreshold ? chestThreshold : '')
                }
                waistThreshold={
                  waistThreshold === null || waistThreshold === ''
                    ? null
                    : Number.parseFloat(waistThreshold ? waistThreshold : '')
                }
                basinThreshold={
                  basinThreshold === null || basinThreshold === ''
                    ? null
                    : Number.parseFloat(basinThreshold ? basinThreshold : '')
                }
                attribute={sliderShown}
                measurementSystem={measurementSystem}
                onCancel={() => setSliderShown(null)}
                onConfirm={value => {
                  updateUser({ [sliderShown]: value })
                  setSliderShown(null)
                }}
              />
            </ColumnContainerSlider>
          ) : null}
        </ColumnContainer>

        {sliderShown ? null : (
          <MobileControlsDown>
            <RowJustified>
              <MyButton
                onClick={() => navigate('/photos-ok')}
                color={theme.colors.secondary}
                variant={'flat'}
                backgroundColor='transparent'
              >
                <BackChevron />
                &nbsp;{t(i18nKeys.back)}
              </MyButton>
              <RowEnd>
                <MyButton
                  onClick={async () => {
                    setIsFirstTime(false)
                    if (
                      isValid(thigh ? thigh : '', 'thigh', thighThreshold ? thighThreshold : '') &&
                      isValid(calf ? calf : '', 'calf', calfThreshold ? calfThreshold : '') &&
                      isValid(neck ? neck : '', 'neck', neckThreshold ? neckThreshold : '') &&
                      isValid(chest ? chest : '', 'chest', chestThreshold ? chestThreshold : '') &&
                      isValid(waist ? waist : '', 'waist', waistThreshold ? waistThreshold : '') &&
                      isValid(basin ? basin : '', 'basin', basinThreshold ? basinThreshold : '')
                    ) {
                      const response = await saveDataOnAitServer(shop, user, fitPreferences)
                      if (!response.ok) {
                        console.error(
                          `[AIT][MTM] error saving data on AIT server: ${response.status}:${response.statusText}`
                        )
                        return
                      }
                      if ((isMobile || isTablet) && pubSubTopicName) {
                        PubSub.publish(pubSubTopicName, {
                          action: 'mobile-flow-complete',
                          payload: {
                            aitId: aitId,
                            jeansOption: jeansOption,
                            moreMeasurements: moreMeasurements,
                            fromB2B: fromB2B,
                            shopProductLink: shopProductLink,
                          },
                        })
                        navigate('/continue-on-desktop', { replace: true })
                      } else {
                        updateShop({ fromPhotosOK: '2' })
                        navigate('/measurement-profile', { replace: true })
                      }
                    } else {
                      let error = 'Invalid fields:'
                      if (!isValid(neck ? neck : '', 'neck', neckThreshold ? neckThreshold : ''))
                        error += ` neck=${neck}`
                      if (!isValid(chest ? chest : '', 'chest', chestThreshold ? chestThreshold : ''))
                        error += ` chest=${chest}`
                      if (!isValid(waist ? waist : '', 'waist', waistThreshold ? waistThreshold : ''))
                        error += ` waist=${waist}`
                      if (!isValid(basin ? basin : '', 'basin', basinThreshold ? basinThreshold : ''))
                        error += ` basin=${basin}`
                      if (!isValid(thigh ? thigh : '', 'thigh', thighThreshold ? thighThreshold : ''))
                        error += ` thigh=${thigh}`
                      if (!isValid(calf ? calf : '', 'calf', calfThreshold ? calfThreshold : ''))
                        error += ` calf=${calf}`
                      console.error(error)
                    }
                  }}
                  color={theme.colors.secondary}
                  variant={'flat'}
                  backgroundColor='transparent'
                >
                  {t(i18nKeys.next)}&nbsp;
                  <ForwardChevron />
                </MyButton>
                {/* } */}
              </RowEnd>
            </RowJustified>
          </MobileControlsDown>
        )}
      </Container>
    </>
  )
}

const Container = styled.div<{ inaltime: number }>`
  display: flex;
  width: 100%;
  height: ${({ inaltime }) => inaltime}px;
  minheight: ${({ inaltime }) => inaltime}px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0px;
  @media screen and (min-width: ${theme.breakpoints[0]}) {
    padding-bottom: 0px;
  }
`

const BodyImage = styled(Image)`
  max-height: calc(${window.innerHeight}px - 50px);
  max-width: 100%;
  width: auto;
  height: auto;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: -10px;
  height: ${Math.round(window.innerHeight * 0.8)}px;
`
const ColumnContainerInputLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 200%;
  text-align: center;
  align-items: center;
  justify-content: start;
  margin-top: 5px;
`
const ColumnContainerSlider = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: start;
  bottom: 5px;
  background-color: white;
`

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: start;
`

const ColumnContainer1 = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 47%;
  text-align: center;
  align-items: center;
  justify-content: start;
  left: 20px;
  top: ${Math.round(window.innerHeight * 0.8 * 0.13)}px;
`
const ColumnContainer2 = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 47%;
  text-align: center;
  align-items: center;
  justify-content: start;
  left: 22px;
  top: ${Math.round(window.innerHeight * 0.8 * 0.29)}px;
`
const ColumnContainer3 = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 47%;
  text-align: center;
  align-items: center;
  justify-content: start;
  left: 22px;
  top: ${Math.round(window.innerHeight * 0.8 * 0.41)}px;
`
const ColumnContainer4 = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 47%;
  text-align: center;
  align-items: center;
  justify-content: start;
  left: 22px;
  top: ${Math.round(window.innerHeight * 0.8 * 0.54)}px;
`
const ColumnContainer5 = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 47%;
  text-align: center;
  align-items: center;
  justify-content: start;
  left: 22px;
  top: ${Math.round(window.innerHeight * 0.8 * 0.66)}px;
`

const ColumnContainer6 = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 47%;
  text-align: center;
  align-items: center;
  justify-content: start;
  left: 22px;
  top: ${Math.round(window.innerHeight * 0.8 * 0.82)}px;
`
const ColumnContainerR1 = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 47%;
  text-align: center;
  align-items: center;
  justify-content: start;
  left: ${Math.round(window.innerWidth * 0.8 * 0.82)}px;
  top: ${Math.round(window.innerHeight * 0.8 * 0.18)}px;
`
const ColumnContainerR2 = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 47%;
  text-align: center;
  align-items: center;
  justify-content: start;
  left: ${Math.round(window.innerWidth * 0.8 * 0.82)}px;
  top: ${Math.round(window.innerHeight * 0.8 * 0.33)}px;
`
const ColumnContainerR3 = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 47%;
  text-align: center;
  align-items: center;
  justify-content: start;
  left: ${Math.round(window.innerWidth * 0.8 * 0.82)}px;
  top: ${Math.round(window.innerHeight * 0.8 * 0.46)}px;
`
const ColumnContainerR4 = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 47%;
  text-align: center;
  align-items: center;
  justify-content: start;
  left: ${Math.round(window.innerWidth * 0.8 * 0.82)}px;
  top: ${Math.round(window.innerHeight * 0.8 * 0.59)}px;
`
const ColumnContainerR5 = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 47%;
  text-align: center;
  align-items: center;
  justify-content: start;
  left: ${Math.round(window.innerWidth * 0.8 * 0.82)}px;
  top: ${Math.round(window.innerHeight * 0.8 * 0.71)}px;
`
const ColumnContainerR6 = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 47%;
  text-align: center;
  align-items: center;
  justify-content: start;
  left: ${Math.round(window.innerWidth * 0.8 * 0.82)}px;
  top: ${Math.round(window.innerHeight * 0.8 * 0.87)}px;
`
const ColumnContainerImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 170vw;
  text-align: center;
  align-items: center;
  justify-content: start;
  margin-left: -8%;
`
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 80%;
`
const RowContainerTop = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 89%;
`
const ColumnContainerInputRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 5%;
  text-align: center;
  align-items: center;
  justify-content: start;
  margin-top: 5px;
  margin-letf: 2%;
`

//TO DO: stergere display: none; cand va fi nevoie
const ProfileInput = styled(Input)<{ isValid: boolean; isVisible: boolean }>`
  ${({ isVisible }) => (isVisible ? '' : 'display: none;')}
  text-align: center;
  height: 35px;
  margin-bottom: 5px;
  width: 85%;
  border-color: ${({ isValid }) => (isValid ? theme.colors.lightGray : theme.colors.secondary)};
  @media screen and (max-height: 670px) {
    margin-bottom: 2px;
  }
`
const HelpButton = styled(Button)`
  min-width: 30px;
  width: 30px;
  min-height: 35px;
  height: 35px;
  padding-top: 10px;
  padding-left: 10px;
  border-radius: 4px;
`
//frameBorder: '1px solid gray',

const MyButton = styled(Button)`
  outline: none !important;
  outline-offset: none !important;
  padding-left: 35px;
  padding-right: 35px;
`
const InputText = styled(Text)`
  padding-bottom: 4px;
  width: 80%;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  flex: 1 0 auto;
  text-align: left;
`

// const InstructionsText = styled(Text)`
//   padding-top: 5px;
//   padding-bottom: 5px;
//   width: 80%;
//   font-size: 14px;
//   line-height: 19px;
//   font-weight: 400;
//   flex: 1 0 auto;
//   text-align: center;
// `

const TitleText = styled(Text)`
  padding-top: 5px;
  width: 80%;
  font-weight: 800;
  line-height: 24px;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[5]}px;
  text-align: center;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    font-weight: 600;
    font-size: ${theme.fontSizes[4]}px;
  }
` //
const RowJustified = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
//
const RowEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`
// position: fixed;
const MobileControlsDown = styled.div`
  position: fixed;
  z-index: 2000;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding-bottom: 30px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    padding-bottom: 15px;
  }
`
