import React, { useReducer, useCallback } from 'react'

import { reducer } from 'app/domain/reducer'
import { App, AppState } from 'app/domain/contracts'
import { updatePubSubTopicNameAction, clearStateActionName } from 'app/domain/actions'
import {
  updateUserAction,
  resetSidePhotoAction,
  resetFrontPhotoAction,
  updateFitPreferencesAction,
  updateSizeRecAction,
  setDefaultPreferencesAction,
  updateSurveyChoicesAction,
  setDefaultSurveyChoicesAction,
} from 'app/domain/user/actions'
import { usePubSubSubscriber } from 'app/hooks'
import { makePubSubTopicName } from '@shared-utils' //, isMobile
import { isMobile, isTablet } from 'react-device-detect'
import { updateShopAction } from './domain/shop/actions'

const initialState: AppState = {
  pubSubTopicName: isMobile || isTablet ? '' : makePubSubTopicName(),
  shop: {
    aitId: '',
    shopId: '',
    domainName: '',
    language: '',
    shopUserId: '',
    productType: '',
    fitId: '',
    shopProductLink: null,
    jeansOption: null,
    moreMeasurements: null,
    fromB2B: null,
    fromPhotosOK: null,
  },
  user: {
    name: '',
    surname: '',
    height: '',
    weight: '',
    age: '',
    thigh: '',
    thighThreshold: '',
    calf: '',
    calfThreshold: '',
    neck: '',
    neckThreshold: '',
    chest: '',
    chestThreshold: '',
    waist: '',
    waistThreshold: '',
    basin: '',
    basinThreshold: '',
    preferences: {
      measurementSystem: 'metric',
    },
    fitPreferences: {
      jacket: {
        fit: null,
        jacketLength: null,
        sleeveLength: null,
      },
      trousers: {
        fit: null,
        length: null,
      },
      shirt: {
        fit: null,
        shirtLength: null,
        watchSide: null,
      },
      isSaved: false,
    },
    sizeRec: {
      product_type: '',
      jacket: {
        size: '',
        waist: '',
        waistAccMin: '',
        waistAccMax: '',
        sleeveLength: '',
        sleeveLengthAccMin: '',
        sleeveLengthAccMax: '',
        length: '',
        lengthAccMin: '',
        lengthAccMax: '',
      },
      trousers: {
        size: '',
        waist: '',
        waistAccMin: '',
        waistAccMax: '',
        length: '',
        lengthAccMin: '',
        lengthAccMax: '',
      },
      shirt: {
        size: '',
        waist: '',
        waistAccMin: '',
        waistAccMax: '',
        neck: '',
        neckAccMin: '',
        neckAccMax: '',
        sleeveLength: '',
        sleeveLengthAccMin: '',
        sleeveLengthAccMax: '',
        length: '',
        lengthAccMin: '',
        lengthAccMax: '',
      },
    },
    photos: {
      base64: {
        side: '',
        front: '',
      },
    },
    surveyChoices: {
      chest: null,
      abdomen: null,
      hips: null,
    },
  },
}

const defaultApp: App = {
  state: initialState,
  actions: {
    clearState: () => {},
    updateShop: () => {},
    updateUser: () => {},
    resetSidePhoto: () => {},
    resetFrontPhoto: () => {},
    updateFitPreferences: () => {},
    updateSizeRec: () => {},
    updatePubSubTopicName: _ => {},
    setDefaultPreferences: () => {},
    updateSurveyChoices: () => {},
    setDefaultSurveyChoices: () => {},
  },
}

export const ApplicationContext = React.createContext<App>(defaultApp)

const SubscribeToMobile = () => {
  usePubSubSubscriber()
  return null
}

type Props = {
  children?: React.ReactNode
}

export const ApplicationProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const updateShop = useCallback(updateShopAction(dispatch), [])
  const updateUser = useCallback(updateUserAction(dispatch), [])
  const clearState = useCallback(clearStateActionName(dispatch), [])
  const resetSidePhoto = useCallback(resetSidePhotoAction(dispatch), [])
  const resetFrontPhoto = useCallback(resetFrontPhotoAction(dispatch), [])
  const updateFitPreferences = useCallback(updateFitPreferencesAction(dispatch), [])
  const updateSizeRec = useCallback(updateSizeRecAction(dispatch), [])
  const updatePubSubTopicName = useCallback(updatePubSubTopicNameAction(dispatch), [])
  const setDefaultPreferences = useCallback(setDefaultPreferencesAction(dispatch), [])
  const updateSurveyChoices = useCallback(updateSurveyChoicesAction(dispatch), [])
  const setDefaultSurveyChoices = useCallback(setDefaultSurveyChoicesAction(dispatch), [])

  return (
    <ApplicationContext.Provider
      value={{
        state,
        actions: {
          clearState,
          updateShop,
          updateUser,
          resetSidePhoto,
          resetFrontPhoto,
          updateFitPreferences,
          updateSizeRec,
          updatePubSubTopicName,
          setDefaultPreferences,
          updateSurveyChoices,
          setDefaultSurveyChoices,
        },
      }}
    >
      <SubscribeToMobile />
      {children}
    </ApplicationContext.Provider>
  )
}
