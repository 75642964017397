import { DefaultTheme } from 'styled-components'

const colors = {
  secondary: '#FF8800',
  primary: '#525252',
  lightGray: '#b0b0b0',
}

const inputStyle = {
  bg: 'white',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'lightGray',
  ':focus': {
    outline: 'none',
    borderColor: 'primary',
  },
  '::placeholder': {
    color: 'lightGray',
  },
}

const baseButtonStyle = {
  p: 3,
  cursor: 'pointer',
  fontWeight: 'bold',
  borderRadius: 'default',
  minWidth: 130,
  width: 170,
  fontSize: 2,
}

export const theme: DefaultTheme = {
  colors,
  fontSizes: [10, 12, 14, 15, 16, 18, 20, 24, 32, 48, 64, 96],
  radii: {
    default: 0,
    circle: 9999,
  },
  fonts: {
    body: '"Open Sans", system-ui, sans-serif',
    heading: '"Open Sans", system-ui, sans-serif',
  },
  space: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 60, 120, 240, 500],
  breakpoints: ['600px', '960px', '1400px'],
  buttons: {
    primary: {
      ...baseButtonStyle,
      bg: 'primary',
      boxShadow: '0px 2px 6px rgba(0, 10, 98, 0.16)',
      ':hover,:focus': {
        boxShadow: '0px 2px 6px rgba(0, 10, 98, 0.3)',
        outline: 'none',
      },
      ':disabled': {
        opacity: 0.5,
      },
    },
    secondary: {
      ...baseButtonStyle,
      boxShadow: '0px 2px 6px rgba(0, 10, 98, 0.16)',
      bg: 'secondary',
      ':hover,:focus': {
        outline: 'none',
      },
      ':disabled': {
        bg: 'lightGray',
        boxShadow: 'none',
      },
    },
    lightGray: {
      ...baseButtonStyle,
      bg: 'lightGray',
      ':hover,:focus': {
        outline: 'none',
      },
    },
    rounded: {
      bg: 'white',
      width: 34,
      height: 34,
      cursor: 'pointer',
      color: 'secondary',
      borderRadius: 'circle',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.161)',
      ':hover,:focus': {
        outline: 'none',
      },
    },
    camera: {
      borderRadius: 'circle',
      bg: '#66ee77',
      ':disabled': {
        bg: '#d9d9d9',
      },
    },
    link: {
      bg: 'transparent',
      color: 'lightGray',
      border: 'none',
      cursor: 'pointer',
      fontSize: [0, 0, 1],
      display: 'flex',
      alignItems: 'center',
      textTransform: 'none',
      ':hover,:active': {
        color: 'primary',
      },
      ':focus': {
        outline: 'none',
      },
    },
    outline: {
      bg: 'white',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'primary',
      borderRadius: 'default',
      minWidth: 130,
      width: 170,
      fontSize: 2,
      fontWeight: 800,
      color: 'primary',
      ':focus': {
        outline: 'none',
      },
    },
  },
  text: {
    body: {
      color: 'primary',
      fontSize: [1, 2, 2, 3],
      lineHeight: ['16px', '16px', '20px', '22px'],
    },
    bodyLight: {
      color: 'lightGray',
      fontSize: [1, 1, 1, 2],
      lineHeight: ['16px', '16px', '20px', '22px'],
    },
    heading: {
      color: 'primary',
      textAlign: 'center',
      fontWeight: [600, 800],
      fontSize: [4, 4, 5, 6],
      marginBottom: [3, 4, 5, 6],
      lineHeight: ['20px', '22px', '24px', '26px'],
    },
    headingLight: {
      fontWeight: [400, 600],
      color: 'primary',
      textAlign: 'center',
      fontSize: [5, 5, 4, 6],
      lineHeight: ['24px', '22px', '22px', '24px'],
    },
    label: {
      color: 'primary',
      fontSize: 0,
      fontWeight: 600,
      lineHeight: '14px',
    },
    iconButtonLabel: {
      color: 'primary',
      fontSize: 0,
      textDecoration: 'none',
    },
    profileLabel: {
      color: 'lightGray',
      fontWeight: 400,
      fontSize: [1, 1, 1, 2],
      lineHeight: ['17px', '17px', '20px', '22px'],
      minWidth: 100,
    },
    profileValue: {
      color: 'primary',
      fontWeight: 600,
      fontSize: [1, 1, 1, 2],
      lineHeight: ['17px', '17px', '20px', '22px'],
    },
  },
  forms: {
    input: inputStyle,
  },
}
