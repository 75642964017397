import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Text, Button } from 'rebass/styled-components'
import { navigate } from '@reach/router'

import { t } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { theme } from 'app/assets/theme'

export const UnkownError: React.FC<any> = () => {
  useEffect(() => {
    var element = document.getElementById('Top')
    if (element != null) {
      var parent = element.parentElement
      if (parent != null) {
        parent.style.height = '99%'
      }
    }
  }, [])
  return (
    <PageContainer id='Top'>
      <ComponentsContainer>
        <ErrorTitleText>{t(i18nKeys.error.title)}</ErrorTitleText>
        <ErrorText>{t(i18nKeys.error.text1)}</ErrorText>
        <ErrorText>{t(i18nKeys.error.text2)}</ErrorText>
        <RetryButton onClick={() => navigate('/analyzing')}>{t(i18nKeys.error.tryAgain)}</RetryButton>
      </ComponentsContainer>
    </PageContainer>
  )
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  text-align: center;
  align-items: center;
`

const ComponentsContainer = styled.div`
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  width: 60%;
  text-align: center;
  align-items: center;
`

const ErrorTitleText = styled(Text)`
  font-weight: 800;
  line-height: 24px;
  margin-bottom: 150px;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[5]}px;
`

const ErrorText = styled(Text)`
  font-weight: 800;
  line-height: 24px;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[4]}px;
`

const RetryButton = styled(Button)`
  margin-top: 100px;
`
