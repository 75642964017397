import React from 'react'

export const Jacket = (props: any) => (
  <svg
    id='jacket'
    xmlns='http://www.w3.org/2000/svg'
    width='20.062'
    height='23.668'
    viewBox='0 0 20.062 23.668'
    {...props}
  >
    <path
      id='Path_2524'
      data-name='Path 2524'
      d='M59.054,18.4l-.808-4.445a.462.462,0,0,0-.91.165l.345,1.9-2.759.333a6.828,6.828,0,0,1,.162-3.2L56.2,7.9l.389,2.138a.462.462,0,0,0,.91-.165l-.892-4.9h0a2.053,2.053,0,0,0-1.3-1.614L52.327,1.84,51.614.271A.462.462,0,0,0,51.193,0H46.868a.462.462,0,0,0-.421.271L45.734,1.84,42.749,3.353a2.052,2.052,0,0,0-1.3,1.614h0L39.007,18.4a.462.462,0,0,0,.4.542l3.115.376-.543,2.45a.462.462,0,0,0,.269.525l3.119,1.338a.462.462,0,0,0,.465-.059l3.2-2.471,3.2,2.471a.462.462,0,0,0,.465.059l3.119-1.338a.462.462,0,0,0,.269-.525l-.543-2.45,3.115-.376a.462.462,0,0,0,.4-.542ZM51.964,3.186,52.7,4.638l-.95.881a.462.462,0,0,0-.108.526l.654,1.479-2.8,3.848V7.865ZM47.166.924H50.9l.563,1.239-2.428,4.6L46.6,2.163ZM46.1,3.186l2.471,4.679v3.508l-2.8-3.848.654-1.479a.462.462,0,0,0-.108-.526l-.95-.881ZM41.858,7.895l1.118,5.261a6.828,6.828,0,0,1,.162,3.2l-2.758-.333ZM40,18.082l.21-1.153,2.762.333-.254,1.148Zm5.477,4.592-2.517-1.08.57-2.575v0l.455-2.052h0s0-.009,0-.013,0-.018,0-.027l.022-.172c.058-.447.1-.794.13-1.1h1.9a.462.462,0,1,0,0-.925H44.17a12.269,12.269,0,0,0-.29-1.767l-1.3-6.14a6.1,6.1,0,0,1-.218-1.672l0-.018a.468.468,0,0,0,.006-.057c.065-.412.3-.647.8-.9l1.873-.95-.658,1.3a.462.462,0,0,0,.1.547l.963.893-.629,1.421a.462.462,0,0,0,.049.459l3.7,5.091v7.346Zm7.1,0-3.087-2.384V12.944l3.7-5.091a.462.462,0,0,0,.049-.459l-.629-1.421.963-.893a.462.462,0,0,0,.1-.547l-.658-1.3,1.873.95c.5.251.736.486.8.9a.464.464,0,0,0,.006.058l0,.019a6.125,6.125,0,0,1-.217,1.67l-1.306,6.14a12.271,12.271,0,0,0-.29,1.767h-1.88a.462.462,0,1,0,0,.925h1.9c.028.3.072.65.13,1.1l.022.172c0,.009,0,.018,0,.027s0,.009,0,.013h0l.455,2.052v0l.57,2.575Zm2.759-4.263-.254-1.148,2.763-.334.209,1.153Z'
      transform='translate(-39 0)'
      fill='currentColor'
    />
    <path
      id='Path_2525'
      data-name='Path 2525'
      d='M427.494,249.962h0a.462.462,0,1,0,.462-.464A.462.462,0,0,0,427.494,249.964Z'
      transform='translate(-409.535 -237.967)'
      fill='currentColor'
    />
  </svg>
)
