import React, { useEffect, useContext, useState } from 'react'
import styled from 'styled-components'
import { Text } from 'rebass/styled-components'

import { SurveyChoices } from 'app/domain/user/contracts'
import { i18nKeys } from 'app/assets/18nKeys'
import { SpecialCheckbox } from './SpecialCheckbox'
import { ApplicationContext } from 'app/Context'
import { t } from '@shared-utils'
import { Loading } from 'app/components/Loading'

type ChestOption = 'flat' | 'regular' | 'proeminent'
type WaistOption = 'flat' | 'regular' | 'proeminent'
type HipsOption = 'narrow' | 'regular' | 'wide'

declare global {
  interface Window {
    BodyKit: any
  }
}
interface Props {
  choices: SurveyChoices
  onChange: (choices: SurveyChoices) => void
}

export const SurveyPanel: React.FC<Props> = ({ choices, onChange }: Props) => {
  const chestOptions: ChestOption[] = ['flat', 'regular', 'proeminent']
  const abdomenOptions: WaistOption[] = ['flat', 'regular', 'proeminent']
  const hipsOptions: HipsOption[] = ['narrow', 'regular', 'wide']
  const {
    state: { user },
  } = useContext(ApplicationContext)

  const [initChest, setInitChest] = useState(0)
  const [initWaist, setInitWaist] = useState(0)
  const [initHips, setInitHips] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  const setNativeValue_out = (element: any, value: any) => {
    const prototype = Object.getPrototypeOf(element)
    const prototypePlaceholderSetterP = Object.getOwnPropertyDescriptor(prototype, 'placeholder')?.set
    prototypePlaceholderSetterP?.call(element, value)
  }

  const setWideNarrow = (grup: string, optiune: string) => {
    let bl_widget = document.getElementById('bodylabsWidget')
    if (bl_widget) {
      let el1 = bl_widget.children[0].children[0].children[1].children[2]
      let elChest = el1.children[2].children[3].children[0]
      let elWaist = el1.children[3].children[3].children[0]
      let elHips = el1.children[4].children[3].children[0]

      let newChestVal = initChest
      if (grup === 'chest') {
        if (optiune === 'flat') newChestVal -= 5
        else if (optiune === 'proeminent')
          newChestVal = Number.parseFloat(newChestVal.toString()) + Number.parseFloat('5')
      } else {
        if (choices.chest === 'flat') newChestVal -= 5
        else if (choices.chest === 'proeminent')
          newChestVal = Number.parseFloat(newChestVal.toString()) + Number.parseFloat('5')
      }

      let newWaistVal = initWaist
      if (grup === 'abdomen') {
        if (optiune === 'flat') newWaistVal -= 5
        else if (optiune === 'proeminent')
          newWaistVal = Number.parseFloat(newWaistVal.toString()) + Number.parseFloat('5')
      } else {
        if (choices.abdomen === 'flat') newWaistVal -= 5
        else if (choices.abdomen === 'proeminent')
          newWaistVal = Number.parseFloat(newWaistVal.toString()) + Number.parseFloat('5')
      }

      let newHipsVal = initHips
      if (grup === 'hips') {
        if (optiune === 'narrow') newHipsVal -= 5
        else if (optiune === 'wide') newHipsVal = Number.parseFloat(newHipsVal.toString()) + Number.parseFloat('5')
      } else {
        if (choices.hips === 'narrow') newHipsVal -= 5
        else if (choices.hips === 'wide') newHipsVal = Number.parseFloat(newHipsVal.toString()) + Number.parseFloat('5')
      }

      setNativeValue_out(elChest, newChestVal)
      elChest.dispatchEvent(new Event('input', { bubbles: true }))
      setNativeValue_out(elWaist, newWaistVal)
      elWaist.dispatchEvent(new Event('input', { bubbles: true }))
      setNativeValue_out(elHips, newHipsVal)
      elHips.dispatchEvent(new Event('input', { bubbles: true }))
    }
  }

  useEffect(() => {
    const setNativeValue = (element: any, value: any) => {
      const prototype = Object.getPrototypeOf(element)
      const prototypePlaceholderSetterP = Object.getOwnPropertyDescriptor(prototype, 'placeholder')?.set
      prototypePlaceholderSetterP?.call(element, value)
    }
    const setMeasures = () => {
      let bl_widget = document.getElementById('bodylabsWidget')
      if (bl_widget) {
        try {
          let el1 = bl_widget.children[0].children[0].children[1].children[2]
          let elementH = el1.children[0].children[3].children[0]
          let elementW = el1.children[1].children[3].children[0]

          if (elementH && elementW) {
            setIsLoading(false)

            setNativeValue(elementH, user.height)
            elementH.dispatchEvent(new Event('input', { bubbles: true }))

            setNativeValue(elementW, user.weight)
            elementW.dispatchEvent(new Event('input', { bubbles: true }))
            //muta corpul in mijloc
            let el = document.getElementsByClassName('meshviewer-inner')[0] as HTMLElement
            el.style['width'] = '300%'
            el.style['marginLeft'] = '-' + (((window.innerWidth - 280) * 62) / 93 + 365) + 'px'
            //muta butonul de reset in mijloc
            el = document.getElementsByClassName('cross')[0] as HTMLElement
            el.style['marginLeft'] = '-' + (((window.innerWidth - 360) * 55) / 83 + 210) + 'px'
            el.style['marginBottom'] = '' + (((window.innerWidth - 280) * 13) / 488 + 17) + 'px'
            el.innerHTML = t(i18nKeys.survey.recenterView)
            el.click()

            el = document.getElementById('tab_options') as HTMLElement
            el.style['display'] = ''

            el = document.getElementById('btn_okToContinue') as HTMLElement
            el.style['display'] = ''

            let elChest = el1.children[2].children[3].children[0]
            let elWaist = el1.children[3].children[3].children[0]
            let elHips = el1.children[4].children[3].children[0]

            const prototypeC = Object.getPrototypeOf(elChest)
            const prototypePlaceholderGetterC = Object.getOwnPropertyDescriptor(prototypeC, 'placeholder')?.get
            setInitChest(prototypePlaceholderGetterC?.call(elChest))

            const prototypeW = Object.getPrototypeOf(elWaist)
            const prototypePlaceholderGetterW = Object.getOwnPropertyDescriptor(prototypeW, 'placeholder')?.get
            setInitWaist(prototypePlaceholderGetterW?.call(elWaist))

            const prototypeH = Object.getPrototypeOf(elHips)
            const prototypePlaceholderGetterH = Object.getOwnPropertyDescriptor(prototypeH, 'placeholder')?.get
            setInitHips(prototypePlaceholderGetterH?.call(elHips))
          } else {
            console.log('NOT elementH && elementW')
            setTimeout(setMeasures, 100)
          }
        } catch {
          console.log('catch')
          setTimeout(setMeasures, 100)
        }
      } else {
        console.log('NOT bl_widget')
        setTimeout(setMeasures, 100)
      }
    }

    let attrs = {
      genderOptions: ['male'],
      defaultGender: 'male',
      zoomSpeed: 0.1,
      showUi: true,
      isSlave: false,
      hiddenMeasurements: [],
      optionalMeasurements: [
        'shirt_sleeve_length',
        'mid_neck_girth',
        'side_arm_length',
        'wrist_girth',
        'shoulder_length',
      ],
      accessKey: 'AK5699f615fffc2003d59375c7ab62c130',
    }
    if (window.BodyKit) {
      window.BodyKit.bootstrapWidget(document.getElementById('bodylabsWidget'), attrs)
      setTimeout(setMeasures, 100)
    }
  }, [/*initChest, initWaist, initHips,*/ user.height, user.weight])
  return (
    <>
      <Container>
        <ContainerBodyShape id='bodylabsWidget'></ContainerBodyShape>
        <table id='tab_options' style={{ width: '100%', marginTop: '-60px', display: 'none' }}>
          <tbody>
            <tr>
              <td style={{ width: '22%' }} align='left' valign='bottom'>
                <Text variant='label'>{t(i18nKeys.survey.chest)}</Text>
              </td>
              {chestOptions.map(o => (
                <td key={'chest_option_' + o} style={{ width: '36%' }} align='left'>
                  <SpecialCheckbox
                    key={o}
                    label={o === 'flat' ? t(i18nKeys.survey.flatChest) : t(i18nKeys.survey[o])}
                    isLabelBold={choices['chest'] === o}
                    isChecked={choices['chest'] === o}
                    onClick={() => {
                      onChange({ ...choices, chest: o })
                      setWideNarrow('chest', o)
                    }}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td style={{ width: '22%' }} align='left' valign='bottom'>
                <Text variant='label'>{t(i18nKeys.survey.abdomen)}</Text>
              </td>
              {abdomenOptions.map(o => (
                <td key={'waist_option_' + o} style={{ width: '36%' }} align='left'>
                  <SpecialCheckbox
                    key={o}
                    label={o === 'flat' ? t(i18nKeys.survey.flatAbdomen) : t(i18nKeys.survey[o])}
                    isLabelBold={choices['abdomen'] === o}
                    isChecked={choices['abdomen'] === o}
                    onClick={() => {
                      onChange({ ...choices, abdomen: o })
                      setWideNarrow('abdomen', o)
                    }}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td style={{ width: '22%' }} align='left' valign='bottom'>
                <Text variant='label'>{t(i18nKeys.survey.hips)}</Text>
              </td>
              {hipsOptions.map(o => (
                <td key={'hips_option_' + o} style={{ width: '36%' }} align='left'>
                  <SpecialCheckbox
                    key={o}
                    label={t(i18nKeys.survey[o])}
                    isLabelBold={choices['hips'] === o}
                    isChecked={choices['hips'] === o}
                    onClick={() => {
                      onChange({ ...choices, hips: o })
                      setWideNarrow('hips', o)
                    }}
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </Container>
      {isLoading ? (
        <FullScreenOverlay>
          <LoadingSpinner />
        </FullScreenOverlay>
      ) : null}
    </>
  )
}

const ContainerBodyShape = styled.div`
  margin-top: 1px;
  width: 100%;
  height: 65vh !important;
`

const Container = styled.div`
  margin-bottom: 15px;
  width: 90%;
  @media screen and (max-height: 700px) {
    margin-bottom: ${Math.ceil(window.innerHeight * 0.03) < 5
      ? '5'
      : Math.ceil(window.innerHeight * 0.03) > 15
      ? '15'
      : Math.ceil(window.innerHeight * 0.03)}px;
  }
`
const FullScreenOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`

const LoadingSpinner = styled(Loading)`
  margin-top: 0;
`
