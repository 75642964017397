import React, { useEffect, useContext } from 'react'
import styled from 'styled-components'
import { /*Button,*/ Text } from 'rebass/styled-components'
import { isMobile, isIOS, isIOS13, isMobileSafari, isSafari, isTablet } from 'react-device-detect'

import { RouteComponentProps, navigate } from '@reach/router'
import { t, myIsIOS, myIsSafari } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { variableWidthStyle } from '../start/style'
import { images } from 'app/assets/survey'
import { theme } from 'app/assets/theme'
//import { MeasurementSytemNames } from 'app/domain/user/contracts'
//import { PubSub } from '@aws-amplify/pubsub'
import { getQueryStringValue } from '@shared-utils'
import { ApplicationContext } from 'app/Context'

const keys = i18nKeys.choosePath

export const ChoosePath: React.FC<RouteComponentProps> = () => {
  const {
    //state,
    actions: { updateShop, updateUser, updatePubSubTopicName },
  } = useContext(ApplicationContext)

  useEffect(() => {
    var element = document.getElementById('Top')
    if (element != null) {
      var parent = element.parentElement
      if (parent != null) {
        parent.style.height = '99%'
      }
    }

    // if (getQueryStringValue('topic') && (isMobile || isTablet)) {
    //   if (!myIsIOS(isIOS, isIOS13) || (myIsIOS(isIOS, isIOS13) && myIsSafari(isSafari, isMobileSafari))) {
    //     updatePubSubTopicName(getQueryStringValue('topic'))

    //     updateUser({
    //       height: getQueryStringValue('uh') || getQueryStringValue('height'),
    //       weight: getQueryStringValue('uw') || getQueryStringValue('weight'),
    //       age: getQueryStringValue('ua') || getQueryStringValue('age'),
    //       thigh: getQueryStringValue('ut') || getQueryStringValue('thigh'),
    //       neck: getQueryStringValue('unk') || getQueryStringValue('neck'),
    //       calf: getQueryStringValue('uc') || getQueryStringValue('calf'),
    //       chest: getQueryStringValue('uch') || getQueryStringValue('chest'),
    //       waist: getQueryStringValue('uwa') || getQueryStringValue('waist'),
    //       basin: getQueryStringValue('ub') || getQueryStringValue('basin'),
    //       name: getQueryStringValue('un') || getQueryStringValue('name'),
    //       surname: getQueryStringValue('us') || getQueryStringValue('surname'),
    //       preferences: {
    //         measurementSystem: getQueryStringValue('ums') as MeasurementSytemNames,
    //       },
    //     })

    //     updateShop({
    //       aitId: getQueryStringValue('aid') || getQueryStringValue('ait_id'),
    //       language: getQueryStringValue('slang') || getQueryStringValue('language') || 'en',
    //       productType: getQueryStringValue('spt') || getQueryStringValue('product_type'),
    //       shopId: getQueryStringValue('sid') || getQueryStringValue('shop_id'),
    //       shopUserId: getQueryStringValue('suid') || getQueryStringValue('shop_user_id'),
    //       shopProductLink: getQueryStringValue('spl') || getQueryStringValue('shop_product_link'),
    //       fitId: getQueryStringValue('fid') || getQueryStringValue('fit_id'),
    //       jeansOption: getQueryStringValue('jns') || getQueryStringValue('jeans_option'),
    //     })

    //     PubSub.publish(getQueryStringValue('topic') as string, { action: 'wait-for-mobile' })
    //   }
    // }
  }, [updateShop, updateUser, updatePubSubTopicName])

  return (
    <>
      {myIsIOS(isIOS, isIOS13) && !myIsSafari(isSafari, isMobileSafari) ? (
        <ContainerNotGood id='Top'>
          <BigOrangeText>
            {(getQueryStringValue('topic') || getQueryStringValue('fromB2B') === '2') && (isMobile || isTablet)
              ? t(i18nKeys.notSafariOniOS)
              : t(i18nKeys.notSafariOniOSNoQR)}
          </BigOrangeText>
        </ContainerNotGood>
      ) : (
        <PageContainer id='Top' isMobile={isMobile || isTablet}>
          <ComponentsContainer isMobile={isMobile || isTablet}>
            <ImagePhotos
              isMobile={isMobile || isTablet}
              src={images.photos_btn}
              onClick={
                () => {
                  if (isMobile || isTablet) {
                    navigate('/mobile-tutorial')
                  } else {
                    navigate('/prepare')
                  }
                }
                /*navigate('/choose-photos')*/
              }
            />
            <TermsAndConditionsInfoText variant='headingLight'>{t(keys.photosBtn)}</TermsAndConditionsInfoText>
            {/* <TermsAndConditionsInfoText variant='headingLight'>&nbsp;&nbsp;</TermsAndConditionsInfoText> 
            <Button variant='secondary' mb={2} onClick={() => navigate('/choose-photos')}>
              {t(keys.photosBtn)}
            </Button> */}
            {/* <Button variant='secondary' mb={2} onClick={() => navigate('/mobile-tutorial-self')}>
          {t(keys.photosSelfBtn)}
        </Button>   */}
            {/* {(state.shop.shopId?.startsWith('M')) 
          ? (null) 
          :(
            <> */}
            <TermsAndConditionsInfoText variant='headingLight'>
              {t(keys.accuracyText) + ': 96%'}
            </TermsAndConditionsInfoText>
            <ContainerRow>
              <MySpan>{t(keys.orText)}</MySpan>
            </ContainerRow>
            {/* <Button variant='secondary' mb={2} onClick={() => navigate('/survey')}>
              {t(keys.surveyBtn)}
            </Button> */}
            <ImageSurvey isMobile={isMobile || isTablet} src={images.survey_btn} onClick={() => navigate('/survey')} />
            <TermsAndConditionsInfoText variant='headingLight'>{t(keys.surveyBtn)}</TermsAndConditionsInfoText>

            <TermsAndConditionsInfoText variant='headingLight'>
              {t(keys.accuracyText) + ': 85%'}
            </TermsAndConditionsInfoText>
            {/* </>
           )
        } */}
          </ComponentsContainer>
        </PageContainer>
      )}
    </>
  )
}
const MySpan = styled.span`
  background: #fff;
  padding: 0 10px;
`

const ContainerRow = styled.div`
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 60px 10px 2px 10px;
  @media screen and (max-height: 700px) {
    margin: ${Math.ceil(window.innerHeight * 0.03) < 30
        ? '30'
        : Math.ceil(window.innerHeight * 0.03) > 60
        ? '60'
        : Math.ceil(window.innerHeight * 0.03)}px
      10px 2px 10px;
  }
`
//margin: 60px 20px 20px 20px; width: 90%;
const BigOrangeText = styled(Text)`
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 80%;
  line-height: ${theme.fontSizes[8] + 4}px;
  color: ${theme.colors.secondary};
  font-size: ${theme.fontSizes[8]}px;
  @media screen and (max-height: 600px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  ${isTablet ? 'margin-top: 15px; margin-bottom: 15px;' : ''}
`
const ContainerNotGood = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
`
// margin-bottom: ${Math.ceil(window.innerHeight * 0.03) < 10
//   ? '10'
//   : Math.ceil(window.innerHeight * 0.03) > 20
//   ? '20'
//   : Math.ceil(window.innerHeight * 0.03)}px;
const TermsAndConditionsInfoText = styled(Text)`
  margin-bottom: 5px;
  @media screen and (max-height: 700px) {
    font-size: ${Math.ceil(window.innerHeight * 0.021) < 8
      ? '8'
      : Math.ceil(window.innerHeight * 0.021) > 12
      ? '12'
      : Math.ceil(window.innerHeight * 0.021)}px;
  }
  @media screen and (max-height: 320px) {
    display: none;
  }
`
const ImagePhotos = styled.img<{ isMobile: boolean }>`
  width: ${isMobile ? '50' : '40'}%;
  @media screen and (max-height: 700px) {
    height: 14%;
  }
  @media screen and (max-height: 320px) {
    display: none;
  }
  margin-bottom: 10px;
  @media screen and (max-height: 700px) {
    margin-bottom: ${Math.ceil(window.innerHeight * 0.03) < 3
      ? '3'
      : Math.ceil(window.innerHeight * 0.03) > 8
      ? '8'
      : Math.ceil(window.innerHeight * 0.03)}px;
  }
  margin-top: 20px;
`
const ImageSurvey = styled.img<{ isMobile: boolean }>`
  width: ${isMobile ? '40' : '30'}%;
  @media screen and (max-height: 700px) {
    height: 14%;
  }
  @media screen and (max-height: 320px) {
    display: none;
  }
  margin-bottom: 10px;
  @media screen and (max-height: 700px) {
    margin-bottom: ${Math.ceil(window.innerHeight * 0.03) < 3
      ? '3'
      : Math.ceil(window.innerHeight * 0.03) > 8
      ? '8'
      : Math.ceil(window.innerHeight * 0.03)}px;
  }
  margin-top: 20px;
`
const PageContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100vw;
  text-align: center;
  align-items: center;
  padding-bottom: 50px;
  @media screen and (min-width: ${theme.breakpoints[0]}) {
    padding-bottom: 70px;
  }
  @media screen and (max-height: 700px) {
    padding-bottom: 30px;
  }
  ${isMobile
    ? '@media screen and (orientation: landscape) { html { transform: rotate(-90deg); transform-origin: left top; width: 100vh; overflow-x: hidden; position: absolute; top: 100%; left: 0; }}'
    : ''}
`
const ComponentsContainer = styled.div<{ isMobile: boolean }>`
  ${variableWidthStyle}
  padding-top: 20px;
  ${isMobile ? ' padding-top: 10px;' : ''}
  @media screen and (max-height: 700px) {
    padding-top: ${Math.ceil(window.innerHeight * 0.014) < 5
      ? '5'
      : Math.ceil(window.innerHeight * 0.014) > 20
      ? '20'
      : Math.ceil(window.innerHeight * 0.014)}px;
  }
`
