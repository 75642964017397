import React /*, { useContext }*/ from 'react'
import styled from 'styled-components'
import { Text } from 'rebass/styled-components'
import { theme } from 'app/assets/theme'

interface Props {
  isShown: boolean
  labelText: string
  labelLeft: string
  labelRight: string
  procent: number
}

export const PBar: React.FC<Props> = ({ isShown, labelText, labelLeft, labelRight, procent }) => {
  return (
    <div>
      <MyRow isShown={isShown}>
        <Text variant='profileLabel'>{labelText}</Text>
      </MyRow>
      <MyDiv isShown={isShown}>
        <PBarGreenRed>
          <RowContainer>
            <PositionedText>&nbsp;{labelLeft}</PositionedText>
            <PositionedText>{labelRight}&nbsp;</PositionedText>
          </RowContainer>
        </PBarGreenRed>
        <CaretDown procent={procent} />
        <MiddleLine />
      </MyDiv>
    </div>
  )
}

const MyDiv = styled.div<{ isShown: boolean }>`
  ${({ isShown }) => (isShown ? '' : 'display: none;')}
  position: relative;
  margin-bottom: 10px;
  @media screen and (max-height: 670px) {
    margin-bottom: 5px;
  }
`
const MyRow = styled.div<{ isShown: boolean }>`
  display: ${({ isShown }) => (isShown ? 'flex' : 'none')};
  margin-top: 10px;
  @media screen and (max-height: 670px) {
    margin-top: 5px;
  }
`
const PBarGreenRed = styled.div`
  position: relative;
  z-index: -3;
  padding: 1px;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 14px;
  border-radius: 5px;
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.25)),
    -webkit-linear-gradient(left, #ffd9dc 15%, #defee6 20% 80%, #ffd9dc 85%);
` /*
const PBarGreenOrangeRed = styled.div`
  position: relative;
  z-index: -3;
  padding: 1px;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 14px;
  border-radius: 5px;
  background-image: -webkit-linear-gradient(
      -45deg,
      transparent 33%,
      rgba(0, 0, 0, 0.1) 33%,
      rgba(0, 0, 0, 0.1) 66%,
      transparent 66%
    ),
    -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.25)),
    -webkit-linear-gradient(left, red 10%, orange 10% 20%, lightgreen 20% 80%, orange 80% 90%, red 90%);
`*/
const MiddleLine = styled.div`
  position: absolute;
  z-index: -2;
  margin-top: -28px;
  width: 2px;
  height: 20px;
  margin-left: 50%;
  left: 0;
  background-color: ${theme.colors.primary};
  opacity: 0.6;
  display: inline-block;
`
const CaretDown = styled.div<{ procent: number }>`
  position: absolute;
  z-index: -1;
  margin-top: -29px;
  width: 2px;
  height: 21px;
  margin-left: ${({ procent }) => procent}%;
  left: 0;
  background-color: ${({ procent }) => (procent < 20.0 || procent > 80.0 ? '#DB3030' : 'green')};
  opacity: 0.6;
  display: inline-block;
  &:before {
    border-bottom: 6px solid ${({ procent }) => (procent < 20.0 || procent > 80.0 ? '#DB3030' : 'green')};
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    content: '';
    height: 0;
    left: 0;
    position: absolute;
    top: 19px;
    width: 0;
    margin-left: -3px;
  }
  &:after {
    border-top: 6px solid ${({ procent }) => (procent < 20.0 || procent > 80.0 ? '#DB3030' : 'green')};
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    content: '';
    height: 0;
    left: 0;
    position: absolute;
    top: -3px;
    width: 0;
    margin-left: -3px;
  }
`
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-top: -3px;
  z-index: 1;
`
const PositionedText = styled.div`
  color: red;
  font-size: smaller;
`
