import React from 'react'
import ReactDOM from 'react-dom'
import { Amplify, I18n } from '@aws-amplify/core'
import { AWSIoTProvider } from '@aws-amplify/pubsub'

import { Root } from 'app/Root'
import translations from 'app/assets/translations.json'
import { getQueryStringValue } from '@shared-utils'
import * as serviceWorker from './serviceWorker'

Amplify.configure({
  Auth: {
    identityPoolId: 'eu-central-1:4595ad56-9f77-46dd-bc01-75cbb59b63b3',
    region: 'eu-central-1',
  },
})

Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: 'eu-central-1',
    aws_pubsub_endpoint: `wss://a3ssvuxh0f6zh-ats.iot.eu-central-1.amazonaws.com/mqtt`,
  })
)

const lang = getQueryStringValue('language') || getQueryStringValue('slang') || 'en'
I18n.putVocabularies(translations)
I18n.setLanguage(lang)

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
