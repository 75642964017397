import React from 'react'

export const InfoMobile = (props: any) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='22.765' height='23.869' viewBox='0 0 22.765 23.869' {...props}>
    <g id='Group_362' data-name='Group 362' transform='translate(-63.717 -486.916)'>
      <g id='iconfinder_user_information_1902263' transform='translate(-44.783 395.916)'>
        <circle
          id='Ellipse_28'
          data-name='Ellipse 28'
          cx='5.06'
          cy='5.06'
          r='5.06'
          transform='translate(111.62 91)'
          fill='#b0b0b0'
        />
        <path
          id='Path_2310'
          data-name='Path 2310'
          d='M124.555,201.243A2.935,2.935,0,0,0,121.9,199.6h-.243a5.844,5.844,0,0,1-9.967,0h-.243a2.952,2.952,0,0,0-2.945,2.945v6.239a2.952,2.952,0,0,0,2.945,2.945h6.338a7.773,7.773,0,0,1,6.772-10.485Z'
          transform='translate(0 -100.336)'
          fill='#b0b0b0'
        />
        <path
          id='Path_2311'
          data-name='Path 2311'
          d='M250.516,241.3a6.216,6.216,0,1,0,6.216,6.216A6.218,6.218,0,0,0,250.516,241.3Zm1.042,9.929h-2.077v-4.847h2.077Zm0-5.669h-2.077V243.8h2.077Z'
          transform='translate(-125.467 -138.864)'
          fill='#b0b0b0'
        />
      </g>
    </g>
  </svg>
)
