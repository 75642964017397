import React from 'react'
import { Router } from '@reach/router'

import {
  InfoScreen,
  TutorialScreen,
  StartScreen,
  UnkownError,
  CameraScreen,
  PrepareScreen,
  AnalyzingScreen,
  AnalysisErrorScreen,
  MeasurementProfileScreen,
  WaitingScreen,
  ContinueOnDesktop,
  PhotosOK,
  JeansMeasurements,
  JeansTutorial,
  MoreMeasurements,
  MoreMeasTutorial,
  ChoosePath,
  Survey,
} from 'app/screens'

export const AppRouter = () => (
  <Router>
    <StartScreen default />
    <ChoosePath path='/choose-path' />
    <Survey path='/survey' />
    <TutorialScreen path='/mobile-tutorial' />
    <InfoScreen path='/info' />
    <UnkownError path='/error' />
    <CameraScreen path='/camera' />
    <PrepareScreen path='/prepare' />
    <AnalyzingScreen path='/analyzing' />
    <PhotosOK path='/photos-ok' />
    <WaitingScreen path='//wait-for-mobile' />
    <AnalysisErrorScreen path='/analysis-error' />
    <ContinueOnDesktop path='/continue-on-desktop' />
    <MeasurementProfileScreen path='/measurement-profile' />
    <JeansMeasurements path='/jeans-measurements' />
    <JeansTutorial path='/jeans-tutorial' />
    <MoreMeasurements path='/more-measurements' />
    <MoreMeasTutorial path='/more-meas-tutorial' />
  </Router>
)
