import { clearDataOnAitServer } from 'app/services/api'
import { updateWidgetButton } from '@shared-utils'

interface UCDependencies {
  clearState: () => void
  clearCookie: () => void
  closeWidget: () => void
}

interface UCData {
  aitId?: string | null
  shopId?: string | null
  language?: string | null
  shopUserId?: string | null
  domainName?: string | null
  productType?: string | null
  fitId?: string | null
  jeansOption?: string | null
  moreMeasurements?: string | null
  fromB2B?: string | null
  shopProductLink?: string | null
  fromPhotosOK?: string | null
}

export const clearDataUC = (dependencis: UCDependencies) => async (data: UCData) => {
  const { clearState, clearCookie, closeWidget } = dependencis
  const response = await clearDataOnAitServer(data)
  if (response.ok) {
    const { button_text } = await response.json()
    updateWidgetButton(button_text, null)
    clearState()
    clearCookie()
    closeWidget()
  } else {
    console.error(`[AIT][MTM] error clearing data on AIT server: ${response.status}:${response.statusText}`)
  }
}
