import { userReducer } from 'app/domain/user/reducer'
import { shopReducer } from 'app/domain/shop/reducer'
import { AppReducer, AppState, AppAction } from 'app/domain/contracts'

export const reducer: AppReducer = (state: AppState, action: AppAction) => {
  switch (action.entity) {
    case 'user': {
      return userReducer(state, action)
    }
    case 'shop': {
      return shopReducer(state, action)
    }
    case 'app': {
      if (action.name === 'updatePubSubTopicName') {
        return {
          ...state,
          pubSubTopicName: action.payload,
        }
      }
      if (action.name === 'clearStateAction') {
        return {
          shop: {
            aitId: null,
            shopId: null,
            domainName: null,
            shopUserId: null,
            language: null,
            productType: null,
            fitId: null,
            shopProductLink: null,
            jeansOption: null,
            moreMeasurements: null,
            fromB2B: null,
            fromPhotosOK: null,
          },
          user: {
            name: '',
            surname: '',
            height: '',
            weight: '',
            age: '',
            thigh: '',
            calf: '',
            neck: '',
            chest: '',
            waist: '',
            basin: '',
            thighThreshold: '',
            calfThreshold: '',
            neckThreshold: '',
            chestThreshold: '',
            waistThreshold: '',
            basinThreshold: '',
            preferences: {
              measurementSystem: 'metric',
            },
            photos: {
              base64: { front: '', side: '' },
            },
            fitPreferences: {
              jacket: {
                fit: null,
                jacketLength: null,
                sleeveLength: null,
              },
              shirt: {
                fit: null,
                shirtLength: null,
                watchSide: null,
              },
              trousers: {
                fit: null,
                length: null,
              },
              isSaved: true,
            },
            sizeRec: {
              product_type: '',
              jacket: {
                size: '',
                waist: '',
                waistAccMin: '',
                waistAccMax: '',
                sleeveLength: '',
                sleeveLengthAccMin: '',
                sleeveLengthAccMax: '',
                length: '',
                lengthAccMin: '',
                lengthAccMax: '',
              },
              trousers: {
                size: '',
                waist: '',
                waistAccMin: '',
                waistAccMax: '',
                length: '',
                lengthAccMin: '',
                lengthAccMax: '',
              },
              shirt: {
                size: '',
                waist: '',
                waistAccMin: '',
                waistAccMax: '',
                neck: '',
                neckAccMin: '',
                neckAccMax: '',
                sleeveLength: '',
                sleeveLengthAccMin: '',
                sleeveLengthAccMax: '',
                length: '',
                lengthAccMin: '',
                lengthAccMax: '',
              },
            },
            surveyChoices: {
              chest: null,
              abdomen: null,
              hips: null,
            },
          },
          pubSubTopicName: '',
        }
      }
      return state
    }
    default: {
      return state
    }
  }
}
