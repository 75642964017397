import React, { useContext, useState, useEffect } from 'react'
import { isMobile, isIOS, isIOS13, isMobileSafari, isSafari, isTablet } from 'react-device-detect'
import { Button /*, Text*/ } from 'rebass/styled-components'

import styled from 'styled-components/macro'
import { RouteComponentProps, navigate } from '@reach/router'
import { PubSub } from '@aws-amplify/pubsub'
import { t, myIsIOS, myIsSafari } from '@shared-utils'
import { getQueryStringValue } from '@shared-utils'
import { theme } from 'app/assets/theme'
import { i18nKeys } from 'app/assets/18nKeys'
import { BackChevron, ForwardChevron } from 'app/assets/icons'
import { PhotoTutorial } from './components/PhotoTutorial'
import { ApplicationContext } from 'app/Context'
import { MeasurementSytemNames } from 'app/domain/user/contracts'

export const TutorialScreen: React.FC<RouteComponentProps> = () => {
  const {
    state,
    actions: { updateShop, updateUser, updatePubSubTopicName },
  } = useContext(ApplicationContext)

  const [mobileScreenIndex, setMobileScreenIndex] = useState(0)

  const changeSlide = (direction: number) => () =>
    setMobileScreenIndex(oldState => {
      const r = oldState + direction

      if (r < 0 || (r > 7 && state.shop.language !== 'fr') || (r > 9 && state.shop.language === 'fr')) {
        return oldState
      }

      return r
    })

  // const openInNewSafariWindow = () => {
  //   //window.open("safari-" + window.location.href, '_system')
  //   var a = document.createElement('a');
  //   a.setAttribute("href", "safari-" + window.location.href);
  //   a.setAttribute("target","_system");

  //   var dispatch = document.createEvent("HTMLEvents");
  //   dispatch.initEvent("click", true, true);
  //   a.dispatchEvent(dispatch);
  // }

  useEffect(() => {
    var element = document.getElementById('Top')
    if (element != null) {
      var parent = element.parentElement
      if (parent != null) {
        parent.style.height = '99%'
      }
    }
    // element = document.getElementById('root')
    // if (element != null) {
    //   element.style.height = '99%'
    // }

    //setTopic(getQueryStringValue('topic'))

    if ((getQueryStringValue('topic') || getQueryStringValue('fromB2B') === '2') && (isMobile || isTablet)) {
      if (!myIsIOS(isIOS, isIOS13) || (myIsIOS(isIOS, isIOS13) && myIsSafari(isSafari, isMobileSafari))) {
        if (getQueryStringValue('topic')) {
          updatePubSubTopicName(getQueryStringValue('topic'))
        }

        updateUser({
          name: getQueryStringValue('un') || getQueryStringValue('name'),
          surname: getQueryStringValue('us') || getQueryStringValue('surname'),
          height: getQueryStringValue('uh') || getQueryStringValue('height'),
          weight: getQueryStringValue('uw') || getQueryStringValue('weight'),
          age: getQueryStringValue('ua') || getQueryStringValue('age'),
          neck: getQueryStringValue('unk') || getQueryStringValue('neck'),
          chest: getQueryStringValue('uch') || getQueryStringValue('chest'),
          waist: getQueryStringValue('uwa') || getQueryStringValue('waist'),
          basin: getQueryStringValue('ub') || getQueryStringValue('basin'),
          thigh: getQueryStringValue('ut') || getQueryStringValue('thigh'),
          calf: getQueryStringValue('uc') || getQueryStringValue('calf'),
          preferences: {
            measurementSystem: getQueryStringValue('ums') as MeasurementSytemNames,
          },
        })

        updateShop({
          aitId: getQueryStringValue('aid') || getQueryStringValue('ait_id'),
          language: getQueryStringValue('slang') || getQueryStringValue('language') || 'en',
          productType: getQueryStringValue('spt') || getQueryStringValue('product_type'),
          shopId: getQueryStringValue('sid') || getQueryStringValue('shop_id'),
          shopUserId: getQueryStringValue('suid') || getQueryStringValue('shop_user_id'),
          shopProductLink: getQueryStringValue('spl') || getQueryStringValue('shop_product_link'),
          fitId: getQueryStringValue('fid') || getQueryStringValue('fit_id'),
          jeansOption: getQueryStringValue('jns') || getQueryStringValue('jeans_option'),
          moreMeasurements: getQueryStringValue('mm') || getQueryStringValue('more_measurements'),
          fromB2B: getQueryStringValue('fromB2B'),
        })

        if (getQueryStringValue('topic')) {
          PubSub.publish(getQueryStringValue('topic') as string, { action: 'wait-for-mobile' })
        }
      }
    }
  }, [updatePubSubTopicName, updateUser, updateShop])
  //<a href= {"safari-" + window.location.href}></a>
  return (
    <Container id='Top'>
      <Mobile>
        <PhotoContent>
          {mobileScreenIndex === 0 && <PhotoTutorial image_index={'1'} />}
          {mobileScreenIndex === 1 && <PhotoTutorial image_index={'2'} />}
          {mobileScreenIndex === 2 && <PhotoTutorial image_index={'3'} />}
          {mobileScreenIndex === 3 && <PhotoTutorial image_index={'4'} />}
          {mobileScreenIndex === 4 && <PhotoTutorial image_index={'5'} />}
          {mobileScreenIndex === 5 && <PhotoTutorial image_index={'6'} />}
          {mobileScreenIndex === 6 && <PhotoTutorial image_index={'7'} />}
          {mobileScreenIndex === 7 && <PhotoTutorial image_index={'8'} />}
          {mobileScreenIndex === 8 && <PhotoTutorial image_index={'9'} />}
          {mobileScreenIndex === 9 && <PhotoTutorial image_index={'10'} />}
        </PhotoContent>

        <MobileControlsDown>
          <RowJustified>
            {mobileScreenIndex === 0 ? (
              <div></div>
            ) : (
              <MyButton
                onClick={changeSlide(-1)}
                color={theme.colors.secondary}
                variant={'flat'}
                backgroundColor='transparent'
              >
                <BackChevron />
                &nbsp;{t(i18nKeys.back)}
              </MyButton>
            )}

            {(mobileScreenIndex === 7 && state.shop.language !== 'fr') ||
            (mobileScreenIndex === 9 && state.shop.language === 'fr') ? (
              <RowEnd>
                <MyButton
                  onClick={() => navigate('/prepare')}
                  color={theme.colors.secondary}
                  variant={'flat'}
                  backgroundColor='transparent'
                >
                  {t(i18nKeys.next)}&nbsp;
                  <ForwardChevron />
                </MyButton>
              </RowEnd>
            ) : (
              <RowEnd>
                <MyButton
                  onClick={changeSlide(1)}
                  color={theme.colors.secondary}
                  variant={'flat'}
                  backgroundColor='transparent'
                >
                  {t(i18nKeys.next)}&nbsp;
                  <ForwardChevron />
                </MyButton>
              </RowEnd>
            )}
          </RowJustified>
        </MobileControlsDown>
      </Mobile>
    </Container>
  )
}

const MyButton = styled(Button)`
  outline: none !important;
  outline-offset: none !important;
  padding-left: 35px;
  padding-right: 35px;
`

const RowEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`
const Container = styled.div``

const RowJustified = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const Mobile = styled.div``

const PhotoContent = styled.div`
  min-height: calc(${window.innerHeight}px - 50px);
`

const MobileControlsDown = styled.div`
  height: 50px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0px;
  padding-bottom: 30px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    padding-bottom: 15px;
  }
`
