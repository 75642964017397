import React from 'react'
import styled from 'styled-components'
import { Text } from 'rebass/styled-components'
import { RouteComponentProps } from '@reach/router'

import { Loading } from 'app/components/Loading'

import { t } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { theme } from 'app/assets/theme'

export const WaitingScreen: React.FC<RouteComponentProps> = () => {
  return (
    <PageContainer>
      <ComponentsContainer>
        <TitleText>{t(i18nKeys.desktopToMobile.waitingForMobileText)}</TitleText>
        <Loading />
      </ComponentsContainer>
    </PageContainer>
  )
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  text-align: center;
  align-items: center;
`

const ComponentsContainer = styled.div`
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  width: 50%;
  text-align: center;
  align-items: center;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    width: 70%;
    padding-top: 30px;
  }

  @media screen and (max-width: 580px) {
    width: 80%;
    padding-top: 20px;
  }
`

const TitleText = styled(Text)`
  font-weight: 800;
  line-height: 24px;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[5]}px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    font-weight: 600;
    font-size: ${theme.fontSizes[4]}px;
  }
`
