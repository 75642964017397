import React from 'react'

import { images } from 'app/assets/preferences'
import { TrousersPreferences } from 'app/domain/user/contracts'
import { OptionsRow, Option } from './OptionsRow'
import { i18nKeys } from 'app/assets/18nKeys'

interface Props {
  preferences: TrousersPreferences
  onChange: (trousers: TrousersPreferences) => void
}

const {
  trousers: { length, fit },
} = images

export const TrousersPanel: React.FC<Props> = ({ preferences, onChange }: Props) => {
  const trouserbreak: Option[] = ['nobreak', 'slightbreak']
  const trouserfit: Option[] = ['skinny', 'semislim', 'regular']
  return (
    <div>
      <OptionsRow
        titleKey={i18nKeys.fitPreferences.trousersFit}
        height={120}
        images={fit}
        category='fit'
        options={trouserfit}
        onChange={onChange}
        preferences={preferences}
      />
      <OptionsRow
        titleKey={i18nKeys.fitPreferences.chooseTrousersLength}
        height={80}
        images={length}
        category='length'
        options={trouserbreak}
        onChange={onChange}
        preferences={preferences}
      />
    </div>
  )
}
