import tutorial_1 from './tutorial_1.png'
import tutorial_2 from './tutorial_2.png'
import tutorial_3 from './tutorial_3.png'
import tutorial_4 from './tutorial_4.png'
import tutorial_5 from './tutorial_5.png'
import tutorial_6 from './tutorial_6.png'
import tutorial_7 from './tutorial_7.png'
import tutorial_8 from './tutorial_8.png'
import tutorial_1_fr from './tutorial_1_fr.png'
import tutorial_2_fr from './tutorial_2_fr.png'
import tutorial_3_fr from './tutorial_3_fr.png'
import tutorial_4_fr from './tutorial_4_fr.png'
import tutorial_5_fr from './tutorial_5_fr.png'
import tutorial_6_fr from './tutorial_6_fr.png'
import tutorial_7_fr from './tutorial_7_fr.png'
import tutorial_8_fr from './tutorial_8_fr.png'
import tutorial_9_fr from './tutorial_9_fr.png'
import tutorial_10_fr from './tutorial_10_fr.png'
import tutorial_1_ro from './tutorial_1_ro.png'
import tutorial_2_ro from './tutorial_2_ro.png'
import tutorial_3_ro from './tutorial_3_ro.png'
import tutorial_4_ro from './tutorial_4_ro.png'
import tutorial_5_ro from './tutorial_5_ro.png'
import tutorial_6_ro from './tutorial_6_ro.png'
import tutorial_7_ro from './tutorial_7_ro.png'
import tutorial_8_ro from './tutorial_8_ro.png'

export const images = {
  tutorial_1,
  tutorial_2,
  tutorial_3,
  tutorial_4,
  tutorial_5,
  tutorial_6,
  tutorial_7,
  tutorial_8,
  tutorial_1_fr,
  tutorial_2_fr,
  tutorial_3_fr,
  tutorial_4_fr,
  tutorial_5_fr,
  tutorial_6_fr,
  tutorial_7_fr,
  tutorial_8_fr,
  tutorial_9_fr,
  tutorial_10_fr,
  tutorial_1_ro,
  tutorial_2_ro,
  tutorial_3_ro,
  tutorial_4_ro,
  tutorial_5_ro,
  tutorial_6_ro,
  tutorial_7_ro,
  tutorial_8_ro,
}
