import chestFlat from './shoulders_narrow.png'
import chestRegular from './shoulders_regular.png'
import chestProeminent from './shoulders_wide.png'
import abdomenFlat from './abdomen_flat.png'
import abdomenRegular from './abdomen_regular.png'
import abdomenProeminent from './abdomen_round.png'
import hipsNarrow from './hips_narrow.png'
import hipsRegular from './hips_regular.png'
import hipsWide from './hips_wide.png'
import photosBtn from './photos_btn.png'
import surveyBtn from './survey_btn.png'

export const images = {
  chest: {
    flat: chestFlat,
    regular: chestRegular,
    proeminent: chestProeminent,
  },
  abdomen: {
    flat: abdomenFlat,
    regular: abdomenRegular,
    proeminent: abdomenProeminent,
  },
  hips: {
    narrow: hipsNarrow,
    regular: hipsRegular,
    wide: hipsWide,
  },
  photos_btn: photosBtn,
  survey_btn: surveyBtn,
}
