import React, { useRef, useState } from 'react'

import styled from 'styled-components'
import { Image, Text } from 'rebass/styled-components'

import { t } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { images } from 'app/assets/analyzing'
import { toBase64ImageSrc } from '@shared-utils'
import { Direction } from 'app/domain/contracts'
import { FileInput } from 'app/components/FileInput'
import { theme } from 'app/assets/theme'

interface Props {
  imageBase64: string
  fileNamePrefix: string
  direction: Direction
  onPhotoSelected: (base64Data: string) => void
}

export const ComputerPhoto: React.FC<Props> = ({ imageBase64, direction, fileNamePrefix, onPhotoSelected }) => {
  const photoFile = useRef<HTMLInputElement>(null)
  const [invalidPhoto, setInvalidPhoto] = useState(false)

  return (
    <Container onClick={() => photoFile.current!.click()}>
      {invalidPhoto ? (
        <ValidationText>{t(i18nKeys.prep.invalidPhoto)}</ValidationText>
      ) : (
        <ValidationText>&nbsp;</ValidationText>
      )}
      <Photo direction={direction} src={imageBase64 ? toBase64ImageSrc(imageBase64) : undefined} />
      {invalidPhoto ? <CameraIcon src={images.retakePhotoCamera} /> : null}
      <OrientationText>{t(i18nKeys[direction])}</OrientationText>
      <FileInput
        id={`file-${direction}`}
        style={{ display: 'none' }}
        setPhoto={(filename, rawPhoto) => {
          if (filename.startsWith(fileNamePrefix)) {
            setInvalidPhoto(false)
            onPhotoSelected(rawPhoto)
          } else {
            onPhotoSelected('')
            setInvalidPhoto(true)
          }
        }}
        photoFileRef={photoFile!}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
  cursor: pointer;
`

const ValidationText = styled(Text)`
  font-weight: 600;
  color: ${theme.colors.secondary};
  font-size: ${theme.fontSizes[1]}px;
`

const Photo = styled(Image)<{ direction: string }>`
  height: 130px;
`
//width: 130px; border: 1px solid ${theme.colors.lightGray};
const CameraIcon = styled(Image)`
  position: absolute;
  max-width: 30%;
  left: 50%;
  top: 42%;
  transform: translate(-50%, -50%);
`

const OrientationText = styled(Text)`
  font-weight: 400;
  margin-top: 8px;
  font-size: ${theme.fontSizes[2]}px;
  color: ${theme.colors.primary}px;
`
