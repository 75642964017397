import React, { useEffect, useRef, useContext, useState } from 'react'
//import SlideRuler from 'slide-ruler'
import styled, { ThemeContext } from 'styled-components'
import { /*Heading,*/ Button /*, Text*/ } from 'rebass/styled-components'
import Picker from 'react-mobile-picker'

import { t } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { MeasurementSytemNames } from 'app/domain/user/contracts'
import { variableWidthStyle } from '../style'
import { validations } from '../constants'
import { isTablet, isMobile } from 'react-device-detect'
import { theme } from 'app/assets/theme'

interface Props {
  weightValue: number
  heightValue: number
  ageValue: number
  calfValue: number
  thighValue: number
  neckValue: number
  chestValue: number
  waistValue: number
  basinValue: number
  calfThreshold: number | null
  thighThreshold: number | null
  neckThreshold: number | null
  chestThreshold: number | null
  waistThreshold: number | null
  basinThreshold: number | null
  attribute: 'height' | 'weight' | 'age' | 'calf' | 'thigh' | 'neck' | 'chest' | 'waist' | 'basin'
  measurementSystem: MeasurementSytemNames
  onCancel: () => void
  onConfirm: (value: number) => void
}

export const Slider: React.FC<Props> = ({
  onCancel,
  onConfirm,
  attribute,
  measurementSystem,
  weightValue,
  heightValue,
  ageValue,
  calfValue,
  thighValue,
  neckValue,
  chestValue,
  waistValue,
  basinValue,
  calfThreshold,
  thighThreshold,
  neckThreshold,
  chestThreshold,
  waistThreshold,
  basinThreshold,
}: Props) => {
  const { initialValue, minValue, maxValue, unit, precision } = transformProps({
    attribute,
    measurementSystem,
    weightValue,
    heightValue,
    ageValue,
    calfValue,
    thighValue,
    neckValue,
    chestValue,
    waistValue,
    basinValue,
    calfThreshold,
    thighThreshold,
    neckThreshold,
    chestThreshold,
    waistThreshold,
    basinThreshold,
  })
  const ref = useRef<HTMLDivElement>(null)
  const theme = useContext(ThemeContext)

  const [optionGroups, setOptionGroups] = useState({ valoare: [initialValue + ' ' + unit] })

  const [valueGroups, setValueGroups] = useState({ valoare: initialValue + ' ' + unit })
  const [selectedValue, setSelectedValue] = useState(initialValue)

  useEffect(() => {
    const el = ref.current
    var vals = []
    for (var i = minValue; i <= maxValue; i += precision ?? 1) {
      vals.push(i + ' ' + unit)
    }
    setOptionGroups({ valoare: vals })
    setValueGroups({ valoare: initialValue + ' ' + unit })
    setSelectedValue(initialValue)
    // new SlideRuler({
    //   el,
    //   maxValue,
    //   minValue,
    //   currentValue: initialValue,
    //   handleValue: setSelectedValue,
    //   precision: precision ?? 1,
    //   divide: 15,
    //   fontColor: theme.colors.lightGray,
    //   fontSize: 11,
    //   fontMarginTop: 66,
    //   colorDigit: '#ededed',
    //   heightDigit: 50,
    //   colorDecimal: theme.colors.primary,
    //   heightDecimal: 55,
    //   lineWidth: 1,
    //   canvasHeight: 75,
    // })
    return () => {
      if (el) el.textContent = ''
    }
  }, [theme.colors, initialValue, minValue, maxValue, unit, precision])

  const attr = t(
    attribute === 'height'
      ? i18nKeys.start.sliderHeight
      : attribute === 'weight'
      ? i18nKeys.start.sliderWeight
      : attribute === 'age'
      ? i18nKeys.start.sliderAge
      : attribute === 'calf'
      ? i18nKeys.start.sliderCalf
      : attribute === 'thigh'
      ? i18nKeys.start.sliderThigh
      : attribute === 'neck'
      ? i18nKeys.start.sliderNeck
      : attribute === 'chest'
      ? i18nKeys.start.sliderChest
      : attribute === 'waist'
      ? i18nKeys.start.sliderWaist
      : i18nKeys.start.sliderBasin
  )
  return (
    <>
      <Container>
        <TextExplain>{t(i18nKeys.start.moveSlider, { attr })}</TextExplain>
        <TextRed>{t(i18nKeys.start.accurateData)}</TextRed>
        {/* <Label>
          {selectedValue}
          {unit}
        </Label> */}
      </Container>
      {/* <SlideRulerContainer ref={ref}></SlideRulerContainer>       */}
      <Picker
        height={
          Math.ceil(window.innerHeight * 0.188) < 90
            ? 90
            : Math.ceil(window.innerHeight * 0.188) > 180
            ? 180
            : Math.ceil(window.innerHeight * 0.188)
        }
        optionGroups={optionGroups}
        valueGroups={valueGroups}
        onChange={(name: any, value: any) => {
          setSelectedValue(value.replace(' ' + unit, ''))
          setValueGroups({ valoare: value })
        }}
      />
      <Actions>
        <Button variant='outline' onClick={onCancel}>
          {t(i18nKeys.start.cancel)}
        </Button>
        <Button ml={4} onClick={() => onConfirm(selectedValue)}>
          {t(i18nKeys.start.confirm)}
        </Button>
      </Actions>
    </>
  )
}

const transformProps = ({
  attribute,
  measurementSystem,
  weightValue,
  heightValue,
  ageValue,
  calfValue,
  thighValue,
  neckValue,
  chestValue,
  waistValue,
  basinValue,
  calfThreshold,
  thighThreshold,
  neckThreshold,
  chestThreshold,
  waistThreshold,
  basinThreshold,
}: any) => {
  if (measurementSystem === 'metric') {
    if (attribute === 'height') {
      return {
        initialValue: heightValue || 170,
        minValue: validations['metric']['height'].min,
        maxValue: validations['metric']['height'].max,
        unit: 'cm',
      }
    }
    if (attribute === 'weight') {
      return {
        initialValue: weightValue || 70,
        minValue: validations['metric']['weight'].min,
        maxValue: validations['metric']['weight'].max,
        unit: 'kg',
      }
    }
    if (attribute === 'age') {
      return {
        initialValue: ageValue || 45,
        minValue: validations['metric']['age'].min,
        maxValue: validations['metric']['age'].max,
        unit: t(i18nKeys.measurementProfile.years),
      }
    }
    if (attribute === 'calf') {
      return {
        initialValue: calfValue || (calfThreshold === null ? 35 : Number.parseFloat(calfThreshold)),
        precision: 0.5,
        minValue: calfThreshold === null ? validations['metric']['calf'].min : Number.parseFloat(calfThreshold) - 10,
        maxValue: calfThreshold === null ? validations['metric']['calf'].max : Number.parseFloat(calfThreshold) + 10,
        unit: 'cm',
      }
    }

    if (attribute === 'thigh') {
      return {
        initialValue: thighValue || (thighThreshold === null ? 54 : Number.parseFloat(thighThreshold)),
        precision: 0.5,
        minValue: thighThreshold === null ? validations['metric']['thigh'].min : Number.parseFloat(thighThreshold) - 10,
        maxValue: thighThreshold === null ? validations['metric']['thigh'].max : Number.parseFloat(thighThreshold) + 10,
        unit: 'cm',
      }
    }

    if (attribute === 'neck') {
      return {
        initialValue: neckValue || (neckThreshold === null ? 35 : Number.parseFloat(neckThreshold)),
        precision: 0.5,
        minValue: neckThreshold === null ? validations['metric']['neck'].min : Number.parseFloat(neckThreshold) - 10,
        maxValue: neckThreshold === null ? validations['metric']['neck'].max : Number.parseFloat(neckThreshold) + 10,
        unit: 'cm',
      }
    }

    if (attribute === 'chest') {
      return {
        initialValue: chestValue || (chestThreshold === null ? 35 : Number.parseFloat(chestThreshold)),
        precision: 1,
        minValue: chestThreshold === null ? validations['metric']['chest'].min : Number.parseFloat(chestThreshold) - 30,
        maxValue: chestThreshold === null ? validations['metric']['chest'].max : Number.parseFloat(chestThreshold) + 30,
        unit: 'cm',
      }
    }

    if (attribute === 'waist') {
      return {
        initialValue: waistValue || (waistThreshold === null ? 35 : Number.parseFloat(waistThreshold)),
        precision: 1,
        minValue: waistThreshold === null ? validations['metric']['waist'].min : Number.parseFloat(waistThreshold) - 30,
        maxValue: waistThreshold === null ? validations['metric']['waist'].max : Number.parseFloat(waistThreshold) + 30,
        unit: 'cm',
      }
    }

    return {
      initialValue: basinValue || (basinThreshold === null ? 54 : Number.parseFloat(basinThreshold)),
      precision: 1,
      minValue: basinThreshold === null ? validations['metric']['basin'].min : Number.parseFloat(basinThreshold) - 30,
      maxValue: basinThreshold === null ? validations['metric']['basin'].max : Number.parseFloat(basinThreshold) + 30,
      unit: 'cm',
    }
  }
  if (attribute === 'height') {
    return {
      initialValue: heightValue || 67,
      minValue: validations['imperial']['height'].min,
      maxValue: validations['imperial']['height'].max,
      unit: 'inch',
    }
  }
  if (attribute === 'weight') {
    return {
      initialValue: weightValue || 150,
      minValue: validations['imperial']['weight'].min,
      maxValue: validations['imperial']['weight'].max,
      unit: 'lbs',
    }
  }
  if (attribute === 'age') {
    return {
      initialValue: ageValue || 45,
      minValue: validations['imperial']['age'].min,
      maxValue: validations['imperial']['age'].max,
      unit: t(i18nKeys.measurementProfile.years),
    }
  }
  if (attribute === 'calf') {
    return {
      initialValue: calfValue || (calfThreshold === null ? 15 : Number.parseFloat(calfThreshold)),
      precision: 0.2,
      minValue: calfThreshold === null ? validations['imperial']['calf'].min : Number.parseFloat(calfThreshold) - 4,
      maxValue: calfThreshold === null ? validations['imperial']['calf'].max : Number.parseFloat(calfThreshold) + 4,
      unit: 'inch',
    }
  }

  if (attribute === 'thigh') {
    return {
      initialValue: thighValue || (thighThreshold === null ? 25 : Number.parseFloat(thighThreshold)),
      precision: 0.2,
      minValue: thighThreshold === null ? validations['imperial']['thigh'].min : Number.parseFloat(thighThreshold) - 4,
      maxValue: thighThreshold === null ? validations['imperial']['thigh'].max : Number.parseFloat(thighThreshold) + 4,
      unit: 'inch',
    }
  }

  if (attribute === 'neck') {
    return {
      initialValue: neckValue || (neckThreshold === null ? 15 : Number.parseFloat(neckThreshold)),
      precision: 0.2,
      minValue: neckThreshold === null ? validations['imperial']['neck'].min : Number.parseFloat(neckThreshold) - 4,
      maxValue: neckThreshold === null ? validations['imperial']['neck'].max : Number.parseFloat(neckThreshold) + 4,
      unit: 'inch',
    }
  }

  if (attribute === 'chest') {
    return {
      initialValue: chestValue || (chestThreshold === null ? 25 : Number.parseFloat(chestThreshold)),
      precision: 1,
      minValue: chestThreshold === null ? validations['imperial']['chest'].min : Number.parseFloat(chestThreshold) - 4,
      maxValue: chestThreshold === null ? validations['imperial']['chest'].max : Number.parseFloat(chestThreshold) + 4,
      unit: 'inch',
    }
  }

  if (attribute === 'waist') {
    return {
      initialValue: waistValue || (waistThreshold === null ? 25 : Number.parseFloat(waistThreshold)),
      precision: 1,
      minValue: waistThreshold === null ? validations['imperial']['waist'].min : Number.parseFloat(waistThreshold) - 4,
      maxValue: waistThreshold === null ? validations['imperial']['waist'].max : Number.parseFloat(waistThreshold) + 4,
      unit: 'inch',
    }
  }

  return {
    initialValue: basinValue || (basinThreshold === null ? 25 : Number.parseFloat(basinThreshold)),
    precision: 1,
    minValue: basinThreshold === null ? validations['imperial']['basin'].min : Number.parseFloat(basinThreshold) - 4,
    maxValue: basinThreshold === null ? validations['imperial']['basin'].max : Number.parseFloat(basinThreshold) + 4,
    unit: 'inch',
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  ${isTablet || isMobile ? ' margin-top: 2px;' : ''}
  @media screen and (max-height: 700px) {
    margin-top: ${Math.ceil(window.innerHeight * 0.001) < 1
      ? '0'
      : Math.ceil(window.innerHeight * 0.001) > 8
      ? '8'
      : Math.ceil(window.innerHeight * 0.001)}px;
  }
  ${variableWidthStyle}
`

const TextExplain = styled.div`
  font-size: ${theme.fontSizes[1]}px;
  margin-bottom: 0px;
  font-weight: 500;
  @media screen and (max-height: 700px) {
    margin-top: ${Math.ceil(window.innerHeight * 0.007) < 1
      ? '1'
      : Math.ceil(window.innerHeight * 0.007) > 15
      ? '15'
      : Math.ceil(window.innerHeight * 0.007)}px;
  }
`
const TextRed = styled.div`
  color: red;
  font-size: ${theme.fontSizes[3]}px;
  font-style: italic;
  margin-top: 0px;
  margin-bottom: 5px;
  font-weight: 600;
  @media screen and (max-height: 700px) {
    margin-bottom: ${Math.ceil(window.innerHeight * 0.007) < 2
      ? '2'
      : Math.ceil(window.innerHeight * 0.007) > 5
      ? '5'
      : Math.ceil(window.innerHeight * 0.007)}px;
    font-size: ${Math.ceil(window.innerHeight * 0.021) < 10
      ? '10'
      : Math.ceil(window.innerHeight * 0.021) > 15
      ? '15'
      : Math.ceil(window.innerHeight * 0.021)}px;
  }
`
// const SlideRulerContainer = styled.div`
//   & .slide-ruler_box_1Le:before {
//     content: '';
//     width: 0;
//     height: 0;
//     top: 20px;
//     left: 50%;
//     position: absolute;
//     background-color: #ffffff;
//     border-left: 6px solid transparent;
//     border-right: 6px solid transparent;
//     border-bottom: 6px solid ${theme.colors.secondary};
//     @media screen and (max-width: ${theme.breakpoints[0]}) {
//       margin-top: 5px;
//     }
//     ${isTablet || isMobile ? ' margin-top: 5px;' : ''}
//   }
// `

const Actions = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  ${variableWidthStyle}
  ${isTablet || isMobile ? ' margin-top: 5px;' : ''}
  @media screen and (max-height: 700px) {
    margin-top: ${Math.ceil(window.innerHeight * 0.007) < 2
      ? '2'
      : Math.ceil(window.innerHeight * 0.007) > 10
      ? '10'
      : Math.ceil(window.innerHeight * 0.007)}px;
  }
`
