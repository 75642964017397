import React from 'react'
import styled from 'styled-components'
import { Text } from 'rebass/styled-components'

import { t } from '@shared-utils'
import { i18nKeys } from 'app/assets/18nKeys'
import { images } from 'app/assets/info'
import { isTablet } from 'react-device-detect'
import { theme } from 'app/assets/theme'
interface Props {
  type: 'phoneLevel' | 'cleanBackground' | 'fittingClothes' | 'showNeck' | 'poseUnchanged'
}

export const Instruction: React.FC<Props> = ({ type }: Props) => {
  return (
    <Container>
      <div>
        <Image src={images[type]} />
      </div>
      <TextBlock>
        <Text variant='body'>{t(i18nKeys.info[type].title)}</Text>
        <Text variant='bodyLight'>{t(i18nKeys.info[type].desc)}</Text>
      </TextBlock>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  margin-bottom: 36px;
  margin-right: 8px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    margin-bottom: 46px;
  }
  ${isTablet ? ' margin-bottom: 46px;' : ''}
`

const Image = styled.img`
  margin-top: 8px;
  width: 30px;
`

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`
