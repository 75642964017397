import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Text, Button } from 'rebass/styled-components'
import { isMobile, isIOS, isIOS13, isMobileSafari, isSafari, isTablet } from 'react-device-detect'

import { RouteComponentProps, navigate } from '@reach/router'
import { t, myIsIOS, myIsSafari } from '@shared-utils'
import { Input } from '@rebass/forms/styled-components'

import { i18nKeys } from 'app/assets/18nKeys'
import { ApplicationContext } from 'app/Context'
import { getDataAndUpdateStore } from 'app/services/api'
import { useUpdateDataFromApi } from 'app/hooks'
import { Slider } from './components/Slider'
import { variableWidthStyle } from './style'
import { TermsAndConditions } from './components/TermsAndConditions'
import { validations } from './constants'
import { images } from 'app/assets/start'
import { theme } from 'app/assets/theme'
//import { createNoSubstitutionTemplateLiteral } from 'typescript'

//for package.json -> set HTTPS=true&& <- before craco start, for https debugging -- do not delete

const keys = i18nKeys.start

export const StartScreen: React.FC<RouteComponentProps> = () => {
  useUpdateDataFromApi()

  const {
    state: {
      shop,
      user: {
        name,
        surname,
        weight,
        height,
        age,
        preferences: { measurementSystem },
      },
    },
    actions,
    actions: { updateUser, updateShop },
  } = useContext(ApplicationContext)

  const [profileId, setProfileId] = useState('')
  const [isProfileIdInvalid, setProfileIdInvalid] = useState(false)
  const [acceptedTAndC, setTAndC] = useState(false)
  const [isFirstTime, setIsFirstTime] = useState(true)
  const [sliderShown, setSliderShown] = useState<'height' | 'weight' | 'age' | null>(null)
  const [warningVisible, setWarningVisible] = useState(false)
  const [isProfileIdVisible, setIsProfileIdVisible] = useState(false)

  const getProfileById = async () => {
    //console.log('getProfileById', shop)
    const result = await getDataAndUpdateStore(
      { ...shop, aitId: profileId, source: 'start' },
      //shop?.jeansOption,
      actions
    )
    if (result?.isInvalidProfileId) {
      setProfileIdInvalid(true)
      alert(t(keys.profileIdInvalid))
      return
    }
    updateShop({ aitId: profileId })
    //console.log(profileId, shop.aitId)
    navigate('/measurement-profile')
  }

  const isValid = (value: string, attribute: 'name' | 'surname' | 'weight' | 'height' | 'age') => {
    if (!value) return false
    if (attribute === 'name' || attribute === 'surname') {
      return value.length <= 20
    }
    try {
      var intVal = Number.parseInt(value, 10)
      return (
        value.toString().length <= 3 &&
        intVal.toString() === value.toString() &&
        intVal >= validations[measurementSystem][attribute].min &&
        intVal <= validations[measurementSystem][attribute].max
      )
    } catch {
      return false
    }
  }

  useEffect(() => {
    var element = document.getElementById('Top')
    if (element != null) {
      var parent = element.parentElement
      if (parent != null) {
        parent.style.height = '99%'
      }
    }
  }, [])

  return (
    <>
      {myIsIOS(isIOS, isIOS13) && !myIsSafari(isSafari, isMobileSafari) ? (
        <ContainerNotGood id='Top'>
          <BigOrangeText>{t(i18nKeys.notSafariOniOSNoQR)}</BigOrangeText>
        </ContainerNotGood>
      ) : (
        <PageContainer id='Top' isMobile={isMobile || isTablet}>
          <ComponentsContainer>
            <Image isMobile={isMobile || isTablet} src={images.logoMTM} />
            {/* <label>jeansOption: {shop.jeansOption} / {shop.shopId}</label> */}
            {/*<InstructionsText variant='body'>{t(keys.subtitle)}</InstructionsText> */}
            {/* <MeasurementSystem /> */}
            {!shop.shopId?.startsWith('SOXX') ? (
              <>
                <ProfileInput
                  name='name'
                  isValid={isValid(name, 'name') || isFirstTime}
                  placeholder={t(isValid(name, 'name') || isFirstTime ? keys.name : keys.nameRequired)}
                  value={name || ''}
                  onChange={e =>
                    updateUser({
                      name: e.target.value,
                    })
                  }
                  onFocus={({ target }) => {
                    setWarningVisible(false)
                    setSliderShown(null)
                  }}
                  type='text'
                  maxLength={20}
                  isVisible={true}
                />
                <ProfileInput
                  name='surname'
                  isValid={isValid(surname, 'surname') || isFirstTime}
                  placeholder={t(isValid(surname, 'surname') || isFirstTime ? keys.surname : keys.surnameRequired)}
                  value={surname || ''}
                  onChange={e =>
                    updateUser({
                      surname: e.target.value,
                    })
                  }
                  onFocus={({ target }) => {
                    setWarningVisible(false)
                    setSliderShown(null)
                  }}
                  type='text'
                  maxLength={20}
                  isVisible={true}
                />
              </>
            ) : null}
            <ProfileInput
              name='height'
              isValid={isValid(height, 'height') || isFirstTime}
              placeholder={t(isValid(height, 'height') || isFirstTime ? keys.height : keys.heightRequired)}
              value={height || ''}
              autoComplete='off'
              type='number'
              maxLength={3}
              min={validations[measurementSystem]['height'].min}
              max={validations[measurementSystem]['height'].max}
              onChange={e => updateUser({ height: e.target.value })}
              onFocus={({ target }) => {
                if (isMobile || isTablet) {
                  target.blur()
                  setSliderShown('height')
                } else {
                  setWarningVisible(true)
                }
              }}
              isVisible={true}
            />
            <ProfileInput
              name='weight'
              isValid={isValid(weight, 'weight') || isFirstTime}
              placeholder={t(isValid(weight, 'weight') || isFirstTime ? keys.weight : keys.weightRequired)}
              value={weight || ''}
              autoComplete='off'
              type='number'
              maxLength={3}
              min={validations[measurementSystem]['weight'].min}
              max={validations[measurementSystem]['weight'].max}
              onChange={e => updateUser({ weight: e.target.value })}
              onFocus={({ target }) => {
                if (isMobile || isTablet) {
                  target.blur()
                  setSliderShown('weight')
                } else {
                  setWarningVisible(true)
                }
              }}
              isVisible={true}
            />
            <ProfileInput
              name='age'
              isValid={isValid(age, 'age') || isFirstTime}
              placeholder={t(isValid(age, 'age') || isFirstTime ? keys.age : keys.ageRequired)}
              value={age || ''}
              autoComplete='off'
              type='number'
              maxLength={3}
              min={validations[measurementSystem]['age'].min}
              max={validations[measurementSystem]['age'].max}
              onChange={e => updateUser({ age: e.target.value })}
              onFocus={({ target }) => {
                if (isMobile || isTablet) {
                  target.blur()
                  setSliderShown('age')
                } else {
                  setWarningVisible(true)
                }
              }}
              isVisible={true}
            />
            <TextRed isVisible={warningVisible}>{t(i18nKeys.start.accurateData)}</TextRed>
            {sliderShown ? null : (
              <>
                <ProfileSwitch variant='body' isVisible={false}>
                  {t(keys.or)}
                </ProfileSwitch>
                <ProfileIdLinkText
                  variant='body'
                  isVisible={!isProfileIdVisible}
                  onClick={() => setIsProfileIdVisible(true)}
                >
                  <u>{t(i18nKeys.start.retakeProfile)}</u>
                </ProfileIdLinkText>
                <ProfileInput
                  name='profileId'
                  isValid={!isProfileIdInvalid}
                  placeholder={t(keys.profileId)}
                  value={profileId}
                  onChange={({ target }) => setProfileId(target.value)}
                  isVisible={isProfileIdVisible}
                  onFocus={({ target }) => {
                    setWarningVisible(false)
                  }}
                />
                <TermsAndConditions onValueChanged={() => setTAndC(state => !state)} accepted={acceptedTAndC} />
                <Button
                  disabled={!acceptedTAndC}
                  onClick={() => {
                    if (profileId) {
                      return getProfileById()
                    }
                    setIsFirstTime(false)
                    if (
                      (shop.shopId?.startsWith('SO') || (isValid(name, 'name') && isValid(surname, 'surname'))) &&
                      isValid(height, 'height') &&
                      isValid(weight, 'weight') &&
                      isValid(age, 'age')
                    ) {
                      if (shop.shopId?.startsWith('SO')) {
                        //Size-rec
                        navigate('/choose-path')
                      } //mtm
                      else {
                        navigate(isMobile || isTablet ? '/mobile-tutorial' : '/prepare')
                      }
                    } else {
                      let error = 'Invalid fields:'
                      if (!shop.shopId?.startsWith('SO') && !isValid(name, 'name')) error += ` name=${name}`
                      if (!shop.shopId?.startsWith('SO') && !isValid(surname, 'surname')) error += ` surname=${surname}`
                      if (!isValid(height, 'height')) error += ` height=${height}`
                      if (!isValid(weight, 'weight')) error += ` weight=${weight}`
                      if (!isValid(age, 'age')) error += ` age=${age}`
                      console.error(error)
                    }
                  }}
                >
                  {t(i18nKeys.next)}
                </Button>
              </>
            )}
          </ComponentsContainer>
          {sliderShown ? (
            <Slider
              weightValue={+weight}
              heightValue={+height}
              ageValue={+age}
              calfValue={+'0'}
              thighValue={+'0'}
              neckValue={+'0'}
              chestValue={+'0'}
              waistValue={+'0'}
              basinValue={+'0'}
              calfThreshold={null}
              thighThreshold={null}
              neckThreshold={null}
              chestThreshold={null}
              waistThreshold={null}
              basinThreshold={null}
              attribute={sliderShown}
              measurementSystem={measurementSystem}
              onCancel={() => setSliderShown(null)}
              onConfirm={value => {
                updateUser({ [sliderShown]: value })
                setSliderShown(null)
              }}
            />
          ) : null}
        </PageContainer>
      )}
    </>
  )
}
//max-height: 100%;
const BigOrangeText = styled(Text)`
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 80%;
  line-height: ${theme.fontSizes[8] + 4}px;
  color: ${theme.colors.secondary};
  font-size: ${theme.fontSizes[8]}px;
  @media screen and (max-height: 600px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`
const Image = styled.img<{ isMobile: boolean }>`
  width: ${isMobile ? '50' : '40'}%;
  @media screen and (max-height: 700px) {
    height: 14%;
  }
  @media screen and (max-height: 320px) {
    display: none;
  }
`
const PageContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100vw;
  text-align: center;
  align-items: center;
  padding-bottom: 50px;
  @media screen and (min-width: ${theme.breakpoints[0]}) {
    padding-bottom: 70px;
  }
  @media screen and (max-height: 700px) {
    padding-bottom: 30px;
  }
  ${isMobile
    ? '@media screen and (orientation: landscape) { html { transform: rotate(-90deg); transform-origin: left top; width: 100vh; overflow-x: hidden; position: absolute; top: 100%; left: 0; }}'
    : ''}
`
const ComponentsContainer = styled.div`
  ${variableWidthStyle}
  padding-top: 10px;
  ${isMobile || isTablet ? ' padding-top: 10px;' : ''}
  @media screen and (max-height: 700px) {
    padding-top: ${Math.ceil(window.innerHeight * 0.014) < 2
      ? '2'
      : Math.ceil(window.innerHeight * 0.014) > 10
      ? '10'
      : Math.ceil(window.innerHeight * 0.014)}px;
  }
`
//TO DO: stergere display: none; cand va fi nevoie//
const ProfileInput = styled(Input)<{ isValid: boolean; isVisible: boolean }>`
  ${({ isVisible }) => (isVisible ? '' : 'display: none;')}
  text-align: center;
  height: 35px;
  margin-bottom: 10px;
  border-color: ${({ isValid }) => (isValid ? theme.colors.lightGray : theme.colors.secondary)};
  @media screen and (max-height: 700px) {
    margin-bottom: ${Math.ceil(window.innerHeight * 0.014) < 2
      ? '2'
      : Math.ceil(window.innerHeight * 0.014) > 10
      ? '10'
      : Math.ceil(window.innerHeight * 0.014)}px;
    height: ${Math.ceil(window.innerHeight * 0.05) < 25
      ? '25'
      : Math.ceil(window.innerHeight * 0.05) > 35
      ? '35'
      : Math.ceil(window.innerHeight * 0.05)}px;
  }
`
const ProfileIdLinkText = styled(Text)<{ isVisible: boolean }>`
  ${({ isVisible }) => (isVisible ? '' : 'display: none;')}
  padding: 3px;
  color: blue;
  cursor: pointer;
  cursor: hand;
  @media screen and (max-height: 700px) {
    font-size: ${Math.ceil(window.innerHeight * 0.028) < 10
      ? '10'
      : Math.ceil(window.innerHeight * 0.028) > 12
      ? '12'
      : Math.ceil(window.innerHeight * 0.028)}px;
  }
` // @media screen and (max-width: ${theme.breakpoints[0]}) {
//   text-align: left;
// }
//TO DO: stergere display: none; cand va fi nevoie
const ProfileSwitch = styled(Text)<{ isVisible: boolean }>`
  ${({ isVisible }) => (isVisible ? '' : 'display: none;')}
  margin-top: 10px;
  margin-bottom: 10px;
  @media screen and (max-height: 700px) {
    margin-top: ${Math.ceil(window.innerHeight * 0.014) < 2
      ? '2'
      : Math.ceil(window.innerHeight * 0.014) > 10
      ? '10'
      : Math.ceil(window.innerHeight * 0.014)}px;
    margin-bottom: ${Math.ceil(window.innerHeight * 0.014) < 2
      ? '2'
      : Math.ceil(window.innerHeight * 0.014) > 10
      ? '10'
      : Math.ceil(window.innerHeight * 0.014)}px;
  }
`
const ContainerNotGood = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
`
const TextRed = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) => (isMobile || isTablet || !isVisible ? 'display: none;' : '')}
  color: red;
  font-size: ${theme.fontSizes[6]}px;
  font-style: italic;
  @media screen and (max-height: 700px) {
    font-size: ${Math.ceil(window.innerHeight * 0.028) < 10
      ? '10'
      : Math.ceil(window.innerHeight * 0.028) > 20
      ? '20'
      : Math.ceil(window.innerHeight * 0.028)}px;
  }
`
