import React from 'react'
import styled from 'styled-components'
import { Heading, Text } from 'rebass/styled-components'

import { t } from '@shared-utils'
import { images } from 'app/assets/info'
import { i18nKeys } from 'app/assets/18nKeys'
import { isTablet } from 'react-device-detect'
import { theme } from 'app/assets/theme'

interface Props {
  direction: 'front' | 'side'
}

export const PhotoInstruction: React.FC<Props> = ({ direction }: Props) => {
  const tKey = direction === 'front' ? 'frontViewTitle' : 'sideViewTitle'
  const iKey = direction === 'front' ? 'frontView' : 'sideView'

  return (
    <div>
      <Heading variant='headingLight'>{t(i18nKeys.info[tKey])}</Heading>
      <InstructionImage src={images[iKey]} alt={t(direction)} />
      <HeadingMobile>{t(i18nKeys.info.followInstructions)}</HeadingMobile>
    </div>
  )
}

const InstructionImage = styled.img`
  width: 100%;
  margin-top: 55px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    margin-top: 40px;
  }
  ${isTablet ? ' margin-top: 40px;' : ''}
`

const HeadingMobile = styled(Text)`
  display: none;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  font-weight: 300;
  margin-top: 40px;
  margin-bottom: 20px;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: block;
  }
  ${isTablet ? ' display: block;' : ''}
`
