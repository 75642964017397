import { css } from 'styled-components'
import { theme } from 'app/assets/theme'
import { isTablet } from 'react-device-detect'

export const variableWidthStyle = css`
  width: 70%;
  ${isTablet ? ' width: 90%;' : ''}
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    width: 90%;
  }
  @media screen and (min-width: ${theme.breakpoints[2]}) {
    width: 70%;
  }
`
