import React from 'react'
import useDeviceOrientation from '@rehooks/device-orientation'
import styled from 'styled-components'
import { Button } from 'rebass/styled-components'

import { useThrottle } from '@shared-utils'
import { SensorInfo } from '../useCamera'
import { theme } from 'app/assets/theme'

interface Props {
  sensorInfo: SensorInfo
  onClick: () => void
}

export const CameraButton: React.FC<Props> = ({ onClick, sensorInfo }: Props) => {
  const { isOk, sensorLR } = useThrottle(sensorInfo, 100)
  // const [FB_vertical, setFB_vertical] = useState(0)
  // const [sensorLR, setsensorLR] = useState(0)
  // const [isOK_oriented, setisOK_oriented] = useState(false)

  let value = useDeviceOrientation()

  let FB = value.beta || 0
  let FB_vertical = FB - 90

  //let LR = value.gamma || 0
  //let LR_vertical = LR

  let isOK_oriented = false
  if (FB_vertical <= 7 && FB_vertical >= -7 && isOk) {
    isOK_oriented = true
    //window.navigator.vibrate(1)
  } else {
    isOK_oriented = false
    //window.navigator.vibrate(1)
  }
  //isOK_oriented = true
  // window.addEventListener('deviceorientation', handleMessage)

  // function handleMessage(e: any) {
  //   setFB_vertical(e.beta - 90)
  //   setsensorLR(e.gamma)
  //   if (FB_vertical <= 7 && FB_vertical >= -7 && sensorLR >= -5 && sensorLR <= 5) {
  //     setisOK_oriented(true)
  //     //window.navigator.vibrate(1)
  //   } else {
  //     setisOK_oriented(false)
  //     //window.navigator.vibrate(1)
  //   }
  // }

  return (
    <ButtonWrapper id='captureImage' variant='camera' disabled={!isOK_oriented} onClick={onClick}>
      <LeftRightIndicator isOk={isOK_oriented} angle={sensorLR || 0} />
      <FrontBackIndicator isOk={isOK_oriented} angle={FB_vertical || 0} />
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled(Button)`
  position: relative;
  height: 60px;
  width: 60px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 22px;
    height: 0;
    width: 0;
    background-color: transparent;
    z-index: 2;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-radius: 2px/8px;
  }
  &:before {
    left: 1px;
    border-left: 10px solid ${theme.colors.secondary};
  }
  &:after {
    right: 1px;
    border-right: 10px solid ${theme.colors.secondary};
  }
`

const LeftRightIndicator = styled.div.attrs(({ angle }: any) => ({
  style: { transform: `rotateZ(${angle}deg)` },
}))<{ isOk: boolean; angle: number }>`
  position: absolute;
  height: 30px;
  width: 60px;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 60px;
  border-bottom-right-radius: 60px;
  background-color: #818386;
  visibility: ${({ isOk }) => (isOk ? 'hidden' : 'visible')};
  z-index: 1;
  transform-origin: 50% 0;
`

const FrontBackIndicator = styled.div<{ isOk: boolean; angle: number }>`
  position: absolute;
  top: 5px;
  left: 5px;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  border: 1px solid #ffffff;
  border-radius: 25px;
  overflow: hidden;
  z-index: 10;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 20px;
    ${({ isOk }) => (isOk ? '' : 'background-color: #ffffff')};
    transform: translateY(${({ angle }) => angle}px);
  }
  &:before {
    width: 6px;
    height: 6px;
    left: 21px;
    border-radius: 10px;
  }
  &:after {
    margin-top: 2px;
    margin-top: 2.5px;
    height: 1px;
    width: 32px;
    left: 8px;
  }
`
