import React, { useContext, useEffect } from 'react'
import { isMobile, /*isIOS, isIOS13, isMobileSafari, isSafari,*/ isTablet } from 'react-device-detect'
import styled from 'styled-components'

import { RouteComponentProps } from '@reach/router'
import { getQueryStringValue } from '@shared-utils'
//import { PubSub } from '@aws-amplify/pubsub'
import { Mobile } from './components/Mobile'
import { Desktop } from './components/Desktop'
import { theme } from 'app/assets/theme'
import { ApplicationContext } from 'app/Context'
import { MeasurementSytemNames } from 'app/domain/user/contracts'

export const PrepareScreen: React.FC<RouteComponentProps<any>> = (props: any) => {
  const {
    //state,
    actions: { updateShop, updateUser /*, updatePubSubTopicName*/ },
  } = useContext(ApplicationContext)

  useEffect(() => {
    var element = document.getElementById('Top')
    if (element != null) {
      var parent = element.parentElement
      if (parent != null) {
        parent.style.height = '99%'
      }
    }
    if (/*getQueryStringValue('topic') &&*/ getQueryStringValue('fromB2B') === '22' && !(isMobile || isTablet)) {
      //if (!myIsIOS(isIOS, isIOS13) || (myIsIOS(isIOS, isIOS13) && myIsSafari(isSafari, isMobileSafari))) {
      /*if (getQueryStringValue('topic')) {
          updatePubSubTopicName(getQueryStringValue('topic'))
        }*/

      updateUser({
        name: getQueryStringValue('un') || getQueryStringValue('name'),
        surname: getQueryStringValue('us') || getQueryStringValue('surname'),
        height: getQueryStringValue('uh') || getQueryStringValue('height'),
        weight: getQueryStringValue('uw') || getQueryStringValue('weight'),
        age: getQueryStringValue('ua') || getQueryStringValue('age'),
        neck: getQueryStringValue('unk') || getQueryStringValue('neck'),
        chest: getQueryStringValue('uch') || getQueryStringValue('chest'),
        waist: getQueryStringValue('uwa') || getQueryStringValue('waist'),
        basin: getQueryStringValue('ub') || getQueryStringValue('basin'),
        thigh: getQueryStringValue('ut') || getQueryStringValue('thigh'),
        calf: getQueryStringValue('uc') || getQueryStringValue('calf'),
        preferences: {
          measurementSystem: getQueryStringValue('ums') as MeasurementSytemNames,
        },
      })

      updateShop({
        aitId: getQueryStringValue('aid') || getQueryStringValue('ait_id'),
        language: getQueryStringValue('slang') || getQueryStringValue('language') || 'en',
        productType: getQueryStringValue('spt') || getQueryStringValue('product_type'),
        shopId: getQueryStringValue('sid') || getQueryStringValue('shop_id'),
        shopUserId: getQueryStringValue('suid') || getQueryStringValue('shop_user_id'),
        shopProductLink: getQueryStringValue('spl') || getQueryStringValue('shop_product_link'),
        fitId: getQueryStringValue('fid') || getQueryStringValue('fit_id'),
        jeansOption: getQueryStringValue('jns') || getQueryStringValue('jeans_option'),
        moreMeasurements: getQueryStringValue('mm') || getQueryStringValue('more_measurements'),
        fromB2B: '2',
      })

      // if (getQueryStringValue('topic')) {
      //   PubSub.publish(getQueryStringValue('topic') as string, { action: 'wait-for-mobile' })
      // }
      //}
    }
  }, [/*updatePubSubTopicName,*/ updateUser, updateShop])
  return (
    <PageContainer id='Top'>
      <Mobile {...props} />
      <Desktop />
    </PageContainer>
  )
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  text-align: center;
  align-items: center;
  padding-bottom: 50px;
  @media screen and (min-width: ${theme.breakpoints[0]}) {
    padding-bottom: 70px;
  }
`
